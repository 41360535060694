import React, { useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {MaskedInput} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";
import useImaskNiceAccept from "../../../Hooks/useImaskNiceAccept";
import {Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";
import PropTypes from 'prop-types';
import {CapTimeframeChoices} from "../../../Components/Table/Table";
import StandardAlert from '../../../Layout/StandardAlert';

const NumberMaskOptions = {
  mask: Number,
  unmask: "typed",
  scale:0,
  max:99999999,
  signed: false,
  radix:".",
  thousandsSeparator:","
}

/*
 * CapsType
 * A titled section that lists caps for a given cap type
 * with options to edit and add.
 *
 * Caps are saved in the data as a single object {daily:10, weekly:100}
 * It's easier to work with a collection so we convert it to an array
 * and work with that in local state.

 */
const CapsType = ({
  title, description, caps, onChange, error,
}) => {
  // Convert caps object to array of objects
  const [capsCollection, setCapsCollection] = useState(Object.entries(caps).map(
    ([timeframe, cap], i) => ({ timeframe, cap }),
  ));

  const capsToObject = (rows) => {
    const obj = {};
    rows.map((c) => { obj[c.timeframe] = c.cap.toString().replace(/[^\d]/, ''); });
    return obj;
  };

  const addCap = () => {
    const newVal = [...capsCollection, { timeframe: '', cap: '' }];
    setCapsCollection(newVal);
    onChange(capsToObject(newVal));
  };

  const handleRemove = (index) => {
    const temp = [...capsCollection];
    temp.splice(index, 1);
    setCapsCollection(temp);
    onChange(capsToObject(temp));
  };

  const handleChangeCap = (capValue, index) => {
    if (capsCollection[index]) {
      const newVal = [...capsCollection];
      newVal[index].cap = capValue;
      setCapsCollection(newVal);
      onChange(capsToObject(newVal));
    }
  };

  const handleChangeTimeframe = (timeframe, index) => {

    if (capsCollection[index]) {
      const newVal = [...capsCollection];
      newVal[index].timeframe = timeframe;
      setCapsCollection(newVal);
      onChange(capsToObject(newVal));
    }
  };

  const getTimeframesUsed = () => capsCollection.map((c) => c.timeframe);

  return (
    <>

      {error
      && (
      <StandardAlert color="warning">
        {error}
      </StandardAlert>
      )}

      <div className="form-section caps-container ">
        <div className="form-section-header">
          {title}

        </div>
        <p>{description}</p>
        {capsCollection.length === 0
        && (
        <StandardAlert color="light">
          <span>
            No caps are configured.
            <Button color="link" className="inline ms-2" onClick={addCap}>
              <FaIcon icon="plus" size="sm" className="me-1" />
              <span>Add a cap</span>
            </Button>
          </span>
        </StandardAlert>
        )}

        <Row>
          <Col sm={12} md={8} lg={6}>
            <Stack gap="0.75em" divider={<RowDivider />} >

              {capsCollection.map((item, i) => (
                <CapRow
                  cap={item.cap !== null ? item.cap.toString() : ''}
                  timeframe={item.timeframe}
                  timeframesUsed={getTimeframesUsed()}
                  onRemove={() => handleRemove(i)}
                  onChangeCap={(val) => handleChangeCap(val, i)}
                  onChangeTimeframe={(e) => handleChangeTimeframe(e.target.value, i)}
                  key={`m-cap-${i}`}
                />
              ))}
            </Stack>
          </Col>
        </Row>


        {capsCollection.length > 0 && (
        <div className="text-right">
          <Button color="link" onClick={addCap}>
            <FaIcon icon="plus" />
            {' '}
            <span>Add Cap</span>
          </Button>
        </div>
        )}
      </div>

    </>
  );
};

const CapRow = ({
  cap,
  timeframe,
  timeframesUsed,
  onRemove,
  onChangeCap,
  onChangeTimeframe,
}) => {
  const {onAccept} = useImaskNiceAccept(onChangeCap);
  return (
    <Row className="cap-row align-items-center">

      <Col sm={6}>
        <Input
          type="select"
          value={timeframe}
          onChange={onChangeTimeframe}
          name="timeframe"
          required
        >
          <option value="" data-default hidden>Timeframe...</option>

          {Object.entries(CapTimeframeChoices).map(([val, label], i) => {
            // disable if timeframe already used in another entry
            const disabled = (val !== timeframe && timeframesUsed.includes(val));
            return <option value={val} key={i} disabled={disabled}>{label}</option>;
          })}
        </Input>
      </Col>

      <Col sn={4}>
        <MaskedInput
          value={cap}
          onAccept={onAccept}
          bsSize="sm"
          placeholder="Cap"
          {...NumberMaskOptions}
        />
      </Col>

      <Col sm={2} className="text-end">
        <Button onClick={onRemove} color="link" size="sm" title="Remove">
          <FaIcon icon="times" color="darkred"/>
        </Button>
      </Col>

    </Row>
  )
};

CapsType.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  caps: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

export default CapsType;
