import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {Button} from 'reactstrap';
import PropTypes from 'prop-types';

/*
 FilterSummary: Produce text summary of selected filters.
 Designed for use in the "Applied Filters" dropdown
 */

const FilterLabelMap = {
  account: 'ACCOUNT',
  accounts: 'ACCOUNT',
  billable: 'BILLABLE',
  channel: 'CHANNEL',
  change_action: 'CHANGE ACTION',
  change_group: 'CHANGE TYPE',
  currentlyInsured: 'INSURED',
  device: 'DEVICE',
  disposition: 'DISPOSITION',
  entity_type: 'OBJECT',
  order: 'ORDER',
  orders: 'ORDER',
  phase: 'PHASE',
  product: 'PRODUCT',
  reject_category:'REJECT CATEGORY',
  script: 'SCRIPT',
  sold: 'SOLD',
  source: 'SOURCE',
  sources: 'SOURCE',
  state: 'STATE',
  status: 'STATUS',
  tag: 'TAG',
  vendor: 'VENDOR',
  vendors: 'VENDOR',
  vertical: 'VERTICAL',
  verticals: 'VERTICAL',
  version: 'API VERSION',
  integration: 'INTEGRATION',
  integrations: 'INTEGRATIONS',
  'vertical.name': 'VERTICAL',
  'include_buyers': 'INCLUDE_BUYERS',
  'match_rejection': 'MATCH REJECTION',
  'sale_rejection': 'SALE REJECTION',
};

// Map of filter keys to choices keys
const FilterChoiceMap = {
  'vertical.name': 'vertical',
  vertical: 'verticals',
  vertical_id: 'vertical',
  vendor_id: 'vendor',
  source_id: 'source',
  order_id: 'order',
};

const FilterSummaryTags = ({
                             filters,
                             filterKeys = [],
                             choices,
                             isLoading,
                             fnGetLabel,
                             onRemoveFilter,
                             onClear,
                             onApply,
                             isPendingApply
}) => {

  // Reduce the filter set: Only make tags for filters named in filterKeys
  const targetFilters = keepKeys(filters, filterKeys)

  if (Object.keys(targetFilters).length === 0) {
    return null;
  }
  const summarizeFilterSettings = (filters, choices) => {
    // console.log('FilterSummary.js: summarizeFilterSettings', filters, choices);
    const filterSummary = {};
    Object.entries(filters).map(([filter, val]) => {
      if (fnGetLabel && typeof fnGetLabel[filter] === 'function' ) {
        //we can provide getter functions for specific filters
        const label = fnGetLabel[filter](val, choices[filter]);
        filterSummary[filter] = {name: filter, value:label};
      } else {
        //Get labels by mapping value to choices, or default to the current value
        // Map: param could be vertical.name, but choices prop is 'vertical'
        const name = FilterChoiceMap[filter] ?? filter;
        if (choices.hasOwnProperty(filter)) {
          const label = getLabels(choices[filter], val);
          if (label) {
            filterSummary[filter] = {name, value:label};
          }
        } else {
          // todo guard against non-strings
          filterSummary[filter] = {name, value:val};
        }
      }

    });
    return filterSummary;
  };

  const getLabels = (options, values) => {
    //console.log('ReportsFilter.js:summarize getLabels:', options, values);

    if (values && Array.isArray(options)) {
      // options.filter((opt) => values.includes(opt.value))
      const selected = Array.isArray(values)
        ? options.filter((opt) => values.some(val => val.toString() === opt.value.toString()))
        : options.filter((opt) => opt.value.toString() === values.toString());

      const labels = selected.length ? selected.map((opt) => opt.label) : null;

      //console.log('FilterSummary.js:summarize, getLabels: selected, labels:', selected, labels);
      return labels;
    }
    return null;
  };

  // Keys in the summary object are the filter keys
  const summary = summarizeFilterSettings(targetFilters, choices);

  if (!Object.keys(summary).length) return null;

  // clear only the filters that are displayed in the summary
  const handleClear = () => {
    if (onClear) {
      onClear(Object.keys(targetFilters));
    }
  }

  return (
    <div className="filter-summary d-flex">
      {Object.entries(summary).map(([filter, item], i) => (
        <div className="active-filter me-1 d-flex align-items-center" key={i}>
          <div className="filter-value">
            {Array.isArray(item.value) && item.value.length > 0  ? (
              <span >
              <strong>{FilterLabelMap[filter] ?? filter.toUpperCase() }:</strong> {item.value.join(', ')}
              </span>
            ) : (
              <span >
              <strong>{FilterLabelMap[filter] ?? filter.toUpperCase()}:</strong> {item.value}
              </span>
            )}
          </div>
          <Button color="link" className="" size="sm" onClick={()=>onRemoveFilter(filter)}>
            <FaIcon icon="times" size="sm" />
          </Button>
        </div>
      ))}

      {!isLoading && (
        <>
          {onApply && isPendingApply && (
            <Button color="link" size="sm" className="inline ms-1" onClick={onApply}>
              Apply
            </Button>
          )}

          {!isPendingApply && onClear && <Button color="link" size="sm" className="inline ms-1" onClick={handleClear}>
            Clear
          </Button>}
        </>
      )}

    </div>
  );
};

const keepKeys = (obj, keys) => {
  obj = { ... obj }
  Object.keys(obj).map(key => {
    if (!keys.includes(key)) {
      delete obj[key]
    }
  })
  return obj
}

FilterSummaryTags.propTypes = {
  filters: PropTypes.object,
  filterKeys: PropTypes.array,
  choices: PropTypes.object,
  onRemoveFilter:PropTypes.func,
  onClear:PropTypes.func,
  onApply:PropTypes.func,
  fnGetLabel:PropTypes.object,
  isPendingApply: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default FilterSummaryTags;


