import React, { useState, useEffect } from 'react';
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  InputGroup,
  InputGroupText,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { titleCase } from '../../../utils/string';
import useApi from '../../../Hooks/useApi';
import {StandardAlert, JsonDisplay, notify} from "@thedmsgroup/mastodon-ui-components";
import HttpLog from "./HttpLog";

const HelpText = ({children}) => <Alert color="secondary">{children}</Alert>

// Fetches and displays delivery detail.
// deliveryOptions: summary delivery data that comes with auction detail, used to build the pick list.
// selectedDeliveryId: a delivery can be selected outside of this component (for example, in Matches)
const Deliveries = ({ deliveryOptions = [], selectedDeliveryId = null }) => {

  const { api } = useApi();
  const [detail, setDetail] = useState(null);
  const [deliveries, setDeliveries] = useState({}); // a cache of loaded delivery detail
  const [loading, setLoading] = useState(false);

  // On mount or when external ID selection, load the delivery detail
  useEffect(() => {
    if (deliveryOptions.length) {
      if (selectedDeliveryId) {
        loadDetails(deliveryOptions.find(el => el.id === selectedDeliveryId))
      } else {
        loadDetails(deliveryOptions[0])
      }
    }
    //Unmount: clear delivery detail cache
    return () => {
      setDeliveries({})
      setDetail(null)
    }
  }, [selectedDeliveryId]);

  const loadDetails = async (delivery) => {
    setLoading(true);
    const result = await api.endpoints.debug.deliveries(delivery.id);

    if (result) {
      // todo consider just merging objects
      result.integration_info = delivery.integration_info
      result.type = delivery.type
      result.cached = delivery.cached
      result.timeout = delivery.reject_category === "delivery_timeout"
      setDeliveries((prev) => {
        const newDeliveries = prev ? {...prev} : {};
        newDeliveries[delivery.id] = result;
        return newDeliveries;
      });
      setDetail(result);
    } else {
      notify(`Unable to load delivery details: ${api.error.name}`, 'error')
    }

    setLoading(false);
  };

  const handleSelectDelivery = (delivery) => {
    if (deliveries[delivery.id]) {
      // already in state
      setDetail(deliveries[delivery.id]);
    } else {
      // Fetch delivery data
      if (delivery.id) {
        loadDetails(delivery);
      } else {
        // Some deliveries don't have an id (local actions that don't send requests out)
        // so we'll set detail with the minimal info that came with the auction detail
        setDetail(delivery);
      }

    }
  };

  return (
    <>
      {deliveryOptions.length === 0 && (
      <StandardAlert color="light">
        This auction has no deliveries
      </StandardAlert>
      )}

      {deliveryOptions.length > 1 && (
        <div className="mb-2 d-flex align-items-center">

          <InputGroup>
            <InputGroupText>
              Delivery
            </InputGroupText>
            <UncontrolledDropdown>
              <DropdownToggle outline color="light" size="sm" caret>
                <strong>
                  {detail ? (
                    <span>{detail.integration_info ? detail.integration_info.label : detail.integration} <small className="ms-2">[{detail.type}]</small></span>
                    ) : (
                    <span>Select a delivery...</span>
                  )}
                </strong>
              </DropdownToggle>
              <DropdownMenu>
                {deliveryOptions.map((dv, i) => (
                  <div key={i}>
                    <DropdownItem onClick={() => handleSelectDelivery(dv)} className={classnames({selected:detail?.delivery_id === dv.id})}>
                      <div className="d-flex justify-content-between">
                        <div><strong>{dv.integration_info ? dv.integration_info.label : titleCase(dv.integration.name)}</strong></div>
                        <div><small className="ms-3">[{titleCase(dv.type)}]</small></div>
                      </div>
                      <small>{dv.id || "No Requests"}</small>
                    </DropdownItem>
                    {i < deliveryOptions.length - 1 && <DropdownItem divider />}
                  </div>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </InputGroup>


          <div className="flex-fill ms-2">
            {loading && <Spinner size="sm" color="secondary" />}
          </div>

        </div>
      )}

      {detail && (
        <div>
        <div className="form-section delivery flex-grow-1">

          {!detail.delivery_id && (
            <StandardAlert color="light" icon="info">
              This integration made no HTTP requests.
            </StandardAlert>
          )}

          {detail.cached && <StandardAlert>
            This delivery used a cached response from a prior delivery.
          </StandardAlert>}

          {detail.timeout && <StandardAlert color="danger" icon="clock">
            This delivery timed out – the response was not used.
          </StandardAlert>}

          <div className="data-row">
            <label>Delivery ID</label>
            <div>{detail.delivery_id || <span>&mdash;</span>}</div>
          </div>

          {!detail.integration_info && <div className="data-row">
            <label>Integration</label>
            <div>{detail.integration}</div>
          </div>}

          {detail.integration_info &&
            <div className="data-row">
              <label>Integration</label>
              <Link
                target="_blank"
                className="link ads"
                to={`/tools/integrations/${detail.integration_info.id}`}
                title="View Integration"
              >
                <FaIcon icon="external-link-alt" size="sm" className="me-1" />
                {detail.integration_info.label} [version {detail.integration_info.version}]

              </Link>
            </div>
          }

          <div className="data-row">
            <label>Result Data</label>
            <div>
              {detail.data ? <>
                <HelpText>This data was returned from our integration after interpreting the client response. It is NOT the actual response returned from the client, only our interpretation of it.<br/>
                  To see what the client actually responded, please review the actual response further below.</HelpText>
                <JsonDisplay data={detail.data || ''} scrollable height="300px" /></> : <span>&mdash;</span>}
            </div>
          </div>


          {detail.input_data && (
            <div className="data-row">
              <label>Integration Data</label>
              <div>
                <ToggleMe>
                  <HelpText>This is all the data that was available to our integration to work with. It does NOT mean this data was sent to clients.<br/>
                    To see what was actually sent, please review the actual request further below. INTERNAL USE ONLY.</HelpText>
                  <JsonDisplay data={detail.input_data || ''} scrollable height="300px" />
                </ToggleMe>
              </div>
            </div>
          )}


          {detail.settings && <div className="data-row">
            <label>Settings</label>
            <div>
              <ToggleMe>
                <JsonDisplay data={detail.settings || ''} scrollable height="300px" />
              </ToggleMe>
            </div>
          </div>}

          {detail.error && <div className="data-row">
            <label>Error</label>
            <div className="error">
              {detail.error}
            </div>
          </div>}


          {detail.api_operations?.map((log, i) => <HttpLog log={log} key={i} />)}
        </div>

        </div>
      )}
    </>
  );
};



const ToggleMe = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  return (
    <div className="toggle-me">
      <Button color="link" size="sm" className="inline d-flex align-items-center" onClick={handleToggle}>
        <FaIcon icon="eye" className="me-1" />
        <div>{show ? 'hide' : 'show'}</div>
      </Button>
      <div className={classnames(['data', { show }])}>
        {children}
      </div>
    </div>
  );
};



Deliveries.propTypes = {
  deliveryOptions: PropTypes.array,
};

export default Deliveries;
