import React from 'react';
import { Row, Col, Input, Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {MaskedInputGroup} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";
import {Stack, RowDivider} from "@thedmsgroup/mastodon-ui-components";
import PropTypes from 'prop-types';
import StandardAlert from '../../../Layout/StandardAlert';
import IconCheckbox from "../../../Components/Form/IconCheckbox";
import useImaskNiceAccept from "../../../Hooks/useImaskNiceAccept";

const NumberMaskOptions = {
  mask: Number,
  unmask: "typed",
  scale:0,
  max:999999,
  signed: false,
  radix:".",
  thousandsSeparator:","
}

/*
 * PingDedupeType
 *
 * A titled section that lists dedupe rules with options to edit and add.
 */
const PingDedupeType = ({
  title, description, dedupeRules = [], onChange, error,
}) => {

  const addEntry = () => {
    const newVal = [...dedupeRules, { duration: "", key_expression: "" }];
    onChange(newVal);
  };

  const handleRemove = (index) => {
    const temp = [...dedupeRules];
    temp.splice(index, 1);
    onChange(temp);
  };

  const handleChangeKeyExpression = (value, index) => {
    if (dedupeRules[index]) {
      const newVal = [...dedupeRules];
      newVal[index].key_expression = value;
      onChange(newVal);
    }
  };

  const handleChangeDuration = (duration, index) => {
    if (dedupeRules[index]) {
      const newVal = [...dedupeRules];
      newVal[index].duration = duration;
      onChange(newVal);
    }
  };

  const handleChangeCacheResponse = (value, index) => {
    if (dedupeRules[index]) {
      const newVal = [...dedupeRules];
      newVal[index].cache_response = value;
      onChange(newVal);
    }
  };

  return (
    <>

      {error
      && (
      <StandardAlert color="warning">
        {error}
      </StandardAlert>
      )}

      <div className="form-section caps-container mb-4">
        <div className="form-section-header">
          {title}

        </div>
        <p>{description}</p>
        {dedupeRules.length === 0
        && (
        <StandardAlert color="light">
          <span>
            No dedupe rules are configured.
            <Button color="link" className="inline ms-2" onClick={addEntry}>
              <FaIcon icon="plus" size="sm" className="me-1" />
              <span>Add a Dedupe Rule</span>
            </Button>
          </span>
        </StandardAlert>
        )}


        <Stack gap="0.75em" divider={<RowDivider />} >
          {dedupeRules.map((item, i) => (
            <PingDedupeRow
              keyExpression={item.key_expression}
              cacheResponse={item.cache_response}
              duration={item.duration !== null ? item.duration.toString() : ''}
              onRemove={() => handleRemove(i)}
              onChangeKeyExpression={(e) => handleChangeKeyExpression(e.target.value, i)}
              onChangeDuration={(val) => handleChangeDuration(val, i)}
              onChangeCacheResponse={(name, value) => handleChangeCacheResponse(value, i)}
              key={`m-cap-${i}`}
            />
          ))}
        </Stack>


        {dedupeRules.length > 0 && (
        <div className="text-right">
          <Button color="link" onClick={addEntry}>
            <FaIcon icon="plus" />
            {' '}
            <span>Add Dedupe Rule</span>
          </Button>
        </div>
        )}
      </div>

    </>
  );
};

const PingDedupeRow = ({
  keyExpression,
  duration,
  cacheResponse,
  onRemove,
  onChangeKeyExpression,
  onChangeDuration,
  onChangeCacheResponse,
}) => {
  const {onAccept:onAcceptDuration} = useImaskNiceAccept(onChangeDuration);
  return (
    <Row className="cap-row align-items-center">

      <Col sm={5}>
        <Input
          name="key_expression"
          value={keyExpression}
          onChange={onChangeKeyExpression}
          placeholder="Key Expression"
        />
      </Col>

      <Col sm={3}>
        <MaskedInputGroup
          suffix="seconds"
          value={duration}
          onAccept={onAcceptDuration}
          bsSize="sm"
          {...NumberMaskOptions}
        />
      </Col>
      <Col sm={2}>
        <IconCheckbox
          name="cache_response"
          value={Boolean(cacheResponse)}
          onChange={onChangeCacheResponse}
        >
          Cache Response
        </IconCheckbox>
      </Col>

      <Col sm={1}>
        <Button onClick={onRemove} color="link" size="sm" title="Remove"><FaIcon icon="times" color="darkred"/></Button>
      </Col>

    </Row>
  )
};

PingDedupeType.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  caps: PropTypes.object,
  onChange: PropTypes.func,
  error: PropTypes.string,
};

export default PingDedupeType;
