import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Lazify from '../../utils/Lazify';
import MatchChecker from './Matching';
import AttributeList from './Attributes/List';
import InputSchema from "./InputSchema";
import Integrations from "./Integrations";
import ProtectedRoute from '../../Components/ProtectedRoute';
import SearchByTarget from "./Rules/SearchByTarget";


const LazyDnc = Lazify(() => import(/* webpackChunkName: "Tools" */ './Dnc'));
const LazyPartners = Lazify(() => import(/* webpackChunkName: "Tools" */ './Partners'));


/*
 * Entry point to all tools routes
 */
const Tools = () => (

  <Routes>
    <Route path="matching" element={<ProtectedRoute permission="integrations.edit" /> } >
      <Route index element={<MatchChecker />} />
    </Route>

    <Route path="dnc" element={<ProtectedRoute permission="dnc.edit" /> } >
      <Route index element={<LazyDnc />} />
    </Route>

    <Route path="partners" element={<ProtectedRoute permission="partners.view" /> } >
      <Route index element={<LazyPartners />} />
    </Route>

    <Route path="integrations/*" element={<ProtectedRoute permission="integrations.edit" /> } >
      <Route path="*" element={<Integrations />}/>
    </Route>

    <Route path="inputschema" element={<ProtectedRoute permission="input_schema.edit" /> } >
      <Route index element={<InputSchema />} />
    </Route>

    <Route path="attributes" element={<ProtectedRoute permission="attributes.edit" /> } >
      <Route index element={<AttributeList />} />
    </Route>

    <Route path="rules" element={<ProtectedRoute permission="rules.search.view" /> } >
      <Route index element={<SearchByTarget />} />
    </Route>

    <Route element={() => <Navigate to="/" />} />
  </Routes>

);

export default Tools;
