import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, FormGroup, FormText, FormFeedback, Label, Input, Button,
} from 'reactstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import StandardAlert from '../../../../Layout/StandardAlert';
import RulesContext from '../../../../Providers/RulesContext';

// When we do scheduled settings, we can use this component (which has its own input and does its own masking)
// import SchedulableSettingInput from "../../../../Components/Rules/SchedulableSettingInput";
// const ConcurrencyScheduleConfig = {default:1, max:400, labelInterval:50, lineInterval:25, showBaseline:false, allowHourParting:true};

// Mask ddd,ddd
const numberMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  integerLimit: 4,
});

const CallSettings = ({
  concurrency, billableDuration, onChange, propAncestors, errors,
}) => {
  const rulesContext = useContext(RulesContext);

  const handleClickDurationAncestor = () => {
    const targetRule = rulesContext.api.getRule(propAncestors.billable_duration.id);
    targetRule && rulesContext.api.setSelected(targetRule);
  };

  return (
    <div>
      {(errors.call_concurrency || errors.billable_duration)
          && (
          <StandardAlert color="info" className="validation">
            Please correct the errors below before continuing
          </StandardAlert>
          )}

      <p>For phone number and SIP configuration, see
        {' '}<Button color="link" className="inline" onClick={() => rulesContext.api.setActiveTab('destinations')}>Destinations</Button></p>
      <Row>
        <Col sm={12}>
          <FormGroup>
            <Label>Call Concurrency</Label>
            <FormText>The maximum number of concurrent calls. Defaults to 1 call.</FormText>

            <MaskedInput
              mask={numberMask}
              name="call_concurrency"
              placeholder="1"
              value={concurrency || ''}
              onChange={onChange}
              invalid={!!errors.call_concurrency}
              render={(ref, props) => <Input className="teeny" innerRef={ref} {...props} />}
            />
            <FormFeedback>{errors.call_concurrency}</FormFeedback>

          </FormGroup>

        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <FormGroup>
            <Label>Billable Duration (seconds)</Label>
            <FormText>Number of seconds before the call becomes billable.  Maximum: 300 (5 minutes).</FormText>

            {propAncestors.billable_duration && (
              <Row>
                <Col sm={12} md={8}>
                  <p>
                    The ancestor rule{' '}
                    <Button color="link" size="sm" className="inline" onClick={handleClickDurationAncestor}>{propAncestors.billable_duration.label}</Button>
                    {' '}
                    has the value
                    {propAncestors.billable_duration.value}
                    .
                    The ancestor value will be used if no value is entered here.
                  </p>

                </Col>
              </Row>
            )}

            <MaskedInput
              mask={numberMask}
              name="billable_duration"
              value={billableDuration || ''}
              onChange={onChange}
              invalid={!!errors.billable_duration}
              render={(ref, props) => <Input className="teeny" innerRef={ref} {...props} />}
            />
            <FormFeedback>{errors.billable_duration}</FormFeedback>

          </FormGroup>

        </Col>
      </Row>

    </div>
  );
};

/* For when we do scheduling of concurrency or duration, use this instead:

          <Row>
            <Col sm={12}>
              <SchedulableSettingInput
                setting="concurrency"
                scheduleSetting="call_concurrency_schedule"
                title="Call Concurrency"
                config={ConcurrencyScheduleConfig}
                hideInput={false}
                value={concurrency || ConcurrencyScheduleConfig.default}
                schedule={concurrencySchedule}
                onChange={onChange}
                size="sm"
              />

            </Col>
          </Row>
 */

CallSettings.propTypes = {
  concurrency: PropTypes.number,
  billableDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // concurrencySchedule: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

export default React.memo(CallSettings);
