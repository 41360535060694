import { useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { filtersToQueryString, queryStringToFilters } from '../Components/Table/queryString';

const DefaultControls = {
  page: 1,
  limit: 20,
  sort_by: '',
  sort_dir: '',
};

const DefaultOptions = {
  // Read and set query string
  // In some cases we use this hook in a secondary table (like change log)
  // and don't want to alter the query string
  useQueryString: false,
  // track page, limit, sort values
  useTableControls: true,
  // Update query string when filters change
  // Sometimes where we have a lot of filters we prefer to execute the fetch
  // on clicking a button, to reduce queries
  updateOnFilterChange: true,
  urlDateFormat: undefined,
  parseOptions: {parseNumbers: false}
};

const TableStateReducer = (state, action) => {
  // Name can be field name or a named action
  const { name, value } = action;

  switch (name) {
    case 'controls':
      return { ...state, controls: value };
    case 'page':
      return { ...state, controls: { ...state.controls, page: value }, tableEvent: 'page' };
    case 'sort':
      return { ...state, controls: { ...state.controls, ...value }, tableEvent: 'sort' };
    case 'sortBy':
      return { ...state, controls: { ...state.controls, sort_by: value }, tableEvent: 'sort' };
    case 'sortDir':
      return { ...state, controls: { ...state.controls, sort_dir: value }, tableEvent: 'sort' };
    case 'limit':
      return { ...state, controls: { ...state.controls, limit: value }, tableEvent: 'limit' };
    case 'filters':
      return {
        ...state, filters: value, controls: { ...state.controls, page: 1 }, tableEvent: 'filters',
      };
    case 'currentQuery':
      return { ...state, currentQuery: value };
    case 'tableEvent':
      return { ...state, tableEvent: value };
    case 'navigation':
      // user navigates (eg, uses back button)
      // value.controls, value.filters
      return { ...state, ...value, tableEvent: 'navigation' };
    case 'reset':
      return { ...state, ...value, tableEvent: 'reset' };
    default:
      return state;
  }
};

/*
 * Hook for maintaining the state of query parameters for a paged table.
 * Keeps state of any and all filters as well as controls for page, limit, sort, etc.
 * Optionally updates the query string with the current state, or responds to query string
 * changes such as back button.
 *
 * Despite the name, the data does not have to be paged.
 *
 * Note: it's useful to keep table "controls" (page, limit, etc) separate from filters
 * because different components handle them.
 * We use this hook for LiveFilterProvider (context for "live" filtering) which does not use
 * table controls like paging
 */
const usePagedTableState = (initialFilters = {}, initialControls = {}, options = {}) => {
  const navigate = useNavigate();
  const location = useLocation();
  options = { ...DefaultOptions, ...options };

  const controlsFromQueryString = () => {
    if (!options.useTableControls) {
      return {};
    }
    let controls = { ...DefaultControls, ...initialControls };

    if (options.useQueryString && location.search) {
      const qsData = qs.parse(location.search);
      const {
        page, limit, sort_by, sort_dir,
      } = qsData;
      controls = {
        ...controls, page: parseInt(page) || 1, limit: parseInt(limit) || DefaultControls.limit, sort_by, sort_dir,
      };
    }
    return controls;
  };

  const initFilters = (state) => {
    // Populate from query string, or use default values
    if (options.useQueryString && location.search) {
      const qsData = queryStringToFilters(location.search, options.urlDateFormat, options.parseOptions);
      // separate filters from table control
      const {
        page, limit, sort_by, sort_dir, ...qsFilters
      } = qsData;
      state.filters = { ...state.filters, ...qsFilters };
    }

    return state;
  };

  const [state, dispatch] = useReducer(TableStateReducer, {
    filters: initialFilters,
    controls: controlsFromQueryString(),
    tableEvent: 'loadpage',
    currentQuery: location.search,
  }, initFilters);

  const handleChangeFilters = (filters) => dispatch({ name: 'filters', value: (filters || { ...initialFilters }) });

  const handleSetPage = (page) => dispatch({ name: 'page', value: page });

  const handleSetSortBy = (sort_by) => dispatch({ name: 'sortBy', value: sort_by });

  const handleSetSortDir = (sort_dir) => dispatch({ name: 'sortDir', value: sort_dir });

  // Used for ReactTable onSort event which sets both fied and direction
  // arg is array: [{id:"purchased_at", desc:false}]
  // We are assuming it's not multisort since we config the tables multisort=false
  const handleSetSort = (rtSort) => {
    const sort = {};
    if (rtSort && rtSort.length) {
      sort.sort_by = rtSort[0].id;
      sort.sort_dir = rtSort[0].desc ? 'desc' : 'asc';
      dispatch({ name: 'sort', value: sort });
    }
  };

  const handleSetLimit = (limit) => dispatch({ name: 'limit', value: limit });


  // handle change router location (for example, user clicks back button)
  useEffect(() => {
    // console.log(`usePagedTableState.js: location.search: ${location.search}, currentQuery:${currentQuery}`);

    if (options.useQueryString && (!state.currentQuery || state.currentQuery !== location.search)) {
      const qsData = queryStringToFilters(location.search || {}, options.urlDateFormat);
      let{
        page, limit, sort_by, sort_dir, ...qsFilters
      } = qsData;
      if (!qsFilters || !Object.keys(qsData).length) qsFilters = initialFilters;
      let controls;
      if (options.useTableControls) {
        controls = {
          page: (parseInt(page) || state.controls.page),
          sort_by: (sort_by || state.controls.sort_by),
          sort_dir: (sort_dir || state.controls.sort_dir),
          limit: (parseInt(limit) || state.controls.limit),
        };
      } else {
        controls = { ...state.controls };
      }

      dispatch({
        name: 'navigation',
        value: {
          filters: qsFilters,
          controls,
        },
      });
    }
  }, [location.search]);

  // After updating the query, update query string if we are using it
  useEffect(() => {

    if (options.useQueryString && state.currentQuery !== location.search) {
      // Use navigate "replace" if there is no current query string.
      // This is because landing on "/my-list" may immediately be updated to "/my-list?page=1"
      // and we don't want two history entries (then you would have to "back" through both)
      navigate(state.currentQuery, {replace: location.search === ""})

    }
  }, [state.currentQuery]);

  const reset = (resetTo = {}) => {
    dispatch({
      name: 'reset',
      value: {
        filters: { ...initialFilters, ...resetTo },
        controls: { ...DefaultControls, ...initialControls },
      },
    });
  };

  const updateQuery = () => {
    // console.log('usePagedTableState.js:updateQuery:', filters);

    let qsData = { ...state.filters };
    delete qsData.diffInMinutes;
    if (options.useTableControls) {
      qsData = {
        ...qsData, page: state.controls.page, limit: state.controls.limit, sort_by: state.controls.sort_by, sort_dir: state.controls.sort_dir,
      };
    }
    const search = filtersToQueryString(qsData, options.urlDateFormat);
    dispatch({ name: 'currentQuery', value: search });
  };

  return {
    filters: state.filters,
    setFilters: handleChangeFilters,
    updateQuery,
    // didNavigate,
    tableEvent: state.tableEvent,
    reset,
    controls: {
      ...state.controls,
      setPage: handleSetPage,
      setSortBy: handleSetSortBy,
      setSortDir: handleSetSortDir,
      setSort: handleSetSort,
      setLimit: handleSetLimit,
    },
    query: state.currentQuery,
  };
};

export default usePagedTableState;
