import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, FormGroup, Form, Label, Button, FormFeedback, FormText, Spinner,
} from 'reactstrap';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import { AppContext } from '../../../Providers/AppProvider';
import LoadingBar from '../../../Layout/LoadingBar';
import StandardAlert from '../../../Layout/StandardAlert';
import useFormState from '../../../Hooks/useFormState';
import EnhancedSelect from "../../../Components/Form/EnhancedSelect";
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import SeedDataBuilder from '../../../Components/Form/SeedDataBuilder';
import useApiBatch from "../../../Hooks/useApiBatch";

const ValidationSchema = Yup.object().shape({
  // source_id: Yup.number().transform(n => isNaN(n) ? 0:n).moreThan(0,'Please select a source'),
});

/*
 * Form component for editing & creating a phone number.
 * Allows assignment of IVR script, Source, and Seed Data
 */
/*
isNew={selectedNumber.id === 0}
                  closeModal={closeEditModal}
                  sourceOptions={sourceOptions}
 */
const PhoneNumber = ({
  id,
  phone_number,
  seed_data,
  script_id,
  source,
  vendor,
//  sourceOptions = [],
  isNew = false,
  closeModal,
}) => {
  console.log('index.js:PHoneNumber props', source, vendor);
  const app = useContext(AppContext);
  const batcher = useApiBatch();
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(
    {
      script_id,
      // script_id:props.script? props.script.id : '', when we move scripts to core
      source_id: source ? source.id.toString() : '',
      vendor_id: vendor ? vendor.id.toString() : '',
      seed_data,
    },
    ValidationSchema,
  );
  // const[sourceList, setSourceList] = useState(null);
  // const[verticalsList, setVerticalsList] = useState(null);
  // const[sourceFilterVertical, setSourceFilterVertical] = useState('');
  // const[sourceFilterStatus, setSourceFilterStatus] = useState('active');
  const [choices, setChoices] = useState({});
  const [allSources, setAllSources] = useState([]);
  const [scriptsLoading, setScriptsLoading] = useState(!!source);
  const [scriptOptions, setScriptOptions] = useState(null);
  const [sourceVertical, setSourceVertical] = useState(source ? source.vertical : null);

  // Load scripts on mount
  useEffect(() => {



    const getChoices = async () => {

      if (!scriptOptions && sourceVertical) {
        loadScripts(sourceVertical);
      }

      const batches = await batcher.fetch([
        { endpoint: 'sources', action: 'list', params:{options:true}  },
        { endpoint: 'vendors', action: 'list' },
      ]);
      if (batches && batches.length) {
        const [sources, vendors] = batches;
        setAllSources(sources);
        setChoices({
          source: sources.map((item) => ({ value: item.id.toString(), label: item.name, vertical:item.vertical })),
          vendor: vendors.map((item) => ({ value: item.id.toString(), label: item.name })),
        });
      }
    };

    if (Object.keys(choices).length === 0) getChoices();
  }, []);

  // Refresh scripts and attributes on source change if vertical is different
  useEffect(() => {
    if (formValues.source_id && choices.source) {
      const source = choices.source.find((opt) => opt.value === formValues.source_id);

      if (source && (!sourceVertical || source.vertical.id !== sourceVertical.id)) {
        setSourceVertical(source.vertical);
        loadScripts(source.vertical);
      }
    } else {
      setScriptOptions(null);
      setSourceVertical(null);
    }
  }, [formValues.source_id]);

  const loadScripts = async (vertical) => {
    // script vertical matches source vertical
    setScriptsLoading(true);

    const result = await app.api.endpoints.scripts.list({ vertical_id: vertical.id });

    if (result) {

      const opt = result.map((script) => ({ value: script.id, label: script.name }));

      opt.sort((a, b) => (a.label === b.label ? 0 : +(a.label > b.label) || -1));
      setScriptOptions(opt);
    } else {
      console.log('index.js:Could not load scripts', app.api.error);
    }
    setScriptsLoading(false);
  };

  // SeedDataBuilder provides object
  const handleUpdateSeedData = (obj) => formApi.handleOnChange('seed_data', obj);

  const handleCancel = () => {
    formApi.clearForm();
    closeModal(false);
  };

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating phone number' : 'Updating phone number');
    let result;
    let { script_id, source_id, vendor_id,seed_data } = formValues;

    seed_data = seed_data && Object.keys(seed_data).length > 0
      ? JSON.stringify(seed_data)
      : null;

    if (isNew) {
      result = await app.api.endpoints.phoneNumbers.create({
        seed_data,
        source_id,
        vendor_id,
        script_id,
      });
    } else {
      result = await app.api.endpoints.phoneNumbers.update({
        id,
        seed_data,
        source_id,
        vendor_id,
        script_id,
      });
    }
    app.showLoader(false);
    if (result) {
      closeModal(true);
    } else {
      console.log('index.js:phoneApi error', app.api.error);
      notify(`Unable to save phone number: ${app.api.error.name}`, 'error');
    }
  };

  // const filterOptions = (option, input) => {
  //  // console.log('index.js:filterOptions', option, input);
  //  // if (input) {
  //     return option.data.vertical.id === 2
  //   //}
  //  // return true;
  // };

  // empty data may be empty array

  // const hasSeedData = Array.isArray(seedData) && seedData.length;

  return (
    <>
      <Row>
        <Col>
          <LoadingBar name="modal" />
          {formIsValid === false
          && (
          <StandardAlert color="warning" className="alert-form-validation">
            Please correct the errors below before continuing
          </StandardAlert>
          )}

          <div className="modal-content-background">
            <Form>

              <FormGroup row>
                <Label sm={2}>
                  Number
                </Label>
                <Col sm={10}>
                  {id === 0 ? <FormText>A new toll-free number will be purchased.</FormText>
                    : <div className="col-form-text"><NumberFormat value={phone_number} displayType="text" format="+###########" /></div>}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>
                  Source
                </Label>
                <Col sm={10}>

                    <EnhancedSelect
                      isClearable
                      isSearchable
                      onChange={(val) => formApi.handleOnChange('source_id', val)}
                      options={choices.source ?? []}
                      value={formValues.source_id || ''}
                      tabSelectsValue
                      backspaceRemovesValue
                      isMulti={false}
                      controlShouldRenderValue
                      hideSelectedOptions
                      closeMenuOnSelect
                      placeholder="Select (type to search)"
                      isInvalid={!!formErrors.source_id}

                    />
                    <FormFeedback>{formErrors.source_id}</FormFeedback>

                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>
                  Vendor
                </Label>
                <Col sm={10}>

                    <EnhancedSelect
                      isClearable
                      isSearchable
                      onChange={(val) => formApi.handleOnChange('vendor_id', val)}
                      options={choices.vendor ?? []}
                      value={formValues.vendor_id || ''}
                      tabSelectsValue
                      backspaceRemovesValue
                      isMulti={false}
                      controlShouldRenderValue
                      hideSelectedOptions
                      closeMenuOnSelect
                      placeholder="Select (type to search)"
                      isInvalid={!!formErrors.vendor_id}
                    />
                    <FormFeedback>{formErrors.vendor_id}</FormFeedback>

                </Col>
              </FormGroup>

              <FormGroup row>
                <Label sm={2}>
                  Seed Data
                </Label>
                <Col sm={10}>
                  {formValues.source_id ? (
                    <SeedDataBuilder
                      seedData={formValues.seed_data}
                      verticalId={sourceVertical ? sourceVertical.id : 0}
                      onUpdate={handleUpdateSeedData}
                    />
                  ) : <FormText>No seed data (select a source)</FormText>}

                </Col>

              </FormGroup>

              {/*
            MST-949
            Script list fetched from core requires code from conversation-service branch
            of core API. We can't use this until we have a way of syncing changes in
            core to the Conversation data. */}
              <FormGroup row>
                <Label sm={2}>
                  IVR Script
                </Label>
                <Col sm={10}>

                  {!scriptsLoading ? (
                    <>
                      {scriptOptions ? (
                        <>
                          <EnhancedSelect
                            isClearable
                            isSearchable
                            onChange={(val) => formApi.handleOnChange('script_id', val)}
                            options={scriptOptions}
                            value={formValues.script_id || ''}
                            tabSelectsValue
                            backspaceRemovesValue
                            isMulti={false}
                            controlShouldRenderValue
                            hideSelectedOptions={false}
                            closeMenuOnSelect
                            placeholder="Select (type to search)..."
                          />
                          <FormFeedback>{formErrors.script_id}</FormFeedback>
                        </>
                      ) : (
                        <>
                          {formValues.source_id ? <i>No scripts found for source vertical</i> : <FormText>No script (select a source)</FormText>}
                        </>

                      )}

                    </>

                  ) : (
                    <Spinner size="sm" color="secondary" />
                  )}

                </Col>

              </FormGroup>

            </Form>
          </div>
        </Col>
      </Row>

      <Row className="modal-content-footer">

        <Col sm={12} className="text-right">
          <Button
            onClick={handleCancel}
            color="link"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>



        </Col>
      </Row>
    </>
  );
};

PhoneNumber.propTypes = {
  id: PropTypes.number,
  phone_number: PropTypes.string,
  closeModal: PropTypes.func,
  isNew: PropTypes.bool,
  vendor: PropTypes.object,
  seed_data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  source: PropTypes.object,
  script_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // script:PropTypes.object,
};

export default PhoneNumber;
