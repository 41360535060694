import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Row, Col, TabContent, TabPane, Nav,
} from 'reactstrap';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import StandardAlert from '../../../Layout/StandardAlert';
import { FormTabFlat } from '../../../Components/Form/FormCommon';
import AccountDetail from './AccountDetail';
import Goals from './Goals';
import GoalTracking from './GoalTracking';
import Funds from './Accounting/Funds';
import PageSection from '../../../Layout/PageSection';
import usePermission from '../../../Hooks/usePermission';
import DocTitle from '../../../Layout/DocTitle';
import { AppContext } from '../../../Providers/AppProvider';
//import AccountBreadcrumb from "../AccountBreadcrumb";
import useQueryState from "../../../Hooks/useQueryState";

/*
Component with form for editing/creating an Account
 */
const AccountSettings = () => {

  const app = useContext(AppContext);
  const [query, setQuery] = useQueryState({}, {historyAction:"push"});
  const { accountId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isNew = location.pathname.indexOf('create') > 0;
  const allowView = usePermission('accounts.view');
  const allowEdit = usePermission('accounts.edit');
  const allowCreate = usePermission('accounts.create');
  const allowAccounting = usePermission('accounting.view');
  const [activeTab, setActiveTab] = useState(query.tab ?? 'details');
  const [account, setAccount] = useState(null);
  //const [goals, setGoals] = useState([]);


  useEffect(() => {
    if (!allowView) {
      navigate('/accounts');
    } else {
      load();
    }
  }, []);

  useEffect(() => {
    if (query.tab && query.tab !== activeTab) {
      handleSelectTab(query.tab);
    }
  }, [query]);


  const handleSelectTab = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab)
      setQuery({tab})
    }

  };

  const handleSaveDetails = (values) => saveDetails(values);

  const load = async () => {
    if (isNew) {
      app.showLoader(false);
      setAccount({ id: 0, name: '' });
      return;
    }
    app.showLoader(true);
    const result = await app.api.endpoints.accounts.show(accountId);

    if (result === false) {
      notify(`Unable to load account:${app.api.error.name}`, 'error');
      return;
    }

    result.account_id = result.id;

    // TODO: serializer might not include value if null

    setAccount(result);

    app.showLoader(false);
  };

  const saveDetails = async (values) => {
    app.showLoader('main', 'Saving Account...');

    const acc = { ...values };

    let result = null;
    if (isNew) {
      // Parent account should be user's account. API will default to root account.
      if (app.account_id) {
        acc.parent_id = app.account_id;
        result = await app.api.endpoints.accounts.create(acc);
      } else {
        notify('Unable to save account: unknown parent account', 'error');
      }
    } else {
      acc.id = account.id;
      result = await app.api.endpoints.accounts.update(acc);
    }

    app.showLoader(false);

    if (!result) {
      notify(`Unable to save account: ${app.api.error.name}`, 'error');
    } else if (isNew) {
      notify('The account was created', 'success');
      navigate(`/accounts/${result.id}/settings`, {replace:true});

    } else {
      setAccount(result);
      notify('The account settings were saved', 'success');
    }
  };

  if (!account) return null;
  const title = isNew ? 'Create Account' : 'Account Settings';

  // No permission to create
  if (isNew && !allowCreate) {
    return (
      <PageSection title={title}>
        <StandardAlert color="light">
          You do not have permission to create an account.
          {' '}
          <Link to="/accounts">Return to Accounts</Link>
        </StandardAlert>
      </PageSection>
    );
  }

  return (
    <>
      {/*<AccountBreadcrumb crumbs={[{ label: 'Settings' }]} />*/}

      <PageSection
        title={title}
        subtitle={account.name ?? ''}
        className="account-settings"
        tools={<Button tag={Link} color="link" size="sm" to="/accounts" title="Account List" className="me-1">
          <FaIcon icon="arrow-left" size="sm" /> Orders
        </Button>}
      >
        <DocTitle pageTitle={`${title} ${account?.name ?? ''} - ${activeTab}`} />
        {!isNew && (
          <Nav className="mastodon-tabs">
            <FormTabFlat
              id="details"
              label="Details"
              activeTab={activeTab}
              setActive={handleSelectTab}
            />

            <FormTabFlat
              id="goals"
              label="Goals"
              activeTab={activeTab}
              setActive={handleSelectTab}
            />

            {allowEdit && (
              <FormTabFlat
                id="goal_tracking"
                label="Goal Tracking"
                activeTab={activeTab}
                setActive={handleSelectTab}
              />
            )}

            {allowAccounting === true && (
              <FormTabFlat
                id="funds"
                label="Manage Funds"
                activeTab={activeTab}
                setActive={handleSelectTab}
              />
            )}

          </Nav>
        )}


        <TabContent activeTab={activeTab}>
          <TabPane tabId="details">

            <Row>
              <Col sm={12} md={4}>
                {account && <AccountDetail account={account} onSave={handleSaveDetails} />}
              </Col>
            </Row>
          </TabPane>

          { !isNew
          && (
            <>
              <TabPane tabId="goals">
                <Goals accountId={accountId} />
              </TabPane>

              {allowEdit && (
                <TabPane tabId="goal_tracking">
                  <GoalTracking accountId={accountId} />
                </TabPane>
              )}

              {allowAccounting  && (
                <TabPane tabId="funds">
                  <Funds accountId={account.id} accountTimezone={account.timezone} />
                </TabPane>
              )}

            </>
          )}
        </TabContent>
      </PageSection>
    </>

  );
};

AccountSettings.displayName = 'Account';



export default AccountSettings;
