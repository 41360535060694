import React, {useState, useEffect} from "react";
import {
  Button,Modal, ModalHeader, ModalBody
} from 'reactstrap';
import useApi from "../../../Hooks/useApi";
//import SourceForm from "./SourceForm";
import {SourceTokenTable, SourceTokenForm, Filters} from "../SourceToken";
import useTableState from "../../../Hooks/useTableState";



const DefaultControls = {
  limit: 15,
  sort_by: 'created_at',
  sort_dir: 'desc',
};

const Sources = ({vendor, isOpen, close}) => {
  const {api} = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const {
    filters, setFilters,  query, controls
  } = useTableState(
    {},
    DefaultControls,
    { useQueryString: false, useTableControls: true },
  );

  const [tokens, setTokens] = useState([])
  const [selectedToken, setSelectedToken] = useState(null);

  useEffect(() => {
    if (isOpen) {
      load();
    }
    return () => {
      setTokens([]);
    }
  }, [isOpen, vendor, query])

  const load = async() => {
    setIsLoading(true);
    const {
      page, sort_by, sort_dir, limit,
    } = controls;
    const result = await api.endpoints.sourceTokens.list({
      vendor: vendor.id,
      ...filters,
      page,
      sort_by,
      sort_dir,
      limit,
    });


    if (result) {
      const { limit, total, data } = result;
      setTokens(data);
      setPageCount(total > limit ? Math.ceil(total / limit) : 1);
      setTotalRows(total);
    }
    setIsLoading(false);
  }

  const archive = async(sourceToken) => {
    setIsLoading(true);
    const result = await api.endpoints.sourceTokens.update({id:sourceToken.id, status:'archived'})
    if (result) {
      load();
    }
    setIsLoading(false);
  }



  const onCloseForm = (refresh) => {
    if (refresh === true) { // could be click event
      load();
    }
    setSelectedToken(null);
  }


  const onAdd = () => {
    setSelectedToken({
      id: 0,
      token: "",
      seed_data: null,
      status: 'active',
      source: {id:0, name:''},
      vendor: vendor
    });
  }

  const onCloseModal = () => {
    setSelectedToken(null);
    close();
  }
  const onEdit = (sourceToken=null) => {
    setSelectedToken(sourceToken);
  }


  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} size="xlg" id="source-vendor-modal" scrollable>
        <ModalHeader toggle={onCloseModal}>
          {selectedToken ? (selectedToken.id > 0 ? 'Edit Source Token' : 'Create Source Token') : 'Source Tokens for Vendor'}
        </ModalHeader>
        <div className="modal-subheader">{vendor.name}</div>

          {selectedToken ? (
            <SourceTokenForm
              vendor={vendor}
              sourceToken={selectedToken}
              close={onCloseForm}
            />
          ) : (
            <ModalBody>
              <div className="d-flex justify-content-between mb-3">
                <div className="source-vendors above-table-filters d-block flex-fill">
                  <Filters
                    filters={filters}
                    onChange={setFilters}
                    loading={isLoading}
                    showVendors={false}
                  />
                </div>
                <div>
                  <Button
                    size="sm"
                    onClick={onAdd}
                    className="mt-1 ms-2"
                  >
                    Add
                  </Button>
                </div>

              </div>

              <SourceTokenTable
                showVendor={false}
                data={tokens}
                loading={isLoading}
                noDataMessage="No sources tokens were found"
                onEdit={onEdit}
                onArchive={archive}
                pageCount={pageCount}
                totalRows={totalRows}
                {...controls}
              />

            </ModalBody>
          )}

      </Modal>
  )
}

export default Sources;


