import React, { useEffect, useContext } from 'react';
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useGlobalFilter
} from 'react-table';
import './ReactTableFlex.scss';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { NoDataComponent } from './Table';
import { DefaultTableState } from './constants';
import { LiveFilteringContext } from '../../Providers/LiveFilteringProvider';

const defaultColumn = {};

/*
 * Table component using hook-based ReactTable 7
 * Uses Flex-based layout
 *
 * Filtering and sorting is executed locally
 * Filtering controls are outside of the table component.
 * Uses context for live filtering (Filters components sets state in context,
 * and it is read here and applied to built-in react-table row filtering)
 * https://codesandbox.io/s/react-table-provider-8rfh3?file=/src/TableFilter.js
 * outside live filtering by prop
 * https://codesandbox.io/s/react-table-render-header-q4vg8?file=/src/Table.js
  *
  * A note on flexLayout:
  * The column property width:(int) is the flex-basis style.
  * Therefore, it is more of a relative value, not a fixed width.
  * minWidth and maxWidth values are only relevant to column resizing
 */
const FlexTable = ({
  data=[],
  columns,
  loading,
  noDataMessage = 'No Rows Found',
  className="",
  renderRowSubcomponent=null,
  reloadCallback=null,
  getInstanceCallback=null,
  onSelectRowsCallback=null,
  onClickRowCallback=null,
  isStateControlled=false,
  showFooter=false,

  // Table config
  disableFilters=false,
  addinHooks = [],
  initialState = {},
  globalFilter,
  filterTypes=[],

  // for controlled state
  expandedRows = {},
  hiddenColumns = [], // array of column ids to hide
  selectedRowIds = {}, // {1:true, 2:false, ...}

}) => {
  const FilterContext = useContext(LiveFilteringContext);

  // return instance using a callback
  const useInstance = (instance) => {
    if (instance && instance.getInstanceCallback) {
      instance.getInstanceCallback(instance);
    }
  };

  const defaultHooks = [
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    ...addinHooks,
  ];

  const {
    // instance methods & props
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state: { expanded, sortBy },
    setAllFilters,
    setGlobalFilter,
    selectedFlatRows,
    // access state's expanded and sortBy, but not the whole state object
    // if you want whole state object, we would just use 'state' here
    // The 'state' prop of instance can look like
    /*
    columnResizing: {columnWidths: {…}}
    expanded: {0: true}
    hiddenColumns: []
    sortBy:[
      0: {sortBy: "name", desc: true}
      ]
     */

  } = useTable(
    // config
    {
      columns,
      data,
      getInstanceCallback,
      filterTypes,
      defaultColumn, // reason to use this?
      globalFilter,
      autoResetGlobalFilter: false,
      initialState: {
        ...DefaultTableState,
        ...initialState,
      },
      // useControlledState: Allows update of table state from outside components.
      // This can be tricky if you are control some state properties externally,
      // but let the table control others. You can end up with infinite loops.
      // Unfortunately, react-table-7 does not allow us to selectively update state items.
      // It's recommended that you either control all of these properties externally, or none.
      // Hence the boolean isStateControlled.
      useControlledState: isStateControlled
        ? (state) =>

          // this fires on any state change
          React.useMemo(
            () => ({
              ...state,
              selectedRowIds,
              hiddenColumns,
              // expanded:expandedRows
            }),
            [state, selectedRowIds, hiddenColumns, expandedRows],
          )

        : (state) => state,

      // manualPagination: true, //tells pagination hook that we handle data fetching manually (must provide pageCount)
      // manualSortBy:true, //letting server do the sorting,
      disableSortRemove: true, // disable 3rd click to turn off sort
      disableMultiSort: true,
      disableFilters,
      autoResetExpanded: false, // prevent expanded rows form collapsing when data refreshes
      // Prevents filters/sort from being reset if data changes when using "live" (client side) filtering
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false
    },
    // hooks
    ...defaultHooks,
    (hooks) => {
      // Add in custom hook
      hooks.useInstance.push(useInstance);
    },
  );

  useEffect(() => {
    if (onSelectRowsCallback) {
      onSelectRowsCallback(selectedFlatRows || []);
    }
  }, [selectedFlatRows]);

  // Global filter allows multi-column search with one string
  // @see: https://react-table.tanstack.com/docs/api/useGlobalFilter
  useEffect(() => {
    if (FilterContext && !disableFilters) {
      setGlobalFilter(FilterContext.globalFilter);
    }
  }, [FilterContext?.globalFilter, data.length]);



  // Filters from context, Reset if changed.
  // Also reset if data length changes
  // (there can be a race condition where filters get set before data is loaded but then do not
  // get applied after the data is loaded)
  useEffect(() => {
    // Filter prop received as object
    // Filter state must be array: [{ id: 'name', value: 'Jane'}, { id: 'age', value: 21 }]
     //console.log(`SimpleTable. js :useEffect, filters changed`, FilterContext.filters);
    if (FilterContext?.filters && !disableFilters) {
      const newFilters = Object.entries(FilterContext.filters).map(([id, value]) => ({ id, value }));

      //console.log(`FlexTable:useEffect, newFilters from context:`, FilterContext.filters, newFilters);
      setAllFilters(newFilters);
    }
  }, [FilterContext?.filters, data.length]);


  //"rows" will change based on filtering. Report back to context so it can be used to show "n out of x rows"
  useEffect(() => {
    if (FilterContext) {
      FilterContext.setFilteredCount(rows.length);
    }
  }, [rows.length]);



  return (
    <div className={classnames('table-container', { loading })}>

      {!loading && rows.length === 0 && <NoDataComponent>{noDataMessage}</NoDataComponent>}

      {loading && <div className="table-loader"><div><Spinner color="secondary" /></div></div> }

      {rows.length > 0 && (
        <div className={`table flex-table ${[className]}`} {...getTableProps()}>
          <div className="thead">
            {headerGroups.map((headerGroup, groupIndex) => (
              <div key={groupIndex} {...headerGroup.getHeaderGroupProps()} className="thr">
                {headerGroup.headers.map((header, i) => {
                  // three new addition to column: isSorted, isSortedDesc, getSortByToggleProps

                  const {
                    render,
                    getHeaderProps,
                    // defaultCanSort,
                    isSorted,
                    isSortedDesc,
                    getSortByToggleProps,
                    // resizer
                    isResizing,
                    getResizerProps,
                    canSort,
                    headerClassName,
                  } = header;

                  const { onClick, ...rest } = getHeaderProps(getSortByToggleProps());

                  return (
                    <div
                      key={`th-${i}`}
                      className={classnames('th', headerClassName, { sortable: canSort, desc: isSorted && isSortedDesc, asc: isSorted && !isSortedDesc })}
                      onClick={onClick}
                      {...rest}
                    >
                      <div>
                        {render('Header')}
                      </div>

                      {canSort && (
                      <div className={classnames(['sort-icon', { 'sort-active': isSorted }])}>
                        {isSorted && !isSortedDesc && <FaIcon icon="sort-up" size="sm" />}
                        {isSorted && isSortedDesc && <FaIcon icon="sort-down" size="sm" />}
                        {!isSorted && <FaIcon icon="sort" size="sm" />}
                      </div>
                      )}

                      {/* resizer div */}

                      <div
                        {...getResizerProps()}
                        className={`resizer ${isResizing ? 'isResizing' : ''}`}
                      />

                    </div>
                  );
                })}
              </div>
            ))}
          </div>

          <div className="tbody" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              // console.log('FlexTable.js:row id,  props.selectedRowIds', row.id, selectedRowIds);
              return (
                <React.Fragment key={`rt-tb-trs${i}`}>
                  <div className={classnames('tr', { odd: i % 2 !== 1, selected: selectedRowIds[row.id] })} {...row.getRowProps()} onClick={() => onClickRowCallback(row.id)}>
                    {row.cells.map((cell, i) => (
                      <div
                        key={i}
                        {...cell.getCellProps({
                          className: `td ${cell.column.className || ''}`,
                          style: cell.column.style,
                        })}
                      >
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                  {row.isExpanded && renderRowSubcomponent ? (
                    <div className="tr subcomponent">
                      {renderRowSubcomponent(row, reloadCallback)}
                    </div>
                  ) : null}
                </React.Fragment>

              );
            })}
          </div>

            {showFooter && footerGroups.length > 0 && (

              <div className="tfoot">
                {footerGroups.map((group, groupKey) => (
                  <div className="tr" {...group.getFooterGroupProps()} key={groupKey}>
                    {group.headers.map((col, c) => {
                      //Top-level pivot table columns might not have a Footer (mst-3230)
                      return col.Footer ? (
                        <div  key={c} {...col.getFooterProps()}
                            className={`td ${col.footerClassName || ''}`}>{col.render('Footer')}</div>
                      ) : null
                    })}
                  </div>
                ))}
              </div>
            )}

        </div>
      )}

    </div>

  );
};

FlexTable.propTypes = {

  loading: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  defaultColumn: PropTypes.object,
  noDataMessage: PropTypes.string,
  className: PropTypes.string,
  renderRowSubcomponent: PropTypes.func,
  reloadCallback: PropTypes.func,
  onClickRowCallback: PropTypes.func,
  isStateControlled: PropTypes.bool,
  showFooter: PropTypes.bool,

  // Table config props
  disableFilters: PropTypes.bool,
  addinHooks: PropTypes.array,

  // Table initial state
  initialState: PropTypes.object,
  hiddenColumns: PropTypes.array, // list of col ids: ['status', ...]
  selectedRowIds: PropTypes.object,
  expandedRows: PropTypes.object, // {1:true,2:false, ...}
  filters: PropTypes.object,
  globalFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

FlexTable.defaultProps = {
  data: [],
  initialSort: [],
  hiddenColumns: [],
  noDataMessage: 'No rows found',
  className: '',
  disableFilters: false,
  defaultColumn: { width: 'auto', minWidth: 100 },
  onClickRowCallback: () => {},
};

export default FlexTable;
