import React, { useMemo, useContext } from 'react';
import {
  Button, Col, FormFeedback, FormGroup, FormText, Input, InputGroup, Label, Row,
} from 'reactstrap';
import * as Yup from 'yup';
import copy from 'fast-copy';
import PropTypes from 'prop-types';
import LoadingBar from '../../../../Layout/LoadingBar';
import {HelpPopper}  from '@thedmsgroup/mastodon-ui-components';
import useFormState from '../../../../Hooks/useFormState';
import StandardAlert from '../../../../Layout/StandardAlert';
import { Required } from '../../../../Components/Form/FormCommon';
import DynamicSingleSelect from '../../../../Components/Form/DynamicSingleSelect';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import { AppContext } from '../../../../Providers/AppProvider';
import Options from './Options';
import { StaticChoiceInputTypes, DynamicSourceMap, DynamicChoiceInputTypes } from './constants';

const ValidationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  alias: Yup.string().trim().matches(/^[a-z0-9_\.]+$/, 'Alias must be lower case alphanumeric (underscore and dot allowed)').required('Alias is required'),
  input_type: Yup.string().required('Enter an input type'),
  data_type: Yup.string().required('Enter a data type'),
  group_id: Yup.number().required('Select a group for display'),
  dynamic_source: Yup.mixed().when('input_type', {
    is: (t) => DynamicChoiceInputTypes.includes(t),
    then: Yup.string().required('Dynamic source is required'),
  }),

  options: Yup.object().shape({
    // Test for choices if input type is choice-based
    choices: Yup.string().test('choices_exist', 'Choices are required (verify format)', function (choices) {
      // Strange way of accessing the parent schema values
      const { from } = this;
      const inputType = from[1].value.input_type;

      if (inputType && StaticChoiceInputTypes.includes(inputType)) {
        const lines = choices.split('\n');
        return lines.every((line) => /^.+:.+$/.test(line));
      }
      return true;
    }).nullable(),

  }),
});

const initFormValues = (attribute) => {
  const values = {
    name: attribute.name,
    alias: attribute.alias,
    product: attribute.product || '',
    vertical_id: attribute.vertical ? attribute.vertical.id : '',
    group_id: attribute.group ? attribute.group.id : '',
    input_type: attribute.input_type,
    data_type: attribute.data_type,
    scope: attribute.scope || '',
    options: attribute.options || {},
    dynamic_source: '',
  };

  if (Array.isArray(values.options)) {
    // php will encode empty into array
    values.options = {};
  }
  values.options.choices = choicesToText(values.options.choices);
  if (!values.options.target) values.options.target = {};

  values.options.target.label = values.options.target.label || '';
  values.options.target.choices = choicesToText(values.options.target.choices);

  return values;
};

const choicesToText = (objChoices) => {
  if (!objChoices) return '';
  const lines = Object.entries(objChoices).reduce((acc, [val, label]) => {
    acc.push(`${val}:${label}`);
    return acc;
  }, []);

  return lines.length ? lines.join('\n') : '';
};

const textToChoices = (text) => {
  if (!text || typeof text !== 'string') return {};
  const choices = {};
  text.split('\n').map((line) => {
    const [key, value] = line.split(':');
    choices[key] = value;
  });
  return choices;
};

const Attribute = ({ attribute, close }) => {
  const app = useContext(AppContext);
  const isNew = !attribute.id;
  const preparedFormValues = useMemo(() => initFormValues(copy(attribute)), [attribute.id]);
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState(preparedFormValues, ValidationSchema);

  // Change data_type value depending on input_type
  const handleChangeInputType = (e) => {
    const input_type = e.target.value;
    let { alias, data_type } = formValues;
    switch (input_type) {
      case 'boolean':
        data_type = 'boolean'; break;
      case 'choice':
      case 'multiple_choice':
      case 'zipcode_list':
      case 'expression':
        data_type = 'string'; break;
      case 'integer_range':
        data_type = 'integer'; break;
      case 'dynamic_choice':
      case 'dynamic_multiple_choice':
        if (!DynamicChoiceInputTypes.includes(alias)) {
          alias = '';
        }
    }

    formApi.setValues({
      ...formValues, alias, input_type, data_type,
    });
  };

  const handleChangeDynamicSource = (e) => {
    const dynamic_source = e.target.value;
    const alias = dynamic_source || '';
    formApi.setValues({ ...formValues, alias, dynamic_source });
  };

  const handleSave = () => {
    const isValid = formApi.validate();
    if (isValid) {
      save();
    }
  };

  const save = async () => {
    app.showLoader('modal', isNew ? 'Creating attribute' : 'Updating attribute');

    const att = copy(formValues);

    if (att.input_type.indexOf('choice') < 0) {
      delete att.options.choices;
    }

    if (att.options.choices) {
      att.options.choices = textToChoices(att.options.choices);
    }

    if (att.input_type !== 'integer_range') {
      delete att.options.min;
      delete att.options.max;
    }

    // Remove empty target, or objectify choices
    if (!att.options.target || Object.keys(att.options.target).length < 2
       || !att.options.target.label || !att.options.target.choices) {
      delete att.options.target;
    } else {
      att.options.target.choices = textToChoices(att.options.target.choices);
    }

    // "dynamic_source" is not a db field, we just set the alias to the dynamic_source value.
    if (DynamicChoiceInputTypes.includes(att.input_type)) {
      delete att.options.choices;
    }

    delete att.dynamic_source;

    let result = null;

    if (attribute.id) {
      att.id = attribute.id;
      result = await app.api.endpoints.attributes.update(att);
    } else {
      result = await app.api.endpoints.attributes.create(att);
    }

    app.showLoader(false);

    if (!result) {
      // Could get 'alias already exists' error
      if (app.api.error.form && app.api.error.form['alias.alias']) {
        notify(`Unable to save attribute: ${app.api.error.form['alias.alias']}`, 'error');
      } else {
        notify(`Unable to save attribute: ${app.api.error.name}`, 'error');
      }
    } else {
      notify('The attributed was saved', 'success');
      close(true);
    }
  };

  return (
    <>
      <LoadingBar name="modal" />

      {!formIsValid
      && (
      <StandardAlert color="info" className="validation">
        Please correct the errors below before continuing
      </StandardAlert>
      )}

      <div className="modal-content-background">
        <Row>
          <Col sm={3}>

            <div className="form-section">
              <FormGroup>
                <Label>
                  Name
                  {' '}
                  <Required />
                </Label>
                <Input
                  type="text"
                  bsSize="sm"
                  name="name"
                  onChange={formApi.handleOnChange}
                  value={formValues.name}
                  invalid={!!formErrors.name}
                  placeholder="The label in the UI"
                />

              </FormGroup>

              <FormGroup>
                <Label>
                  Alias
                  {' '}
                  <Required />
                  {' '}
                  <HelpPopper name="alias" title="Alias" iconClass="ms-2">
                    The attribute&apos;s key used within the matchable definition. Lower case with no spaces. Dots can be used
                    to indicate categories of attributes, such as
                    {' '}
                    <i>properties.num_stories</i>
                  </HelpPopper>
                </Label>
                <Input
                  type="text"
                  bsSize="sm"
                  name="alias"
                  onChange={formApi.handleOnChange}
                  value={formValues.alias}
                  invalid={!!formErrors.alias}
                  disabled={formValues.input_type.indexOf('dynamic') >= 0}
                  placeholder={formValues.input_type.indexOf('dynamic') >= 0 ? 'Select a dynamic source' : 'The matchable key'}
                />
                {!isNew && <FormText><small>NOTE: Changing the alias will NOT update existing matchables that use it!</small></FormText>}
              </FormGroup>
              {/* TODO: if dynamic, alias must be source (vertical_id, account_id, etc) */}

              <FormGroup>
                <Label>
                  Vertical
                </Label>

                <DynamicSingleSelect
                  onChange={formApi.handleOnChange}
                  defaultValue={formValues.vertical_id}
                  noSelectionLabel="All verticals"
                  name="vertical_id"
                  size="sm"
                  endpoint="verticals"
                  invalid={!!formErrors.vertical_id}
                  labelProperty="display_name"
                  params={{options:true}}
                />

                <FormFeedback>{formErrors.vertical_id}</FormFeedback>
              </FormGroup>

              <FormGroup>

                <Label>
                  Product
                </Label>
                <InputGroup>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={formApi.handleOnChange}
                    value={formValues.product}
                    name="product"
                    invalid={!!formErrors.product}
                  >
                    <option value="">All products</option>
                    <option value="clicks">Clicks</option>
                    <option value="calls">Calls</option>
                    <option value="leads">Leads</option>
                  </Input>
                </InputGroup>
                <FormFeedback>{formErrors.product}</FormFeedback>
              </FormGroup>

              {/* TODO disable depending on input_type ? */}
              <FormGroup>
                <Label>
                  Data Type
                  {' '}
                  <Required />
                </Label>
                <InputGroup>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={formApi.handleOnChange}
                    value={formValues.data_type}
                    name="data_type"
                    invalid={!!formErrors.data_type}
                  >
                    <option value="">Select...</option>
                    <option value="boolean">Boolean</option>
                    <option value="integer" disabled={formValues.input_type === 'boolean'}>Integer</option>
                    <option value="string" disabled={formValues.input_type === 'boolean'}>String</option>
                    <option value="array" disabled={formValues.input_type === 'boolean'}>Array</option>
                  </Input>
                </InputGroup>
                <FormFeedback>{formErrors.data_type}</FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label>
                  Input Type
                  {' '}
                  <Required />
                </Label>
                <InputGroup>
                  <Input
                    type="select"
                    onChange={handleChangeInputType}
                    value={formValues.input_type}
                    name="input_type"
                    bsSize="sm"
                    invalid={!!formErrors.input_type}
                  >
                    <option value="">Select...</option>
                    <option value="boolean">Boolean</option>
                    <option value="expression">Expression</option>
                    <option value="integer_range">Integer Range</option>
                    <option value="choice">Choice</option>
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="dynamic_choice">Dynamic Choice</option>
                    <option value="dynamic_multiple_choice">Dynamic Multiple Choice</option>
                    <option value="list">List (Freeform Choices)</option>
                    <option value="metro_choice">Metro Choice</option>
                    <option value="state_choice">State Choice</option>
                    <option value="county_list">County List</option>
                    <option value="zipcode_list">Zipcode List</option>

                  </Input>
                </InputGroup>
                <FormFeedback>{formErrors.input_type}</FormFeedback>
              </FormGroup>

              {DynamicChoiceInputTypes.includes(formValues.input_type) && (
              <FormGroup>
                <Label>
                  Dynamic Source
                  {' '}
                  <Required />
                  {' '}
                  <HelpPopper name="dynamic-source" title="Dynamic Source" iconClass="ms-2">
                    Dynamic choice inputs draw their choices from the database. The alias will be set according to chosen source.
                  </HelpPopper>
                </Label>
                <InputGroup>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={handleChangeDynamicSource}
                    value={formValues.dynamic_source}
                    name="dynamic_source"
                    invalid={!!formErrors.dynamic_source}
                  >
                    <option value="">Select...</option>
                    {Object.entries(DynamicSourceMap).map(([val, label], i) => <option key={i} value={val}>{label}</option>)}
                  </Input>
                </InputGroup>
                <FormFeedback>{formErrors.dynamic_source}</FormFeedback>
              </FormGroup>
              )}

              <FormGroup>
                <Label>
                  Attribute Group
                  {' '}
                  <Required />
                  {' '}
                  <HelpPopper name="group" title="Attribute Group" iconClass="ms-2">
                    Attributes are grouped under these headers in the selection dropdown.
                  </HelpPopper>
                </Label>

                <DynamicSingleSelect
                  onChange={formApi.handleOnChange}
                  defaultValue={formValues.group_id}
                  name="group_id"
                  size="sm"
                  endpoint="attributes"
                  action="groups"
                  invalid={!!formErrors.group_id}
                  labelProperty="name"
                  params={{options:true}}
                />

                <FormFeedback>{formErrors.group_id}</FormFeedback>
              </FormGroup>

              {/* TODO: secondary list for dynamic source: vertical_id, account_id, vendor_id */}

              <FormGroup>
                <Label>
                  Scope
                  <HelpPopper name="scope" title="Scope" iconClass="ms-2">
                    Optionally limit the use of this attribute to routing rules.
                    If not set, the attribute will be available to all scopes (depending on settings for product and vertical)
                  </HelpPopper>
                </Label>
                <InputGroup>
                  <Input
                    type="select"
                    bsSize="sm"
                    onChange={formApi.handleOnChange}
                    value={formValues.scope}
                    name="scope"
                    invalid={!!formErrors.scope}
                  >
                    <option value="">Any</option>
                    <option value="routing">Routing Rules</option>
                  </Input>
                </InputGroup>
                <FormFeedback>{formErrors.scope}</FormFeedback>
              </FormGroup>
            </div>

          </Col>

          <Col sm={9}>
            <Options inputType={formValues.input_type} {...formValues.options} errors={formErrors} onChange={formApi.handleOnChange} />
          </Col>
        </Row>

      </div>

      <Row className="modal-content-footer">

        <Col sm={12} className="text-end">
          <Button
            onClick={close}
            color="link"
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            size="sm"
          >
            Save
          </Button>


        </Col>
      </Row>
    </>
  );
};

Attribute.propTypes = {
  close: PropTypes.func.isRequired,
  attribute: PropTypes.object.isRequired,
};

export default Attribute;
