import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const getIcon = (icon) => { // todo allow accessing all icons
  switch (icon) {
    case 'info':
      return 'info-circle';
    case 'check':
      return 'check-circle';
    case 'clock':
      return 'clock';
    case 'alert':
    default:
      return 'exclamation-circle';
  }
};

/*
  * Formatted Reactstrap alert with icon
  * allowDismiss: puts x icon to make alert go away
  * onDismiss: function to call onDismiss, such as clear error state in parent
  */
const StandardAlert = ({
  color, icon, inline, allowDismiss, onDismiss, className, children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDismiss = () => {
    setIsOpen(false);
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
  };

  return (
    <Alert
      color={color}
      isOpen={isOpen}
      toggle={allowDismiss ? handleDismiss : null}
      className={classnames({ 'standard-alert': true, inline, [className]: className })}
    >
      {icon !== null && (
        <div className="standard-alert-icon">
          <FaIcon icon={getIcon(icon)} size="lg" />
        </div>
      )}

      <div className="standard-alert-content">
        {children}
      </div>
    </Alert>
  );
};

StandardAlert.defaultProps = {
  color: 'warning',
  className: '',
  inline: false,
};

StandardAlert.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  allowDismiss: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
};

export default StandardAlert;
