import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { TreeContext } from '../../../Components/Rules/TreeProvider';
import PageSection from '../../../Layout/PageSection';
import TreeBranch from '../../../Components/Rules/TreeBranch';
import InputWithIcon from '../../../Components/Form/InputWithIcon';
import TreeSkeleton from './TreeSkeleton';
import SimpleSelect from '../../../Components/Form/SimpleSelect';
import usePermission from '../../../Hooks/usePermission';
import {singularOrPlural} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
/*
 * Component for sidebar tree view. Renders root branch, which recurses for all sub-branches.
 * Has text input for searching the tree.
 */
const RuleTree = ({
  rule,
  expandToLevel,
  selectedRule,
  ancestors,
  ruleComponent,
  isLoading,
}) => {
  const treeContext = useContext(TreeContext);

  const allowIntegration = usePermission('integrations.edit');
  const allowAdvanced = usePermission('rules_advanced.edit');

  const filterOptions = useMemo(() => {
    let options = [
      { value: 'schedule', label: 'Activation Schedule' },
      { value: 'ads', label: 'Ads' },
      { value: 'bid_blocked', label: 'Bid Blocked' },
      { value: 'bid_schedule', label: 'Bid Schedule (hourly)' },
      { value: 'bid_exclusivity', label: 'Bid Exclusivity' },
      { value: 'billable_duration', label: 'Billable Duration' },
      { value: 'budget', label: 'Budget/Caps' }, // advertiser rules, budget & caps
      { value: 'channel_modifiers', label: 'Channel Modifiers' },
      { value: 'datasheets', label: 'Datasheets' },
      { value: 'destination_number', label: 'Destination Phone Number' },
      { value: 'disabled', label: 'Disabled status' },
      { value: 'prefill', label: 'Prefill' },
      { value: 'match_is_required', label: 'Match Required' },
      { value: 'post_sale', label: 'Post-sale integrations' },
      { value: 'proxy_bidding', label: 'Proxy Bidding' },
      { value: 'sale_conditions', label: 'Sale Conditions' },
      { value: 'is_standalone', label: 'Standalone' },
      { value: 'tags', label: 'Tags' },
      { value: 'match_conditions', label: 'Targets' },
      { value: 'tracking', label: 'Tracking' },
      { value: 'advanced', label: 'Advanced rule settings' },
    ];

    // Filter out options based on product or permissions
    // Note: memo dependent on isLoading because order (which has product) might not have loaded yet
    options = allowIntegration ? options : options.filter((opt) => opt.value !== 'post_sale' && opt.value !== 'prefill' && opt.value !== 'proxy_bidding');
    options = allowAdvanced ? options : options.filter(opt => opt.value !== 'advanced');
    options = treeContext.product === 'calls' ? options : options.filter((opt) => opt.value !== 'billable_duration' && opt.value !== 'destination_number');
    return options;
  }, [isLoading]);

  const searchResult = treeContext.getMatches(rule);

  const handleSearchDebounced = useDebouncedCallback((val) => {
    treeContext.setSearch(val);
  }, 400);

  return (

    <div className="col-rule-tree">

      <PageSection title="Rules Tree">

        {isLoading ? (
          <TreeSkeleton />
        ) : (
          <>
            {rule.rules.length > 0
                && (
                <>
                  <div>

                    <InputWithIcon
                      icon="search"
                      iconSize="sm"
                      placeholder="Search by name"
                      name="search_term"
                      containerClass="expands"
                      defaultValue=""
                      onChange={(e) => handleSearchDebounced(e.target.value)}
                      onClear={treeContext.clearSearch}
                      className="mb-2"
                    />

                  </div>

                  <div className="mb-2">

                    <SimpleSelect
                      autoFocus={false}
                      isClearable
                      isSearchable
                      onChange={treeContext.setFilter}
                      options={filterOptions}
                      value={treeContext.filter}
                      tabSelectsValue
                      backspaceRemovesValue
                      isMulti={false}
                      controlShouldRenderValue
                      hideSelectedOptions={false}
                      closeMenuOnSelect
                      placeholder="Rules with..."
                    />

                  </div>

                </>
                )}

            {treeContext.isFiltered && (
            <div className="filter-result d-flex justify-content-between align-items-center">
              <div>
                Search Result:
                {' '}
                {treeContext.filteredResultCount || 'no'}
                {' '}
                {singularOrPlural(treeContext.filteredResultCount, 'rule', 'rules')}
                {' '}
                found
              </div>
              <Button size="sm" color="link" onClick={treeContext.clearSearch}><FaIcon icon="times" size="sm" /></Button>
            </div>
            )}

            <TreeBranch
              rule={searchResult}
              selectedRule={selectedRule}
              ancestors={ancestors}
              expandToLevel={expandToLevel}
              ruleComponent={ruleComponent}
              level={0}
            />
          </>
        )}

      </PageSection>

    </div>

  );
};

RuleTree.propTypes = {
  rule: PropTypes.object.isRequired,
  ruleComponent: PropTypes.func.isRequired,
  selectedRule: PropTypes.object,
  ancestors: PropTypes.array,
  expandToLevel: PropTypes.number,
  isLoading: PropTypes.bool,
};

RuleTree.defaultProps = {
  expandToLevel: 0,
  selectedRule: null,
  ancestors: [],
};

export default RuleTree;
