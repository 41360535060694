import React from "react";

export const ProductChoices = [
  { value: 'clicks', label: 'Clicks' },
  { value: 'leads', label: 'Leads' },
  { value: 'calls', label: 'Calls' },
  // {value: 'click-to-calls', label: 'Click to Call'},
];

export const DeliveryRejectCategoryChoices = [
  {value: 'no match', label: 'No Match'},
  {value: 'duplicate', label: 'Duplicate'},
  {value: 'invalid', label: 'Invalid'},
  {value: 'delivery_failure', label: 'Delivery Failure'},
  {value: 'delivery_timeout', label: 'Delivery Timeout'},
  {value: 'general', label: 'General'},
  {value: 'todo', label: 'TODO'},
  {value: 'capped', label: 'Capped'},
  {value: 'compliance', label: 'Compliance'},
  {value: 'dnc', label: 'DNC'},
  {value: 'geo', label: 'Geo'},
];



export const StatusChoices = [
  { value: 'active', label: 'Active' },
  { value: 'paused', label: 'Paused' },
  { value: 'archived', label: 'Archived' },
];

export const DispositionChoices = [
  { value: 'answered', label: 'Answered' },
  { value: 'completed', label: 'Completed' },
  { value: 'failed', label: 'Failed' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'no-answer', label: 'No Answer' },
  { value: 'busy', label: 'Busy' },
  { value: 'ringing_inbound', label: 'Ringing Inbound' },
  { value: 'ringing_outbound', label: 'Ringing Outbound' },
];

export const StateChoices = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District ' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

// export const ChangeTypeChoices = [
//   { value: "bid", label: "Bid Changes"},
//   { value: "status", label: "Status Changes"},
//   { value: "target", label: "Targeting Changes"},
//   { value: "ad", label: "Ad Changes"},
//   { value: "user", label: "User Changes"},
// ];
export const ChangeGroupChoices = [
  { value: 5, label: 'Ad Changes' }, // entity
  { value: 2, label: 'Bid Changes' }, // fields
  { value: 3, label: 'Budget Changes' }, // fields
  { value: 4, label: 'Schedule Changes' }, // fields, different entities
  { value: 1, label: 'Status Changes' }, // field, different entities
  { value: 6, label: 'Targeting Changes' }, // field
  { value: 7, label: 'User Changes' }, // entity
  { value: 9, label: 'Access/Permissions' },
  { value: 10, label: 'Publishing Changes' },
  { value: 11, label: 'Vertical Changes' },
  { value: 12, label: 'Channel Modifiers (advertiser)' }, // field
];

export const ChangeActionChoices = [
  { value: 'update', label: 'Update' },
  { value: 'create', label: 'Create' },
  { value: 'delete', label: 'Delete' },
];

export const ChangeEntityTypeChoices = [
  { value: 'account', label: 'Accounts' },
  { value: 'attribute', label: 'Attributes' },
  { value: 'ad', label: 'Ads' },
  { value: 'channel', label: 'Channels' },
  { value: 'goal,goaltrackingfile', label: 'Goals' },
  { value: 'inputschema', label: 'Input Schema' },
  { value: 'integration', label: 'Integrations' },
  { value: 'order', label: 'Orders' },
  { value: 'partner', label: 'Partners' },
  { value: 'phonenumber', label: 'Phone Numbers' },
  { value: 'routingrule', label: 'Routing Rules' },
  { value: 'source', label: 'Sources' },
  { value: 'targetrule', label: 'Target Rules' },
  { value: 'user', label: 'Users' },
  { value: 'vertical', label: 'Verticals' },
];

export const IntegrationTypeChoices = [
  { value: 'delivery', label: 'Delivery'},
  { value: 'proxy_bidding', label: 'Proxy Bidding'},
  { value: 'prefill', label: 'Pre-fill'},
  { value: 'enrichment', label: 'Enrichment'},
  { value: 'sale_confirmation', label: 'Sale Confirmation'},
];

//Chart aggregation intervals
export const ChartIntervalOptions = [
  {
    label: 'Minute',
    value: '1m',
  },
  {
    label: 'Hour',
    value: '1h',
  },
  {
    label: 'Day',
    value: '1d',
  },
  {
    label: 'Week',
    value: '1w',
  },
  {
    label: 'Month',
    value: '1M',
  },
  {
    label: 'Quarter',
    value: '1q',
  },
  {
    label: 'Year',
    value: '1y',
  },
];
