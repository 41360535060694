import React, {useState, useContext, useEffect} from 'react';
import { Button } from 'reactstrap';
import copy from 'fast-copy';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import EnrichmentForm from './EnrichmentForm';
import StandardAlert from '../../../../Layout/StandardAlert';
import usePermission from '../../../../Hooks/usePermission';

/*
 * Component for entering one or more lead "enrichments" with conditions that affect matching/sales.
 * (In database, enrichment_addons)
 */

const NewEnrichment = {
  priority: 0,
  timing: 'matching',
  suppression_conditions: [],
  integration: { name: '', settings: {} },
};

const Enrichments = ({ addOns = [], onChange, errors = {}, loadIntegrations }) => {
  const allowEditIntegration = usePermission('integrations.edit');
  const [intConfig, setIntConfig] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [addIndex, setAddIndex] = useState(-1);
  const [refreshKey, setRefreshKey] = useState(100);

  const _loadIntegrations = async () => {
    const ints = await loadIntegrations()
    if (ints) {
      setIntConfig(ints)
    }
  }

  useEffect(() => {
    _loadIntegrations()
  }, [])

  const handleApply = (newData) => {
    if (newData) {
      let temp = copy(addOns);//
      if (editIndex >= 0 && addOns[editIndex]) {

        temp[editIndex] = newData;
        if (newData.priority !== addOns[editIndex].priority) {
           temp = changePriority(temp, newData.priority, editIndex)
        }

        onChange(temp);
      }
    }
    setRefreshKey(refreshKey + 1);
    setAddIndex(-1);
    setEditIndex(-1);

  }


  const handleCreate = () => {
    const sc = copy(NewEnrichment);
    sc.priority = addOns.length + 1;
    onChange([...addOns, sc]);
    setAddIndex(addOns.length)
    setEditIndex(addOns.length)
  };


  const changePriority = (addOns, newPriority, index) => {
    let temp = [...addOns];
    // Get item at index, move into new slot
    const removed = temp.splice(index, 1);
    if (removed.length) {
      temp.splice(newPriority - 1, 0, removed[0]);
    }

    temp = resetPriorities(temp);

    return temp;
  };

  const resetPriorities = (addOns) => {
    const temp = [...addOns];
    temp.forEach((addOn, i) => {
      addOn.priority = i + 1;
    });
    return temp;
  }

  const handleDelete = (index) => {
    let temp = [...addOns];
    temp.splice(index, 1);
    temp = resetPriorities(temp);
    onChange([...temp]);
    setAddIndex(-1)
    setEditIndex(-1)
    setRefreshKey(refreshKey + 1);
  };



  return (
    <div>
      { Object.keys(errors).length > 0 && (
        <StandardAlert color="info" className="validation">
          Please correct the errors below before continuing
        </StandardAlert>
      )}

      <p>Enrichment integrations connect to third party systems to send or fetch data during the matching/sales process.</p>

      {!allowEditIntegration && <StandardAlert color="light">Managing this setting requires additional permission.</StandardAlert>}

      { addOns.length === 0 && allowEditIntegration && (
        <StandardAlert color="light">
          No enrichments configured.{' '}
          <Button color="link" className="inline" onClick={handleCreate}>
            <FaIcon icon="plus" size="sm" className="me-1" />
            Add Enrichment Integration
          </Button>
        </StandardAlert>
      )}

      <div key={refreshKey}>
        {addOns.map((addOn, i) => (
          <EnrichmentForm
            key={i}
            addOn={addOn}
            addOnCount={addOns.length}
            config={intConfig}
            index={i}
            editMode={editIndex === i && allowEditIntegration}
            isNew={addIndex === i}
            errors={errors[i]}
            onDelete={handleDelete}
            onApply={handleApply}
            onEditMode={setEditIndex}
          />
        ))}
        {addOns.length > 0 && allowEditIntegration && (
        <div className="text-end">
          <Button color="link" onClick={handleCreate} disabled={editIndex >= 0 || addIndex >= 0}>
            <FaIcon icon="plus" className="me-1" />
            Add Enrichment Integration
          </Button>
        </div>
        )}
      </div>

    </div>
  );
};

Enrichments.propTypes = {
  addOns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  loadIntegrations: PropTypes.func,
};

export default Enrichments;
