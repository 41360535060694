import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import Configuration from './Configuration';
import useCollectionTracker from '../../Hooks/useCollectionTracker';
import usePermission from '../../Hooks/usePermission';
import StandardAlert from '../../Layout/StandardAlert';

// AKA post sale integrations
const DeliveryDestinations = ({
  destinations=[],
  definitions,
  description,
  label = 'Delivery Destinations',
  onChange,
  errors,
}) => {
  // We are not restricting view, only edit
  const allowEdit = usePermission('integrations.edit');

  const {
    api: collection,
    willDeleteIndex,
    editIndex,
    addMode,
  } = useCollectionTracker();

  const handleAdd = () => {
    const item = { integration: { name: '', settings: {} } };
    onChange([...destinations, item]);
    collection.add(destinations.length);
  };

  const handleUpdate = (intg, index) => {
    const temp = [...destinations];
    if (destinations[index]) {
      temp[index].integration = intg;
      onChange(temp);
    }
    // collection.apply();
  };

  const handleDelete = (index) => {
    const temp = [...destinations];
    temp.splice(index, 1);
    /// setActiveIndex(temp.length ? temp.length -1 : null);
    collection.onDelete();
    onChange([...temp]);
  };

  // MST-979 if user has no permission to edit integrations: hide if none exist, show read-only if integration exists
  if (!allowEdit && !destinations.length) {
    return (null);
  }

  return (
    <div className="form-section">
      <div className="form-section-header">{label}</div>
      {description && <p>{description}</p>}
      {definitions.length > 0 ? (
        <>

          {!allowEdit && (
          <StandardAlert color="light" className="mb-0">
            Managing this setting requires additional permissions.
          </StandardAlert>
          )}

          <Row className="mt-3">
            <Col lg={12} xl={9}>

              <div className="deliver-destinations-list">
                {destinations.map((dest, i) => (
                  <Configuration
                    key={i}
                    index={i}
                    config={dest.integration}
                    definitions={definitions}
                    type="delivery"
                    editMode={editIndex === i}
                    isNew={addMode}
                    errors={errors[i]}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                    onApply={collection.apply}
                    setEdit={collection.edit}
                    allowEdit={allowEdit}
                    willDelete={willDeleteIndex === i}
                    setWillDelete={(i) => collection.setWillDeleteIndex(i)}
                    allowControls={allowEdit}
                  />
                ))}
              </div>
            </Col>
          </Row>
          {addMode === false && allowEdit
          && (
          <Button color="link" onClick={handleAdd} disabled={editIndex !== null}>
            <FaIcon icon="plus" />
            {' '}
            Add Integration
          </Button>
          )}
        </>
      ) : (
        <StandardAlert color="light">
          No
          {' '}
          {label.toLowerCase()}
          {' '}
          have been defined for the current product or
          vertical.
        </StandardAlert>
      )}
    </div>

  );
};

DeliveryDestinations.propTypes = {
  destinations: PropTypes.array,
  definitions: PropTypes.array,
  description: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default DeliveryDestinations;
