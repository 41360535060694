import React, {
  useContext, useState, useEffect, Suspense, lazy,
} from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CopyWidget from '../../../Components/Rules/CopyWidget';
import MoveWidget from '../../../Components/Rules/MoveWidget';

import RulesContext from '../../../Providers/RulesContext';
import usePermission from '../../../Hooks/usePermission';
import StandardAlert from '../../../Layout/StandardAlert';

const LazyBulkEditModal = lazy(() => import(/* webpackChunkName: "RuleModals" */ './RulesConfig/BulkEditRulesModal'));
const LazyBatchRuleModal = lazy(() => import(/* webpackChunkName: "RuleModals" */ '../../../Components/Rules/BatchRuleModal'));
const LazySettingCopyModal = lazy(() => import(/* webpackChunkName: "RuleModals" */ '../../../Components/Rules/SettingCopyModal'));
const LazySettingPasteModal = lazy(() => import(/* webpackChunkName: "RuleModals" */ '../../../Components/Rules/SettingPasteModal'));

const ActionHeader = ({ label, setActionMode }) => (
  <div className="header d-flex align-items-baseline">
    <Button onClick={() => setActionMode(null)} color="transparent"><FaIcon icon="arrow-left" size="1x" className="me-2" /></Button>
    <h5 className="flex-fill">{label}</h5>
  </div>
);

/*
 * ACTIONS PANEL
 * Slide-out panel for Rule Manager containing rule actions like copy, add, bulk update, etc
 * Some actions are done within the menu, and others will open a modal or do some other external action
 */
const ActionsPanel = (props) => {
  const rulesContext = useContext(RulesContext);
  const [actionMode, setActionMode] = useState(null);
  const [bulkEditModal, setBulkEditModal] = useState(false);
  const [batchRuleModal, setBatchRuleModal] = useState(false);
  const [settingCopyModal, setSettingCopyModal] = useState(false);
  const [settingPasteModal, setSettingPasteModal] = useState(false);
  const allowCreate = usePermission('orders.create');

  const toggleBulkEditModal = () => setBulkEditModal(!bulkEditModal);
  const toggleSettingCopyModal = () => setSettingCopyModal(!settingCopyModal);
  const toggleSettingPasteModal = () => setSettingPasteModal(!settingPasteModal);
  const toggleBatchRuleModal = () => setBatchRuleModal(!batchRuleModal);

  const handleClose = () => {
    setActionMode(null);
    rulesContext.api.toggleActionsPane();
  };

  const handleDeleteRule = () => {
    // props.onDeleteRule();
    rulesContext.api.deleteRule(props.rule);
    handleClose();
  };

  const handleAddRule = (action) => {
    rulesContext.api.addRule(props.rule, action);
    handleClose();
  };

  const toggleDisabled = () => {
    if (props.rule.disabled) {
      rulesContext.api.disableRule(props.rule, false);
      handleClose();
    } else {
      // confirm
      setActionMode('disable');
    }
  };

  const handleDisable = () => {
    rulesContext.api.disableRule(props.rule, true);
    handleClose();
  };
  const handleSave = () => {
    props.onSaveRule();
    handleClose();
  };

  // close when modal opens
  useEffect(() => {
    if (batchRuleModal || settingCopyModal || settingPasteModal || bulkEditModal) {
      handleClose();
    }
  }, [batchRuleModal, settingCopyModal, settingPasteModal, bulkEditModal]);

  if (!props.rule) return (null);

  return (
    <>
      {/* Hide RuleActions header when in action mode? */}
      <div className={classnames(['actions-panel', { open: props.isOpen }])}>

        {/* Show menu if NOT in action mode */}
        {!actionMode && (
        <>
          <div className="header d-flex justify-content-between align-items-baseline">
            <h4 className="m-0">Rule Actions</h4>
            <Button onClick={rulesContext.api.toggleActionsPane} color="transparent"><FaIcon icon="times" size="1x" /></Button>
          </div>

          <div className="rule-action-menu d-flex flex-column mt-1">

            <div className="rule-action-item" style={{ marginLeft: '-5px' }}>
              <UncontrolledDropdown direction="left" size="sm">
                <DropdownToggle color="link" className="no-case" disabled={props.rule.isNew || !allowCreate} caret>
                  <FaIcon icon="plus" />
                  {' '}
                  Add Rules
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem onClick={() => handleAddRule('below')} disabled={props.isRootRule}>
                    <FaIcon icon="bars" />
                    {' '}
                    One, Below
                  </DropdownItem>
                  <DropdownItem onClick={() => handleAddRule('child')}>
                    <FaIcon icon="indent" />
                    {' '}
                    One, As Child
                  </DropdownItem>
                  <DropdownItem onClick={toggleBatchRuleModal}>
                    <FaIcon icon="indent" />
                    {' '}
                    Create Multiple...
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={handleSave}
                disabled={props.rule.isNew}
                className="no-case"
              >
                <FaIcon icon="pencil-alt" />
                Save Rule
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={() => setActionMode('copy')}
                disabled={props.isRootRule || props.rule.isNew}
                className="no-case"
              >
                <FaIcon icon="copy" />
                Copy Rule
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={() => setActionMode('move')}
                disabled={props.isRootRule || props.rule.isNew}
                className="no-case"
              >
                <FaIcon icon="arrows-alt" />
                Move Rule
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={toggleDisabled}
                disabled={props.isRootRule || props.rule.isNew}
                className="no-case"
              >

                {props.rule.disabled ? (
                  <>
                    <FaIcon icon="plug" />
                    Enable
                  </>
                ) : (
                  <>
                    <FaIcon icon="ban" />
                    Disable
                  </>
                ) }
                {' '}
                Rule
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={() => setActionMode('delete')}
                disabled={props.isRootRule || props.rule.isNew}
                className="no-case"
              >
                <FaIcon icon="trash" />
                Delete Rule
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                color="link"
                size="sm"
                onClick={toggleBulkEditModal}
                disabled={props.rule.isNew}
                className="no-case"
              >
                <FaIcon icon="list" />
                Bulk Edit
              </Button>
            </div>

            {allowCreate === true && (
            <div className="rule-action-item" style={{ marginLeft: '-5px' }}>
              <UncontrolledDropdown direction="left" size="sm">
                <DropdownToggle color="link" className="no-case" caret>
                  <FaIcon icon="cog" className="me-1" />
                  Transfer Settings
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem onClick={toggleSettingCopyModal}>
                    <FaIcon icon="copy" />
                    {' '}
                    Copy Setting...
                  </DropdownItem>
                  <DropdownItem onClick={toggleSettingPasteModal}>
                    <FaIcon icon="paste" />
                    {' '}
                    Paste Setting...
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            )}

          </div>
        </>
        )}

        {/*
            * Action view (menu goes away, show action form
           */}

        {actionMode === 'copy' && (
        <div className="rule-action">
          <ActionHeader label="Copy Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <CopyWidget rule={props.rule} close={handleClose} cancel={() => setActionMode()} />
          </div>
        </div>
        )}

        {actionMode === 'move' && (
        <div className="rule-action">
          <ActionHeader label="Move Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <MoveWidget rule={props.rule} close={handleClose} />
          </div>
        </div>
        )}

        {actionMode === 'delete' && (
        <div className="rule-action">
          <ActionHeader label="Delete Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <StandardAlert color="light" className="p-0">Are you sure? Any rules that descend from this rule will also be deleted.</StandardAlert>
            <div className="d-flex mt-2 justify-content-end">
              <Button size="sm" color="link" onClick={() => setActionMode(null)} >Cancel</Button>
              <Button className="ms-1" size="sm" color="primary" onClick={handleDeleteRule}>Delete</Button>
            </div>
          </div>
        </div>
        )}

        {actionMode === 'disable' && (
        <div className="rule-action">
          <ActionHeader label="Disable Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <StandardAlert color="light" className="p-0">
              Disabling will block the application of this rule and all descendant rules.
              The rules can still be edited.
            </StandardAlert>
            <div className="d-flex mt-2 justify-content-end">

              <Button size="sm" color="link" onClick={() => setActionMode(null)} >Cancel</Button>
              <Button size="sm" className="ms-1" color="primary" onClick={handleDisable}>Disable</Button>

            </div>
          </div>
        </div>
        )}

      </div>

      <div
        className={classnames(['actions-overlay', { open: props.isOpen }])}
        onClick={handleClose}
      />

      <Suspense fallback={<div />}>
        <LazyBulkEditModal
          isOpen={bulkEditModal}
          close={toggleBulkEditModal}
          rule={props.rule}
          refreshRules={rulesContext.api.refreshRules}
        />
      </Suspense>

      {allowCreate === true && (
      <Suspense fallback={<div />}>
        <LazySettingCopyModal
          isOpen={settingCopyModal}
          close={toggleSettingCopyModal}
          isDirtyRule={props.isDirty}
          rule={props.rule}
        />
      </Suspense>
      )}

      {allowCreate === true && (
      <Suspense fallback={<div />}>
        <LazySettingPasteModal
          isOpen={settingPasteModal}
          close={toggleSettingPasteModal}
          rule={props.rule}
          onChange={props.onChange}
          onPasteRule={props.onPasteRule}
        />
      </Suspense>
      )}

      <Suspense fallback={<div />}>
        <LazyBatchRuleModal
          isOpen={batchRuleModal}
          close={toggleBatchRuleModal}
          rule={props.rule}
          attributes={rulesContext.attributes}
          attributeGroups={rulesContext.attributeGroups}
          refreshRules={rulesContext.api.refreshRules}
        />
      </Suspense>
    </>
  );
};

ActionsPanel.propTypes = {
  isOpen: PropTypes.bool,
  rule: PropTypes.object,
  isRootRule: PropTypes.bool,
  isDirty: PropTypes.bool,
  onChange: PropTypes.func,
  onPasteRule: PropTypes.func,
  onSaveRule: PropTypes.func,
};

ActionsPanel.defaultProps = {
  isOpen: false,
};

export default ActionsPanel;
