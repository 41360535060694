import React, {useCallback} from "react";
import { FormGroup, FormText, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useDebouncedCallback } from 'use-debounce';
import StandardAlert from '../../../../Layout/StandardAlert';
import ProxyBidding from './ProxyBidding';
import BidWidget from '../../../../Components/Rules/BidWidget';
import SchedulableSettingInput from '../../../../Components/Rules/SchedulableSettingInput';
import ValueDeltaDisplay from '../../../../Components/ValueDeltaDisplay';
import { MoneyFormatter, PercentFormatter } from '../../../../Components/Table/Table';
import usePermission from '../../../../Hooks/usePermission';
import InputWithIcon from '../../../../Components/Form/InputWithIcon';
import {BiddingScheduleConfig} from "../const";

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 3,
  // fixedDecimalScale:true
});



const Bidding = (props) => {
  const allowEditIntg = usePermission('integrations.edit');
  const allowEditOrder = usePermission('orders.edit');
  if (!props.hasOwnProperty('type')) return (null);

  const handleChangeCeilingDebounced = useDebouncedCallback((val) => {
    props.onChange('bid_ceiling', val);
  }, 400);

  const handleChangeSchedule = useCallback((sched, tz) => {
    props.onChangeSchedule('schedule_modifiers', sched, tz);
  }, []);

  const absBidError = props.errors['bidding.absolute_bid'];
  const modifierError = props.errors['bidding.bid_modifier'];
  const proxyError = props.errors.proxy_bidding;

  return (
    <div id="rule-bidding">

      {(absBidError || modifierError || proxyError)
      && (
      <StandardAlert color="info" className="validation">
        Please correct the errors below before continuing
      </StandardAlert>
      )}

      <div className="form-section">
        <div className="form-section-header">Bidding</div>

        <BidWidget
          type={props.type}
          absolute_bid={props.absolute_bid}
          bid_modifier={props.bid_modifier}
          onChange={props.onChange}
          showExclusivity
          exclusivity={props.exclusivity}
          isActiveTab={props.isActiveTab}
          errors={props.errors}
        />

        {allowEditIntg && (
        <FormGroup className="d-inline-block me-3">
          <Label>Bid Ceiling</Label>
          <MaskedInput
            id="inp-bid-ceiling"
            mask={currencyMask}
            defaultValue={props.bid_ceiling || ''}
            onChange={(e) => handleChangeCeilingDebounced(e.target.value)}
            name="bid_ceiling"
            render={(ref, props) => <InputWithIcon className="tiny" innerRef={ref} icon="dollar-sign" {...props} />}
          />
          <FormText>If set, final adjusted bid may not exceed this dollar amount</FormText>
        </FormGroup>
        )}

      </div>

      <div className="form-section">
        <div className="form-section-header">Bidding Schedule</div>
        <p>Modify bids by day and hour (of the consumer)</p>
        <div>
          <FormGroup>

            { (props.type !== 'block') ? (
              <SchedulableSettingInput
                setting="bid"
                scheduleSetting="schedule_modifiers"
                scheduleTimezone={props.scheduleTimezone}
                title="Bid Modifier"
                summaryNote="Scheduled modifiers are a percentage of the bid determined by this rule and/or parent rules."
                config={BiddingScheduleConfig}
                hideInput
                allowEdit={allowEditOrder}
                value=""
                schedule={props.schedule}
                onChange={handleChangeSchedule}
                size="lg"
              />
            ) : (
              <FormGroup>
                <StandardAlert color="light" icon="info">
                  Schedule inactive: bidding blocked.
                </StandardAlert>
              </FormGroup>

            )}
          </FormGroup>

        </div>
      </div>

      <ProxyBidding
        proxy={props.proxyBidding}
        onChange={(config) => props.onChange('proxy_bidding', config)}
        errors={proxyError}
      />

    </div>
  );
};

const BidAlert = ({
  type, bid, previousBid, previousType, defaultMsg = '',
}) => {
  let alert = '';
  if (type === 'modifier') {
    bid = parseInt(bid);

    if (bid === 0) {
      alert = 'Setting the modifier to zero will disable bidding. Instead, choose Block as a bid type.';
    } else if (bid === 100 && previousType === type) {
      alert = 'A modifier of 100% will not modify the bid.';
    } else if (bid && previousBid !== bid && previousType === type) {
      alert = (
        <span>
          Bid change:
          <ValueDeltaDisplay oldValue={previousBid} newValue={bid} threshold={1000} />
          {' '}
          from
          <PercentFormatter value={previousBid} />
        </span>
      );
    }
  } else if (type === 'absolute') {
    bid = parseFloat(bid);

    if (bid === 0) {
      alert = 'Setting the bid to zero will disable bidding. You can also choose Block as a bid type.';
    } else if (bid && parseFloat(previousBid) !== bid && previousType === type) {
      alert = (
        <span>
          Bid change:
          <ValueDeltaDisplay oldValue={previousBid} newValue={bid} threshold={1000} />
          {' '}
          from
          <MoneyFormatter value={previousBid} />
        </span>
      );
    }
  }
  //
  return (<FormText className="bid-alert">{alert || defaultMsg}</FormText>);
};

// const BidAlert = ({type, bid, previousBid, defaultMsg=''}) => {
//   let alert = '';
//   if(type === 'modifier' && parseInt(bid) === 0){
//     alert = ' Setting the modifier to zero will disable bidding. Instead, choose Block as a bid type.';
//   }else if(type === 'modifier' && parseInt(bid) === 100){
//
//     alert = ' An modifier of 100% will not modify the bid.';
//   }else if(type === 'absolute'){
//     bid = parseFloat(bid);
//     if(bid === 0){
//       alert = ' Setting the bid to zero will disable bidding. You can also choose Block as a bid type.';
//     } else {
//       alert = <span>Bid change: <ValueDeltaDisplay delta={getDelta(parseFloat(previousBid), bid)}/></span>
//     }
//
//   }
//   //
//   return(<FormText>{alert || defaultMsg}</FormText>)
//
// };

Bidding.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeSchedule: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  absolute_bid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bid_modifier: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bid_ceiling: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  proxyBidding: PropTypes.object,
  errors: PropTypes.object,
  product: PropTypes.string,
  isActiveTab: PropTypes.bool,
  exclusivity: PropTypes.string,
};

export default React.memo(Bidding);
