import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import Cards from './Cards';
import BalanceAndPlan from './BalanceAndPlan';
import Activity from './Activity';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
// import LoaderDots from "../../../Layout/LoaderDots";
import StandardAlert from '../../../../Layout/StandardAlert';
import { AppContext } from '../../../../Providers/AppProvider';
import useApi from "../../../../Hooks/useApi";

// Accounting API will not return null fields.
// Use this so we have a complete data structure.
const AccountDefault = {
  id: 0,
  balance: 0,
  cash_balance: 0,
  credit_balance: 0,
  credit_cards: [],
  payment_plan: {},
};

/*
 * Component for managing credit cards, adding funds to your account, and managing
 * payment plans
 */
const Funds = ({ accountId, accountTimezone }) => {
  const app = useContext(AppContext);
  const Accounting = React.useMemo(() => app.getApi('accounting'), []);
  const { api } = useApi();

  // Account is an entity from Accounting API, not same as core
  const [account, setAccount] = useState({
    id: null, // same as core account id
    balance: null,
    credit_cards: null,
    payment_plan: null,
  });
  const [transfereeAccountOptions, setTransfereeAccountOptions] = useState([])
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // On mount
  useEffect(() => {
    if (accountId) {
      getAccounting();
    }
  }, [accountId]);

  const getAccountingData = async () => {
    const account = await Accounting.getAccount(accountId);

    if (account) {
      setAccount({ ...AccountDefault, ...account });
    } else {
      notify(`Unable to get accounting data: ${Accounting.error.name}`, 'error');
    }

    const transferAccounts = await api.endpoints.accounts.list()
    if (transferAccounts) {
      setTransfereeAccountOptions(transferAccounts.filter( acc => acc.id.toString() !== accountId.toString()).map(acc => {
        return {value: acc.id, label: acc.name}
      }))
    }
  }

  const getAccounting = async () => {
    setIsLoading(true);

    getAccountingData();

    setIsLoading(false);
  };

  const refreshAccount = async () => {
    getAccountingData()
  };

  const savePaymentPlan = async (plan) => {
    setIsUpdating(true);
    const result = await Accounting.updateAccount(accountId, { payment_plan: plan });
    if (result) {
      notify('The payment plan has been updated', 'success');
    } else {
      notify(`The payment plan could not be saved: ${Accounting.error}`, 'error');
    }
    setIsUpdating(false);
  };

  const addCredit = async (amount) => {
    setIsUpdating(true);
    const result = await Accounting.addAccountCredit(accountId, amount);
    if (result) {
      getAccounting();
      notify('The account has been credited', 'success');
    } else {
      notify(`The account could not be credited: ${Accounting.error}`, 'error');
    }
    setIsUpdating(false);
  };

  // if(isLoading) return (<div style={{minHeight:'200px',position:'relative'}}><LoaderDots active={true}/></div>);
  if (isLoading) return (<div className="text-center" style={{ minHeight: '200px' }}><Spinner size="lg" /></div>);

  return (
    <div id="manage-funds-section">

      {account.id === null && <StandardAlert color="light">Accounting data is unavailable for this account</StandardAlert> }

      {account.id && (
        <>

            <Row>
              <Col sm={12} md={10}>
                <BalanceAndPlan
                  balance={account.balance}
                  cashBalance={account.cash_balance}
                  creditBalance={account.credit_balance}
                  plan={account.payment_plan}
                  savePlan={savePaymentPlan}
                  addCredit={addCredit}
                  isUpdating={isUpdating}
                  cards={account.credit_cards}
                  accountTimezone={accountTimezone}
                  accountId={accountId}
                  transfereeAccountOptions={transfereeAccountOptions}
                  refreshAccount={refreshAccount}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm={12} md={10}>
                <Cards
                  cards={account.credit_cards}
                  accountId={accountId}
                  refreshAccount={getAccounting}
                />
              </Col>

            </Row>
{/*

            <Row className="mt-3">
              <Col sm={12}>
                <Activity
                  cards={account.credit_cards}
                  accountId={accountId}
                  refreshAccount={getAccounting}
                />
              </Col>
            </Row>
*/}


          {/* <Col widths={['sm','md','lg','xl', 'xxl']}  lg={{ size: 12, order: 1}}  xl={{ size: 4, order: 2}} >
            <BalanceAndPlan
              balance={account.balance}
              cashBalance={account.cash_balance}
              creditBalance={account.credit_balance}
              plan={account.payment_plan}
              savePlan={savePaymentPlan}
              addCredit={addCredit}
              isUpdating={isUpdating}
              cards={account.credit_cards}
            />
          </Col> */}

        </>
      )}

    </div>
  );
};

Funds.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accountTimezone: PropTypes.string,
};

export default Funds;
