import React, { useState, useEffect } from 'react';
import {
  Input,
  Button,
  Form,
  FormGroup,
  Label,
  Col, FormText, FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import copy from 'fast-copy';
import { useDebouncedCallback } from 'use-debounce';
import ConfigSettingsForm from './ConfigSettingsForm';

const ConfigEdit = (props) => {
  // The rule loads all possible integration configurations for the vertical/product, so
  // here we select the config by name
  const getDefinition = (name) => props.definitions.find((conf) => conf.name === name);
  const [originalData, setOriginalData] = useState(props.config);
  const [selectedDefinition, setSelectedDefinition] = useState(() => {
    // set selected to existing config, or default if only 1 config exists, else no defined selection
    if (props.config.name) {
      return getDefinition(props.config.name);
    }
    return props.definitions.length === 1 ? props.definitions[0] : undefined;
  });

  // If using service chooser
  // Handle changing the integration service, which changes the available settings
  const handleChangeService = (name) => {
    const def = getDefinition(name);
    setSelectedDefinition(def);

    if (!def) {
      props.onUpdate({ name: '', settings: '' }, props.index);
    } else if (originalData && originalData.name === name) {
      props.onUpdate(copy(originalData), props.index);
    } else {
      const newSettings = {};
      if (def.supported_settings) {
        def.supported_settings.map((s) => newSettings[s.name] = '');
      }

      const newConfig = { name, settings: newSettings };
      props.onUpdate(newConfig, props.index);
    }
  };

  const handleChangeTimeout = useDebouncedCallback((val) => {
    let newConfig = copy(props.config)
    newConfig.timeout = val
    props.onUpdate(newConfig, props.index)
  }, 400)

  // on enter edit mode, update parent if config already selected
  useEffect(() => {
    if (selectedDefinition) {
      handleChangeService(selectedDefinition.name);
    }
  }, []);

  const handleApply = () => {
    setOriginalData(props.config);
    props.onApply();
  };

  const handleCancel = () => {
    if (props.isNew) {
      props.onDelete(props.index);
    } else {
      if (originalData) {
        props.onUpdate(copy(originalData), props.index);
        setSelectedDefinition(getDefinition(originalData.name));
      }
      props.onApply();
    }
  };

  return (
    <Form>
      {props.showServiceChooser && (
      <ServiceSelect
        definitions={props.definitions}
        value={selectedDefinition ? selectedDefinition.name : ''}
        onChange={handleChangeService}
        hasSettings={selectedDefinition && selectedDefinition.supported_settings?.length > 0}
        error={props.errors['integration.name']}
      />
      )}


      {selectedDefinition !== undefined && (
        <>
          {Array.isArray(selectedDefinition.supported_settings) && selectedDefinition.supported_settings.length > 0 && (
          <ConfigSettingsForm
            index={props.index}
            definition={selectedDefinition}
            config={props.config}
            onUpdate={props.onUpdate}
            errors={{}}
          />
          )}

          <FormGroup row>
            <Label sm={3} for="settings">Custom Timeout</Label>
            <Col sm={9}>
              <Input
                type="number"
                name="timeout"
                value={props.config.timeout}
                onChange={(e) => handleChangeTimeout(e.target.value)}
              />
              <FormText>In Seconds. Integrations use a sensible default timeout. Only override this carefully!</FormText>
            </Col>
          </FormGroup>

        </>
      )}


      {props.allowControls && (
      <div className="d-flex justify-content-end">
        {props.isNew === true ? (
          <>
            <Button className="mt=2 me-2" size="sm" color="link" onClick={handleCancel} >Cancel</Button>
            <Button className="mt=2 me-2" size="sm" onClick={handleApply} disabled={!selectedDefinition}>Add</Button>
          </>
        ) : (
          <>
            {/*  disabled={!props.config.name} */}
            <Button className="mt=2 me-2" size="sm" color="link" onClick={handleCancel} >Cancel</Button>
            <Button className="mt=2 me-2" size="sm" onClick={handleApply} disabled={!selectedDefinition}>Apply</Button>
          </>
        )}
      </div>
      )}

    </Form>
  );
};

/*
 * Dropdown for selecting a service (such as for integration), which
 * will change the available settings
 */
const ServiceSelect = ({
  definitions, value, hasSettings, onChange, error,
}) => {
  if (definitions.length === 0) {
    return (<span>No integrations are available.</span>);
  }

  return (
    <FormGroup row>
      <Label sm={3} for="integration">Service</Label>
      <Col sm={9}>
        <Input
          type="select"
          name="integration"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          invalid={!!error}
        >
          <option value="">Select... </option>
          {definitions.map((conf, i) => <option value={conf.name} key={i}>{conf.label}{conf.status !== "active" && ` [${conf.status.toUpperCase()}]`}</option>)}
        </Input>
        {value && hasSettings === false && <FormText>This service has no settings</FormText>}
        <FormFeedback>{error}</FormFeedback>

      </Col>
    </FormGroup>
  );
};

ConfigEdit.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  allowControls: PropTypes.bool,
  isNew: PropTypes.bool,
  showServiceChooser: PropTypes.bool,
  definitions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // definitions for all available configurations
  config: PropTypes.object, // current setting values
  onUpdate: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  onDelete: PropTypes.func,
  errors: PropTypes.object,
};

export default ConfigEdit;
