import React from 'react';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Block from './Block';
import { MoneyFormatter } from '../../../Components/Table/Table';
import {HelpPopper, RelativeTime, UrlDisplayWidget, ExpandableSection}  from '@thedmsgroup/mastodon-ui-components';

/*
 * Auction bid detail
 */
const Bid = ({
  position,
  transaction_id,
  account_bid,
  vendor_bid,
  internal_bid_modifiers,
  adjusted_account_bid,
  blocks,
  sales = [],
  sale,
  sale_attempt,
}) => (
  <div className="bid">
    <div className="form-section-header mb-2 fs-5">
      {sale ? (
        <span>
          <FaIcon icon="star" color="gold" />
          {' '}
          Bid Sold
        </span>
      ) : <span><FaIcon icon="hand-holding-usd" /> Bid Offered</span>}
    </div>
    <div className="data-row">
      <label>Position</label>
      <div>{position}</div>
    </div>

    <div className="data-row">
      <label>Sale Attempt</label>
      <div>{sale_attempt}</div>
    </div>

    <div className="data-row">
      <label>Account Bid</label>
      <div>
        {account_bid ? <MoneyFormatter value={account_bid} /> : <i>none</i>}
        {adjusted_account_bid > 0 && adjusted_account_bid !== account_bid && <>
          {' '}<FaIcon icon="arrow-right" /> <MoneyFormatter value={adjusted_account_bid} />
          <HelpPopper name="what-is-this" iconClass="ms-2">The buyer has adjusted their bid on the sale</HelpPopper>
        </>}
      </div>
    </div>

    <div className="data-row">
      <label>Vendor Bid</label>
      <div>{vendor_bid ? <MoneyFormatter value={vendor_bid} /> : <i>none</i>}</div>
    </div>

    {transaction_id && <div className="data-row">
      <label>Transaction ID</label>
      <div>{transaction_id}</div>
    </div>}

    <div className="data-row">
      <label>Blocks</label>
      <div>
        { blocks && blocks.length > 0 ? (
          <>
            {blocks.map((b, i) => <Block {...b} key={i} />)}
          </>
        ) : (
          "-"
        )}
      </div>

    </div>

    {sale && (
    <div className={classnames(['data-row', { 'flex-column': sale }])}>
      <label>Sale</label>
      <div>
        <div className="sale">
          <div className="ms-2">
            <div className="sub-row">
              <div className="key">Sale ID</div>
              <div>{sale.sale_id}</div>
            </div>
            {sale.redirect_url && <div className="sub-row">
              <div className="key">Redirect Url</div>
                <div>
                  <ExpandableSection>
                    <UrlDisplayWidget title="Redirect URL" url={sale.redirect_url} />
                  </ExpandableSection>


                </div>

            </div>}
            <div className="sub-row">
              <div className="key">
                When:
              </div>
              <div><RelativeTime date={sale.sold_at} /></div>
            </div>
          </div>
        </div>
      </div>

    </div>
    )}

  </div>
);

export default Bid;
