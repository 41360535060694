import React, { useState, useEffect } from 'react';
import {
  FormGroup, Button, Input, Label, Card, CardHeader, CardBody, Spinner,
} from 'reactstrap';
//import createNumberMask from 'text-mask-addons/dist/createNumberMask';
//import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { isDate } from 'date-fns';
import Flatpickr from 'react-flatpickr';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
//import InputWithIcon from '../../../../Components/Form/InputWithIcon';
import StandardAlert from '../../../../Layout/StandardAlert';
import { DateFormatter, MoneyFormatter } from '../../../../Components/Table/Table';
import 'flatpickr/dist/themes/airbnb.css';
import useFormState from '../../../../Hooks/useFormState';
import { titleCaseWord } from '../../../../utils/string';
import usePermission from '../../../../Hooks/usePermission';
import useTimezoneDisplay from '../../../../Hooks/useTimezoneDisplay';
import {MaskedInputGroup, MaskedInput} from "@thedmsgroup/mastodon-ui-components/lib/components/Form/MaskedInput";
import TransfersInline from "./TransfersInline";

const validationSchema = Yup.object().shape({
  type: Yup.mixed().notRequired(),
  amount: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).moreThan(0, 'Please enter an amount greater than zero').required('Please set a dollar amount'),
  threshold: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
    is: 'recharge',
    then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).moreThan(0, 'Set a threshold greater than zero').required('Please set a threshold amount'),
  }),
  interval: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
    is: 'subscription',
    then: Yup.number().transform((n) => ((isNaN(n) || n === null) ? -1 : parseInt(n))).moreThan(0, 'Day interval is required'),
  }),
  charge_date: Yup.mixed().when('type', { // note, error property will be 'bidding.bid_modifier'
    is: 'subscription',
    then: Yup.string().required('Start date is required'),
  }),

});

const DefaultMaskOptions = {
  mask: Number,
  unmask: "typed",
  scale:0,
  max:99999,
  signed: false,
  radix:".",
  thousandsSeparator:","
}

const CreditMaskOptions = {...DefaultMaskOptions, signed:true, min:-99999}
const PlanAmountMaskOptions = {...DefaultMaskOptions}

const IntervalMaskOptions = {...DefaultMaskOptions, max:999, min:1}


const pickerOptions = {
  enableTime: false,
  minDate: 'today',
  dateFormat: 'm-d-Y',
  altInput: true,
  altFormat: 'F j, Y',
};

// formState hook controls payment plan form
const initialPlanFormState = {
  type: '',
  amount: '',
  interval: 14,
  charge_date: new Date(),
  threshold: '',
};

// Note: API account request will not have a payment_plan field if there is no plan,
// so the currentPlan property could be undefined
const BalanceAndPlan = ({
  plan,
  balance,
  cashBalance,
  creditBalance,
  savePlan,
  addCredit,
  isUpdating,
  cards,
  accountTimezone,
  accountId, transfereeAccountOptions,
  refreshAccount
}) => {

  const [planEditMode, setPlanEditMode] = useState(false);
  const [addCreditMode, setAddCreditMode] = useState(false);
  const [transferCashMode, setTransferCashMode] = useState(false);
  const [newCreditAmount, setNewCreditAmount] = useState('');
  const [balanceError, setBalanceError] = useState(null);
  const [hasCards, setHasCards] = useState(cards.length > 0);
  const [hasAutoBillCard, setHasAutoBillCard] = useState(cards.some((card) => card.auto_billing_eligible));
  const {
    formApi, formIsValid, formValues, formErrors,
  } = useFormState({ ...initialPlanFormState, ...plan }, validationSchema);
  const { formatToTimezone } = useTimezoneDisplay(accountTimezone);
  const allowEdit = usePermission('accounting.edit');
  const allowView = usePermission('accounting.view');
  const allowAddCredit = usePermission('accounting_credit.create');
  const allowAccountingTransferCreate = usePermission('accounting_transfers.create');

  if (!allowView) return (<span>Not authorized</span>);

  const toggleTransferCashMode = () => setTransferCashMode(!transferCashMode);

  useEffect(() => {
    setHasCards(cards.length > 0);
    if (cards.length) {
      setHasAutoBillCard(cards.some((card) => card.auto_billing_eligible));
    }
  }, [cards]);

  const handleAddCredit = async () => {
    const amt = parseInt(newCreditAmount.toString().replace(',', ''));

    if (amt === 0 || isNaN(amt)) {
      setBalanceError('Credit amount is required');
    } else {
      setBalanceError(null);
      await addCredit(amt);
      setAddCreditMode(false);
    }
  };

  const handleSavePlan = async () => {
    // PROBLEM: subscription charge date is string on load. But date widget changes it to Date.
    // If we save to account timezone, it could display one day behind
    //  "2021-09-16T03:00:00Z"  displays as 9:15 because converted to browser timezone PST
    // See how we are doing it in Rules/ Advanced.js (quaifying rate start )

    // If valid, convert values for API
    //console.log('BalanceAndPlan.js: save plan formvalue', formValues.charge_date);
    //console.log('BalanceAndPlan.js: to timezone', accountTimezone, formatToTimezone(formValues.charge_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"));

    if (formApi.validate()) {
      let newPlan = null;
      if (formValues.type === 'subscription') {
        newPlan = (({
          type, amount, interval, charge_date,
        }) => ({
          type, amount, interval, charge_date,
        }))(formValues);

        if (isDate(newPlan.charge_date)) {
          newPlan.charge_date = formatToTimezone(
            isDate(newPlan.charge_date) ? newPlan.charge_date.toString() : newPlan.charge_date,
            "yyyy-MM-dd'T'HH:mm:ss'Z'",
          );
        }

        // will take "2020-05-01T00:00:00-07:00"  or "2020-05-01T00:00:00Z"
        // newPlan.charge_date = format(newPlan.charge_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"); //newPlan.charge_date.toString();//moment(newPlan.charge_date).format(); // 2020-04-27T00:00:00-07:00
        newPlan.interval = parseInt(newPlan.interval);
        // need to unmask the dollar values
        newPlan.amount = parseInt(newPlan.amount.toString().replace(',', ''));
        // TODO: user timezone
      } else if (formValues.type === 'recharge') {
        newPlan = (({ type, amount, threshold }) => ({ type, amount, threshold }))(formValues);
        newPlan.threshold = parseInt(newPlan.threshold.toString().replace(',', ''));
        newPlan.amount = parseInt(newPlan.amount.toString().replace(',', ''));
      }
      // updatePlan(newPlan)
      await savePlan(newPlan);
      setPlanEditMode(false);
    }
  };

  const toggleAddCredit = () => {
    if (allowAddCredit) {
      if (addCreditMode) {
        setAddCreditMode(false);
        setBalanceError('');
      } else {
        if (planEditMode) {
          formApi.clearForm();
          setPlanEditMode(false);
        }
        setAddCreditMode(true);
      }
    }

  };

  const toggleEditPlan = () => {
    if (planEditMode) {
      formApi.clearForm();
      setPlanEditMode(false);
    } else {
      setAddCreditMode(false);
      setNewCreditAmount('');
      setPlanEditMode(true);
    }
  };

  const handleTransfersClose = (refresh) => {
    setTransferCashMode(false);
    if (refresh === true) {
      refreshAccount();
    }
  }

  return (
    <div className="accounting-details form-section d-flex p-4 mb-2">

      <Card className="account-balance  me-4">
        <CardHeader>Account Balance</CardHeader>
        <CardBody>

          {balanceError && (
          <StandardAlert color="light" className="">
            {balanceError}
          </StandardAlert>
          )}

          <div className="detail-table">
            <div className="detail-rows">
              <div className="detail-row">
                <Label>Balance</Label>
                <div className="value">
                  {balance === 0 && <FaIcon icon="exclamation-triangle" color="#990000" size="1x" className="me-1" /> }
                  <MoneyFormatter className="balance" value={balance} />
                </div>
              </div>
              <div className="detail-row">
                <Label>
                  Credit
                  {allowAddCredit && !addCreditMode && <Button color="link" size="sm" className="inline ms-2" onClick={toggleAddCredit}>Add</Button>}
                </Label>
                <div className="value flex-fill">
                  {addCreditMode === true ? (
                    <div className="add-credit d-flex align-items-center justify-content-end">
                      {isUpdating === true && <Spinner size="sm" className="me-1" /> }

                      <MaskedInputGroup
                        className="teeny me-2"
                        title="Add Credit"
                        prefix="$"
                        {...CreditMaskOptions}
                        value={newCreditAmount}
                        onAccept={(value) => setNewCreditAmount(value)}
                        bsSize="sm"
                        />

                      <Button size="xs" color="primary" className="ms-1 me-1" disabled={isUpdating} onClick={handleAddCredit}>Add</Button>
                      <Button size="xs" outline className="me-2" onClick={toggleAddCredit}><FaIcon icon="times" /></Button>
                      <span><MoneyFormatter value={creditBalance} /></span>
                    </div>
                  ) : (
                    <span><MoneyFormatter value={creditBalance} /></span>
                  )}

                </div>
              </div>
              <div className="detail-row">
                <Label>Cash {
                  allowAccountingTransferCreate
                  && transfereeAccountOptions.length > 0
                  && <Button
                    color="link"
                    size="sm"
                    className="inline ms-2"
                    onClick={toggleTransferCashMode}
                    disabled={transferCashMode}
                    title="Transfer cash to another account"
                  >Transfer</Button>
                }
                </Label>
                <div className="value"><MoneyFormatter value={cashBalance} /></div>
              </div>

              {allowAccountingTransferCreate && transferCashMode && transfereeAccountOptions.length > 0 &&
                <TransfersInline accountId={accountId} accountOptions={transfereeAccountOptions} close={handleTransfersClose}/>}

            </div>
          </div>
        </CardBody>
      </Card>

      <Card className="payment-plan">
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <div>Payment Plan</div>
            {allowEdit && (
              <Button className="inline" color="link" size="sm" onClick={toggleEditPlan}>
                <FaIcon icon="pencil-alt" size="sm" />
              </Button>
            )}
          </div>


        </CardHeader>
        <CardBody>

          {!formIsValid && (
          <StandardAlert color="light" className="">
            {Object.values(formErrors)[0]}
          </StandardAlert>
          )}

          {hasCards === false && formValues.type && (
          <StandardAlert color="light" className="">
            The selected plan requires a credit card
          </StandardAlert>
          )}

          {hasCards && !hasAutoBillCard && formValues.type && (
            <StandardAlert color="light" className="">
              A credit card must be configured for use with automatic payments
            </StandardAlert>
          )}

          <div className="detail-table mb-2">

            <div className="detail-rows">
              <div className="detail-row d-flex">
                <Label>Billing</Label>
                <div className="description flex-fill">
                  <small>
                    {formValues.type === 'recharge' && <i>Keep cash balance above a threshold</i>}
                    {formValues.type === 'subscription' && <i>Add funds at regular intervals</i>}
                    {!formValues.type && <i>No automatic payments</i>}
                  </small>
                </div>
                <div className="value">
                  {planEditMode === true ? (
                    <Input type="select" size="sm" name="type" onChange={formApi.handleOnChange} value={formValues.type}>
                      <option value="">None</option>
                      <option value="subscription">Subscription</option>
                      <option value="recharge">Recharge</option>
                    </Input>
                  ) : (
                    <span>{titleCaseWord(formValues.type) || (null)}</span>

                  )}
                </div>
              </div>

              {formValues.type !== '' && (
              <div className="detail-row d-flex">
                <Label>Amount</Label>
                <div className="description">&nbsp;</div>
                <div className="value">

                  {planEditMode === true ? (
                    <MaskedInputGroup
                      className="teeny me-2"
                      prefix="$"
                      placeholder="Amount"
                      {...PlanAmountMaskOptions}
                      value={formValues.amount ?? ''}
                      onAccept={(value) => formApi.handleOnChange('amount', value)}
                      invalid={!!formErrors.amount}
                      bsSize="sm"
                    />
                  ) : (
                    <span><MoneyFormatter value={formValues.amount} /></span>
                  )}
                </div>
              </div>
              )}

              {formValues.type === 'recharge' && (
              <div className="detail-row d-flex">
                <Label>Threshold</Label>
                <div className="description flex-fill"><small><i>Maintain cash balance at or above:</i></small></div>
                <div className="value">

                  {planEditMode === true ? (
                    <MaskedInputGroup
                      className="teeny me-2"
                      prefix="$"
                      {...{...PlanAmountMaskOptions, max:99999999}}
                      value={formValues.threshold ?? ''}
                      onAccept={(value) => formApi.handleOnChange('threshold', value)}
                      invalid={!!formErrors.threshold}
                      placeholder="Threshold"
                      bsSize="sm"
                    />
                  ) : (
                    <span><MoneyFormatter value={formValues.threshold} /></span>
                  )}
                </div>
              </div>
              )}

              {formValues.type === 'subscription' && (
              <div className="detail-row d-flex">
                <Label>Interval</Label>
                <div className="description flex-fill"><small><i>Days between charges</i></small></div>
                <div className="value">
                  {planEditMode === true ? (

                    <MaskedInput
                      className="teeny me-2"
                      {...IntervalMaskOptions}
                      value={formValues.interval}
                      onAccept={(value) => formApi.handleOnChange('interval', value)}
                      invalid={!!formErrors.interval}
                      placeholder="days"
                    />

                  ) : (
                    <span>
                      {formValues.interval}
                      {' '}
                      days
                    </span>
                  )}
                </div>
              </div>
              )}

              {formValues.type === 'subscription' && (
              <div className="detail-row d-flex">
                <Label>Charge Date</Label>
                <div className="description flex-fill"><small><i>Charges start from this day forward</i></small></div>
                <div className="value">
                  {planEditMode === true ? (
                    <FormGroup>
                      <Flatpickr
                        options={pickerOptions}
                        value={formValues.charge_date}
                        className="form-control form-control-sm"
                        placeholder="Select date..."
                        onChange={(date) => formApi.handleOnChange('charge_date', date[0])}
                      />
                    </FormGroup>
                  ) : (
                    <span>
                      <DateFormatter value={formValues.charge_date} format="MMM do, y" timezone={accountTimezone} />
                      {' '}
                    </span>
                  )}
                </div>
              </div>
              )}

            </div>

            {planEditMode === true && allowEdit && (
            <div className=" d-flex mt-3">
              <div className="ms-auto">
                {isUpdating === true && <Spinner size="sm" className="me-1" /> }
                <Button size="sm" color="link" onClick={toggleEditPlan}>Cancel</Button>
                <Button size="sm" color="primary" onClick={handleSavePlan} disabled={isUpdating} className="me-2">Save Plan</Button>

              </div>
            </div>
            )}
          </div>

        </CardBody>
      </Card>

    </div>

  );
};

BalanceAndPlan.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cashBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditBalance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plan: PropTypes.object,
  updatePlan: PropTypes.func,
  savePlan: PropTypes.func,
  addCredit: PropTypes.func,
  cards: PropTypes.array,
  accountTimezone: PropTypes.string,
};

export default BalanceAndPlan;
