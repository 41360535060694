import React, { useEffect, useState, useCallback } from 'react';
import {
  TabContent, TabPane, Nav, NavLink, NavItem, Button,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {faBrain} from "@fortawesome/free-solid-svg-icons";
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { titleCase } from '../../../utils/string';
import Match from './Match';
import Deliveries from './Deliveries';
import Lead from './Lead';
import Call from './Call';
import Timeline from './Timeline';
import MatchFilters from './MatchFilters';
import LoaderDots from '../../../Layout/LoaderDots';
import useApi from '../../../Hooks/useApi';
import './styles.scss';
import {matchSorter} from "match-sorter";
import BiddingLog from "./BiddingLog";
import MatchingLog from "./MatchingLog";
import {StandardAlert, JsonDisplay, RelativeTime} from '@thedmsgroup/mastodon-ui-components';
import RoutingModal from "../../Routing/Mapper/Modal";
import EntityLinks from "./EntityLinks";
import HttpLogs from "./HttpLogs";

/*
 * Auction detail view
 * Partitions auction data into formatted sections.
 * Can be used inside routed page view, or modal view from calls & leads lists.
 */
const Auction = ({ auction_id, timezone = 'UTC', defaultTab='matches' }) => {
  const { api } = useApi();
  const [loading, setLoading] = useState(true);
  const [auction, setAuction] = useState(null);
  const [auctionJSON, setAuctionJSON] = useState(null);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [selectedDeliveryId, setselectedDeliveryId] = useState();
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [matchFilters, setMatchFilters] = useState({});
  const [buyers, setBuyers] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const[routingModalFilters, setRoutingModalFilters] = useState({});

  const openRoutingModal = useCallback((filters={}) => {
    setRoutingModalFilters(filters);
  }, []);

  const closeRoutingModal = () => setRoutingModalFilters({});

  // on change auction_id (navigation, manually entering new auction id)
  useEffect(() => {
    if (auction_id) {
      load();
    }

    return () => {
      setAuction(null);
      setLoading(true);
    };
  }, [auction_id]);

  const load = async () => {
    const result = await api.endpoints.debug.auctions(auction_id);
    // If not found, result could be: {"auction":[],"json":null}
    if (result && result.auction && !Array.isArray(result.auction)) {
      if (!result.auction.matches) {
        result.auction.matches = []
      }
      if (!result.auction.deliveries) {
        result.auction.deliveries = []
      }

      // Choices for filtering
      const matchBrokers = [];
      const matchBuyers = [];
      result.auction.matches.forEach((match) => {
        if (match.broker && !matchBrokers.includes(match.broker)) {
          matchBrokers.push(match.broker);
        }
        if (match.buyer && !matchBuyers.includes(match.buyer)) {
          matchBuyers.push(match.buyer);
        }
      });

      setBrokers(matchBrokers);
      setBuyers(matchBuyers);
      setFilteredMatches(result.auction.matches);

      setAuction(result.auction);
      setAuctionJSON(JSON.parse(result.json));
    } else {
      console.log('Unable to load auction data', api.error);
    }
    setLoading(false);
  };

  const handleChangeMatchFilters = (filters) => {
    let matches = [...auction.matches];

    if (filters.buyer) {
      matches = matchSorter(matches, filters.buyer, {
        keys: ['buyer'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (items) => items, // disabled match sorting
      });
    }
    if (filters.broker) {
      matches = matchSorter(matches, filters.broker, {
        keys: ['broker'],
        threshold: matchSorter.rankings.CONTAINS,
        sorter: (items) => items, // disabled match sorting
      });
    }
    setFilteredMatches(matches);
    setMatchFilters(filters);
  };

  const handleDeliverySelect = (id) => {
    setActiveTab('deliveries')
    setselectedDeliveryId(id)
  }

  return (

    <div className="auction-detail d-flex">

      <LoaderDots active={loading} width={160} />

      {!auction && !loading && (
      <div className="flex-fill">
        <StandardAlert color="light">Unable to load auction</StandardAlert>
      </div>
      )}

      {auction && !loading && (

      <>
        <div className="overview">
          <div className="d-flex flex-column">

            <CountDisplay counts={auction.counts} matches={auction.matches} deliveries={auction.deliveries} gotoTab={setActiveTab} />

            {auction.parent_auction_id && <div className="ov-pod">
              <div className="label">Parent Auction</div>
              <div><Button
                onClick={() => openModal(auction.parent_auction_id)}
                color='link'
                className='inline'
              >
                {auction.parent_auction_id}
              </Button></div>
            </div>}

            <div className="ov-pod">
              <div className="label">Vertical</div>
              <div>{auction.vertical.name}</div>
            </div>

            <div className="ov-pod">
              <div className="label">Product</div>
              <div>{titleCase(auction.product)}</div>
            </div>

            <div className="ov-pod">
              <div className="label">Source</div>
              <div>
                {auction.source ? (
                  <EntityLinks
                    entityId={auction.source.id}
                    label={auction.source.name}
                    linkPath="/publishing/sources"
                    linkSearch={`page=1&search=${auction.source.name}`}
                    routingFilter="sourceId"
                    openRoutingModal={openRoutingModal}
                  />
                ) : (
                  <i>unknown</i>
                )}
              </div>
            </div>

            <div className="ov-pod">
              <div className="label">Vendor</div>
              <div>{auction.vendor?.name || <i>unknown</i>}</div>
            </div>

            <div className="ov-pod">
              <div className="label">
                Start
              </div>
              <div>
                <RelativeTime date={auction.started_at}/>
              </div>
            </div>

            <div className="ov-pod">
              <div className="label">Timezone</div>
              <div>{auction.timezone}</div>
            </div>

            <div className="ov-pod">
              <div className="label">App Version</div>
              <div>{auction.app_version}</div>
            </div>

            {auction.feature_flags &&
            <div className="ov-pod">
              <div className="label">Feature Flags</div>
                {auction.feature_flags?.length > 0 ? (
                  <ul className="list-unstyled">
                    {auction.feature_flags.map((flag, i) => <li key={i}>{flag}</li>)}
                  </ul>
                ) : (
                  <i>none</i>
                )}

            </div>}

            {auction.limit &&
            <div className="ov-pod">
              <div className="label">Limit</div>
              {auction.limit}
            </div>}

            {auction.include_buyers && auction.include_buyers.length > 0 &&
            <div className="ov-pod">
              <div className="label">Include Buyers</div>
              <ul className={"list-unstyled"}>
                {auction.include_buyers.map((buyer, i) => <li key={i}>{buyer}</li>)}
              </ul>
            </div>}

            {auction.exclude_buyers && auction.exclude_buyers.length > 0 &&
            <div className="ov-pod">
              <div className="label">Exclude Buyers</div>
              <ul className={"list-unstyled"}>
                {auction.exclude_buyers.map((buyer, i) => <li key={i}>{buyer}</li>)}
              </ul>
            </div>}

            <div className="ov-pod">
              <a className={"float-right"} target="_blank" href={"https://app.datadoghq.com/apm/traces?query=@auction_id:"+auction_id+"&showAllSpans=true&sort=desc&spanType=all"}><img src={require('./datadog.png')} alt="Datadog" /></a>
            </div>

          </div>
        </div>

        <div className="auction-subnav">
          <Nav className="auction-subnav d-flex flex-column flex-fill">

            <AuctionNavItem
              tabId="matches"
              isActive={activeTab === 'matches'}
              onClick={() => setActiveTab('matches')}
              label="Matches & Bids"
              icon="comment-dollar"
            />

            <AuctionNavItem
              tabId="matching_log"
              isActive={activeTab === 'matching_log'}
              onClick={() => setActiveTab('matching_log')}
              label="Matching Log"
              icon={faBrain}
            />

            <AuctionNavItem
              tabId="bidding_log"
              isActive={activeTab === 'bidding_log'}
              onClick={() => setActiveTab('bidding_log')}
              label="Bidding Log"
              icon="comment-dollar"
            />

            <AuctionNavItem
              tabId="deliveries"
              isActive={activeTab === 'deliveries'}
              onClick={() => setActiveTab('deliveries')}
              label="Deliveries"
              icon="envelope"
            />

            <AuctionNavItem
              tabId="lead"
              isActive={activeTab === 'lead'}
              onClick={() => setActiveTab('lead')}
              label="Lead"
              icon="id-card"
            />

            {auction.product === 'calls' && (
            <AuctionNavItem
              tabId="call"
              isActive={activeTab === 'call'}
              onClick={() => setActiveTab('call')}
              label="Call"
              icon="phone"
            />
            )}

            <AuctionNavItem
              tabId="timeline"
              isActive={activeTab === 'timeline'}
              onClick={() => setActiveTab('timeline')}
              label="Timeline"
              icon="stopwatch"
            />

            <AuctionNavItem
              tabId="json"
              isActive={activeTab === 'json'}
              onClick={() => setActiveTab('json')}
              label="JSON"
              icon="cog"
            />

            <AuctionNavItem
              tabId="http_logs"
              isActive={activeTab === 'http_logs'}
              onClick={() => setActiveTab('http_logs')}
              label="HTTP Logs"
              icon="envelope"
            />

          </Nav>
        </div>

        <div className="auction-section ms-3 flex-fill">

          <TabContent activeTab={activeTab}>

            <TabPane tabId="matches" className="">

              <div className="auction-matches">
                {auction.matches?.length > 0 ? (
                  <>
                    <MatchFilters
                      buyers={buyers}
                      brokers={brokers}
                      filters={matchFilters}
                      onChange={handleChangeMatchFilters}
                      resultCount={filteredMatches.length}
                      total={auction.matches.length}
                    />
                    {filteredMatches.map((match, i) => <Match
                      {...match}
                      product={auction.product}
                      key={i}
                      onSelectDelivery={handleDeliverySelect}
                      onShowRouting={openRoutingModal}
                      />
                    )}
                  </>
                ) : (
                  <StandardAlert color="light">
                    This auction has no matches
                  </StandardAlert>
                )}
              </div>
            </TabPane>

            <TabPane tabId="deliveries" className="deliveries">
              {auction && <Deliveries deliveryOptions={auction.deliveries} selectedDeliveryId={selectedDeliveryId} /> }
            </TabPane>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="lead" className="">
                <Lead
                  lead={auction.lead}
                  timezone={timezone}
                  deliveries={auction.deliveries}
                  gotoDelivery={handleDeliverySelect}
                />
              </TabPane>
            </TabContent>

            <TabPane tabId="call" className="">
              <Call call={auction.received_call} ivrCall={auction.ivr_call} ivrLog={auction.ivr_log} />
            </TabPane>

            <TabPane tabId="timeline">
              <Timeline auction={auction} />
            </TabPane>

            <TabPane tabId="bidding_log">
              <BiddingLog auction={auction} />
            </TabPane>

            <TabPane tabId="matching_log">
              <MatchingLog auction={auction} onShowRouting={openRoutingModal} />
            </TabPane>

            <TabPane tabId="json" className="">
              <JsonDisplay data={auctionJSON} />
            </TabPane>

            <TabPane tabId="http_logs" className="http_logs">
              <HttpLogs auctionId={auction.id} />
            </TabPane>

          </TabContent>
        </div>

        <RoutingModal
          isOpen={Object.keys(routingModalFilters).length > 0}
          toggle={closeRoutingModal}
          {...routingModalFilters}
        />

      </>

      )}




    </div>

  );
};

const AuctionNavItem = React.memo(({
  tabId, icon, label, isActive, onClick,
}) => (
  <NavItem className={classnames({ active: isActive })}>
    <NavLink
      className={classnames({ active: isActive })}
      onClick={onClick}
    >
      <FaIcon icon={icon} size="2x" />
      <div className="tab-label">{label}</div>
    </NavLink>
  </NavItem>
));

const CountDisplay = React.memo(({
  matches, deliveries, counts, gotoTab,
}) => {
  const matchBlocks = matches ? matches.find((m) => m.blocks && m.blocks.length > 0) : 0;
  const bidBlocks = matches ? matches.find((m) => m.final_bid?.blocks && m.final_bid.blocks.length > 0) : 0;
  const dvFail = deliveries ? deliveries.find((d) => d.status === 'failed') : 0;

  return (
    <div className="d-flex counts justify-content-between mb-2">

      <div className="count d-flex flex-column" onClick={() => gotoTab('matches')}>
        {matchBlocks && <FaIcon icon="exclamation-triangle" size="sm" title="Has blocks" />}
        <div className="number">{counts.matches}</div>
        <div>{counts.matches === 1 ? 'Match' : 'Matches'}</div>
      </div>

      <div className="count d-flex flex-column" onClick={() => gotoTab('matches')}>
        {bidBlocks && <FaIcon icon="exclamation-triangle" size="sm" />}
        <div className="number">{counts.bids}</div>
        <div>{counts.bids === 1 ? 'Bid' : 'Bids'}</div>
      </div>

      <div className="count d-flex flex-column" onClick={() => gotoTab('matches')}>
        <div className="number">{counts.sales}</div>
        <div>{counts.sales === 1 ? 'Sale' : 'Sales'}</div>
      </div>
      <div className="count d-flex flex-column" onClick={() => gotoTab('deliveries')}>
        {dvFail && <FaIcon icon="exclamation-triangle" size="sm" />}
        <div className="number">{counts.deliveries}</div>
        <div>{counts.deliveries === 1 ? 'Delivery' : 'Deliveries'}</div>
      </div>

    </div>
  );
});

Auction.propTypes = {
  auction_id: PropTypes.string,
  timezone: PropTypes.string,
  defaultTab: PropTypes.string,
};

export default Auction;
