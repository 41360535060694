import React, { FC, useState, useEffect } from 'react';
import {
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { titleCase } from '../../utils/string';
import {StatusType} from "../../types";

type StatusTogglerPropertiesType = {
  status:StatusType,
  target?: any,
  onChange?:(target:any, status:StatusType) => any;
  onChangeAsync?:(target: any, status:StatusType) => any;
  allowEdit?: boolean;
  showArchived?: boolean;
  className?: string;
}

/*
 * Widget that toggles a record's status, designed for a table cell.
 * Note: use the class name 'status-cell' on the containing cell. This adjusts some react-table styles.
 *
 * "target": could be ID or an object, depending on what the status update callback needs
 */
const StatusToggler:FC<StatusTogglerPropertiesType> = ({
  status,
  target,
  onChange,
  onChangeAsync,
  allowEdit,
  showArchived=true,
  className
                                                       }) => {
  // Keeps status in state rather than reading props,
  // because the containing table can take some time to refresh after the status update.
  // This can result in this component "flashing back" to the old status before showing the new,
  // if relying on the props
  const [displayStatus, setDisplayStatus] = useState<string>(status);
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = (newStatus:StatusType) => {
    if (newStatus !== displayStatus) {
     // setIsLoading(true);
      //window.setTimeout(() => {
        updateStatus(newStatus);
     // }, 5000)

    }
  };

  const updateStatus = async (newStatus:StatusType) => {
    if (onChangeAsync) {
      setIsLoading(true);
      setDisplayStatus(newStatus);
      const updated = await onChangeAsync(target, newStatus);
      setIsLoading(false);
      // TODO: could have alert icon if result false
      if (!updated) {
        setDisplayStatus(status);
      }
    } else if (onChange) {
      onChange(target, newStatus);
      setDisplayStatus(newStatus);
    }

  };

  // This handles an odd effect when the list that this widget is in gets filtered.
  // Make sure that state is updated to new prop value
  useEffect(() => {
    if (displayStatus !== status) {
      setDisplayStatus(status);
    }
  }, [status]);

  return (
    <div key={target} className={classnames(["status-toggler", className], {enabled: allowEdit})}>
      {allowEdit ? (
        <>
          {!isLoading ? (
            <UncontrolledDropdown>

              <DropdownToggle tag="div" caret>
                <FaIcon icon="circle" size="xs" className={classnames(['current-status-icon', displayStatus, 'me-1'])} />
                <span>{titleCase(displayStatus)}</span>

              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="status-active" onClick={() => handleStatusChange('active')}>
                  <FaIcon icon="circle" size="xs" className="current-status-icon active" />
                  {' '}
                  Active
                </DropdownItem>
                <DropdownItem className="status-pause" onClick={() => handleStatusChange('paused')}>
                  <FaIcon icon="circle" size="xs" className="current-status-icon paused" />
                  {' '}
                  Paused
                </DropdownItem>
                {showArchived && (
                  <DropdownItem className="status-archive" onClick={() => handleStatusChange('archived')}>
                    <FaIcon icon="circle" size="xs" className="current-status-icon archived" />
                    {' '}
                    Archived
                  </DropdownItem>
                )}

              </DropdownMenu>

            </UncontrolledDropdown>
          ) : (
            <Spinner size="sm" className="ms-3" color="primary" />
          )}
        </>
      ) : (
        <>
          <FaIcon icon="circle" size="xs" className={classnames(['current-status-icon', status, 'me-2'])} />
          <span>{titleCase(displayStatus)}</span>
        </>
      )}

    </div>

  );
};


export default StatusToggler;
