import React from 'react';
import {
  Modal as BootstrapModal, ModalBody, ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Auction from './index';
import {CopyButton} from "@thedmsgroup/mastodon-ui-components";

/*
 * Modal for auction detail view, wraps Auction Detail
 */
const Modal = ({ auctionId, isOpen, cancel, timezone='UTC', defaultTab }) => {

  const permalink = `${location.protocol}//${location.host}/#/reports/auction/${auctionId}`;
  return (
    <BootstrapModal isOpen={isOpen} toggle={cancel} size="xlg" className="auction-detail" unmountOnClose={true}>
      <ModalHeader toggle={cancel}>
        Auction Detail: {auctionId}
      </ModalHeader>


      <div className="ms-3">
        <Link target="_blank" to={`/reports/auction/${auctionId}`} className="link auction-detail-link p-0">
          <FaIcon icon="external-link-alt" size="sm" />
          {' '}
          Permalink
        </Link>

        <CopyButton
          id="copy-permalink"
          textToCopy={permalink}
          buttonSize="sm"
        >
          <FaIcon icon="copy" size="sm" />
        </CopyButton>
      </div>

      <ModalBody>

        <Auction auction_id={auctionId} timezone={timezone} defaultTab={defaultTab} />

      </ModalBody>
    </BootstrapModal>
  );
};

Modal.propTypes = {
  auctionId: PropTypes.string,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  cancel: PropTypes.func,
  timezone: PropTypes.string,
  defaultTab: PropTypes.string,
};

Modal.defaultProps = {

};

export default Modal;
