import {getProperty} from 'dot-prop';

export const AggIntervalToUnit = {
  '1m':'minute',
  '1h':'hour',
  '1d':'day',
  '1w':'week',
  '1M':'month',
  '1q':'month',
  '1y':'year',
}

// Enforce a maximum number of buckets in a dataset
// (Can keep bar charts from getting too crazy)
export const normalizeBuckets = (bucketsArr, MAX_BUCKETS) => {
  const bucketLength = bucketsArr?.length;
  if (bucketLength > MAX_BUCKETS) {
    const scaleFactor = Math.ceil(bucketLength / MAX_BUCKETS);
    const reduceArr = [];
    for (let i = 0; i < bucketLength; i += scaleFactor) {
      reduceArr.push(bucketsArr[i]);
    }
    return {buckets:reduceArr, scaleFactor};
  }
  return {buckets:bucketsArr, scaleFactor: 0}
};

/*
 * Refactor a time-based aggregation that has sub-aggregation per time slot
 * into labels and multiple datasets expected by ChartJS.
 */
export const aggregateToMultiDataset = (
  timeBuckets,
  timeField='key_as_string',
  dataKeyField = 'key',
  dataCountField = 'doc_count',
  dataBucketPath = 'data',
  dataLabelFormatter
) => {
  /*
  Example: each time slot has an aggregation of integrations and their timeout counts.
  delivery_errors_over_time.buckets[]
    {
      key: long int
      key_as_string:  "2024-01-25T18:12:00.000Z"
      doc_count: 26
      timeouts: {
        doc_count: int
        integrations.buckets[]
          {key: 'iwebquotes_health_pingpost', doc_count: 12}
      }
    }

    Transform into datasets by integration name:
    {
      labels: [ "2024-01-25T18:12:00.000Z", ...all other time slot keys... ],
      datasets: [  ... dataset per integration.... ]
    }
    where each dataset is
    {
      label: integration name
      data: [ array of counts per time period ]
    }
   */

  // Labels are the time axis (timeField value)
  const labels = [];

  const datasets = {} ;

  timeBuckets.forEach((timeBucket, timeIndex) => {
    // console.log('index.tsx: iterating on a date bucket', bkt);
    const time = timeBucket[timeField];
    const data = getProperty(timeBucket, dataBucketPath);
    if (time && data  ) {
      labels.push(time);
      //console.log('chart.js. time', time);
      data.forEach((dataBucket) => {
        const dataKey = getProperty(dataBucket, dataKeyField)
        const dataCount = getProperty(dataBucket, dataCountField);
        //console.log('chart.js: data ', `key:${dataKey}: ${dataCount}`);
        if (dataKey && dataCount !== undefined) {
          // With dataKey, create an entry in datasets with the key as the label,
          // and counts as an array of zero counts equal to the number of time values
          if (typeof datasets[dataKey] === 'undefined') {
            datasets[dataKey] = {
              label: typeof dataLabelFormatter === 'function' ? dataLabelFormatter(dataKey) : dataKey,
              data: Array(timeBuckets.length).fill(0)
            }
          }

          //Fill in the count at the current time position
          datasets[dataKey].data[timeIndex] = dataCount;
        }


      })
    } else {
      throw new Error('aggregateToMultiDataset: unable to resolve data')
    }

  })

  return {labels, datasets: Object.values(datasets)}
}
