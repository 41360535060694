// There are unlikely to change any time soon, so hard-coding
// for crafting example JSONs are fine.
const piiFields = [
  'first_name',
  'last_name',
  'address',
  'address2',
  'phone',
  'email',
];

const stripPii = (schema) => {
  let newSchema = {};
  if (!schema) return {}
  Object.keys(schema).map(key => {
    if (!piiFields.includes(key)) {
      newSchema[key] = schema[key]
    }
  })

  return newSchema;
}

const Ping = (token, schema) => {
  return {
    source_token: token,
    data: stripPii(schema),
  }
}

const PingResp = {
  "auction_id": "string",
  "visitor_id": "string",
  "bids":
    [
      {
        "bid_id": 1,
        "buyer_name": "Acme",
        "exclusive": true,
        "vendor_attributes": {},
        "bid_amount": "string",
        "transfer_number": "string"
      }
    ],
  "expires_at": "string",
  "payout": null,
  "sold": true,
  "matched": true,
  "bid_amount": null,
  "default_accepted_bid_ids": [],
  "time_ms": 0
};

const PingPost = (schema) => {
  let j = {
    "auction_id": "string",
    "data": {},
    "accepted_bid_ids": [
      0,
    ]
  }

  if (!schema) return j;

  Object.keys(schema).map(key => {
    if (piiFields.includes(key)) {
      j.data[key] = schema[key]
    }
  })

  return j
};

const PostResp = {
  "auction_id": "string",
  "bids": [
    {
      "bid_id": 0,
      "bid_amount": "string",
      "buyer_name": "string"
    }
  ],
  "expires_at": "string",
  "payout": "string",
  "sold": true,
  "matched": true,
  "bid_amount": "string",
  "default_accepted_bid_ids": [
    0
  ],
  "time_ms": 0,
  "data_issues": []
}

const PostRespNoSale =
  {

    "auction_id": null,
    "bids": [],
    "expires_at": "string",
    "payout": "string",
    "sold": true,
    "matched": true,
    "bid_amount": "string",
    "default_accepted_bid_ids":

      [
        0
      ],
    "time_ms": 0,
    "data_issues": []

  };

export {Ping, PingResp, PingPost, PostResp, PostRespNoSale};
