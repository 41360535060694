import React, {useContext, useState, useMemo} from "react";
import {Badge, Button} from "reactstrap";
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import BidDisplay from './BidDisplay';
import {titleCase} from "@thedmsgroup/mastodon-ui-components/lib/utils/string";
import { TargetGroupsDescription } from './TargetDescription';
import {Stack, RowDivider, WeekSummarySVG} from "@thedmsgroup/mastodon-ui-components";
import { CapTimeframeChoices, DateFormatter, MoneyFormatter, PercentFormatter } from '../Table/Table';
import {BiddingScheduleConfig} from "../../App/AdvertiserManager/RulesManager/const";
import StandardAlert from "../../Layout/StandardAlert";
import usePermission from "../../Hooks/usePermission";
import RulesContext from "../../Providers/RulesContext";
import AdDisplay from "../../App/AdManager/AdDisplay";

const numberFormat = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

/* Display a nice label for a given integration alias (indicate non-active status) */
const IntegrationDisplay = ({alias, type}) => {
  const rulesContext = useContext(RulesContext);

  const integration = useMemo(()=>{
    const definitions = rulesContext.api.getIntegrationDefinitionsByType(type);
    const intConf = definitions.find((conf) => conf.name === alias);
    return intConf ?? {label:alias, status: ""}
  }, [alias, type]);

  return (<span>{integration.label}
    {integration.status && integration.status !== 'active' && <Badge color="warning" className="ms-1">{titleCase(integration.status)}</Badge>}
  </span>)
}

export const ActivityScheduleSummary = React.memo(({ schedule, timezone, gotoTab }) => {
  if (!schedule || schedule.length === 0) return (null);

  return (
    <SummaryItemWrapper label="On/Off Schedule" tabId="activity" icon="calendar-times" gotoTab={gotoTab} className="activity-schedule-summary">
      <span>Paused ranges:</span>
      <ul>
        {schedule.map((range, i) => (

          <li key={i}>
            <b><DateFormatter value={range.from} timezone={timezone} format="M/d/y h:mm a" /></b>
            <span className="ms-2 me-2">to</span>
            <b><DateFormatter value={range.to} timezone={timezone} format="M/d/y h:mm a" /></b>
          </li>

        ))}
      </ul>
    </SummaryItemWrapper>
  );
});

/* Caps for Publisher Routing - sale caps, match caps */
export const RoutingCapsSummary = ({ caps, title, gotoTab }) => {
  if (!caps || !Object.entries(caps).length) return null;

  return (
    <SummaryItemWrapper className="routing-caps-summary" label={title} icon="hand-paper" tabId="caps" gotoTab={gotoTab}>
      <Stack gap="0.5em" divider={<RowDivider />} >
      {Object.entries(caps).map(([timeframe, cap], i) => (
        <div key={i} className="d-flex caps-row">
          <div className="ms-2 w-25">{CapTimeframeChoices[timeframe]}</div>
          <div className="cap-value flex-fill"><NumberFormat value={cap} displayType="text" thousandSeparator decimalScale={0} fixedDecimalScale /></div>
        </div>
      ))}
      </Stack>
    </SummaryItemWrapper>
  );
};

/* Ping Dedupe for Publisher Routing  */
export const PingDedupeCapsSummary = ({ caps, title, gotoTab }) => {
  if (!caps || !caps.length) return null;

  return (
    <SummaryItemWrapper className="routing-caps-summary" label={title} icon="copy" tabId="caps" gotoTab={gotoTab}>
      <Stack gap="0.5em" divider={<RowDivider />} >
      {caps.map((cap, i) => (
        <div key={i} className="d-flex">
          <div className=" ms-2 w-25">{cap.key_expression ?? ''}</div>
          {cap.duration >= 0 && <div className="me-3">
            <NumberFormat
            value={cap.duration}
            displayType="text"
            thousandSeparator
            decimalScale={0}
            /> seconds
          </div>}
          {cap.cache_response && <div><i> (response cached)</i></div>}
        </div>
      ))}
      </Stack>
    </SummaryItemWrapper>
  );
};


export const BiddingSummary = ({
  ruleType,
  label = 'Bidding',
  type, absolute_bid,
  bid_modifier,
  floor, // routing rules
  ceiling,
  showBidCeiling = false,
  proxyBidding,
  gotoTab,
}) => (
  <SummaryItemWrapper className="bid-summary" label={label} tabId="bidding" icon="comment-dollar" gotoTab={gotoTab}>
    <BidDisplay
      ruleType={ruleType}
      type={type}
      absolute_bid={absolute_bid}
      bid_modifier={bid_modifier}
      blockedText="Blocked"
      passText="Pass"
    />
    {ceiling && showBidCeiling && (
      <div className="mt-2">
        Bid Ceiling: <b><MoneyFormatter value={ceiling} /></b>
      </div>
    )}

    {floor > 0 && (
      <div className="mt-2">
        Bid Floor:
        {' '}
        <b><MoneyFormatter value={floor} /></b>
      </div>
    )}

    {proxyBidding?.integration && (
      <>
        <div className="mt-2 data-row">
          <label>Proxy:</label>
          <div><IntegrationDisplay type="proxy_bidding" alias={proxyBidding.integration.name} /></div>
        </div>
          <div className="data-row">
            <label>Direct transfer:</label>
            <div>{proxyBidding.direct_transfer ? 'yes' : 'no'}</div>
          </div>

            {proxyBidding.bid_floor && (
              <div className="data-row">
                <label>Bid Floor:</label>
                <div><MoneyFormatter value={proxyBidding.bid_floor} /></div>
              </div>
            )}

            {proxyBidding.bid_modifier && (
              <div className="data-row">
                <label>Bid Modifier:</label>
                <div><PercentFormatter value={proxyBidding.bid_modifier} /></div>
              </div>
            )}
      </>
    )}
  </SummaryItemWrapper>
);

export const BidScheduleSummary = ({ schedule, gotoTab }) => {
  if (Object.keys(schedule).length === 0) return (null);

  return (
    <SummaryItemWrapper className="bid-schedule-summary" label="Bid Schedule" tabId="bidding" icon="clock" gotoTab={gotoTab}>

      <WeekSummarySVG
        schedule={schedule}
        config={BiddingScheduleConfig}
        labelSize="sm"
        onChangeSelectedDay={()=>{}}
        selectedDay={"monday"}
      />
    </SummaryItemWrapper>
  );
};

export const TagSummary = ({ tags = [], gotoTab }) => {
  if (tags.length === 0) return (null);
  return (<SummaryItemWrapper className="tag-summary" label="Tags" tabId="tags" icon="tags" gotoTab={gotoTab}>{tags.join(', ')}</SummaryItemWrapper>);
};

/* Routing flow control  */
export const FlowControlSummary = ({ flowControl, gotoTab }) => {
  if (!flowControl) return (null);
  return (
    <SummaryItemWrapper className="flow-control-summary" label="Flow Control" tabId="bidding" icon="filter" gotoTab={gotoTab}>
      <div className="d-flex align-items-baseline mt-2">
        <div className="flow-setting">{titleCase(flowControl)}</div>
        <div className="ms-2">
          {flowControl === 'include' ? (
            <span>only leads matching target conditions</span>
          ) : <span>all leads matching target conditions</span>}
        </div>
      </div>
    </SummaryItemWrapper>
  );
};

/* Routing flow control */
export const BidFalloffSummary = ({ bidFalloff, gotoTab }) => {
  if (!bidFalloff) return (null);
  return (
    <SummaryItemWrapper className="bid-falloff-summary" label="Bid Falloff" tabId="bidding" icon="filter" gotoTab={gotoTab}>
      <p className="d-flex align-items-baseline mt-2" style={{fontSize:'1.6rem'}}>
        {bidFalloff}%
      </p>
    </SummaryItemWrapper>
  );
};

/* Routing sell strategy  */
export const SellStrategySummary = ({ sellStrategy, gotoTab }) => {
  if (!sellStrategy) return (null);
  return (
    <SummaryItemWrapper className="sell-strategy-summary" label="Selling Strategy" tabId="bidding" icon="balance-scale" gotoTab={gotoTab}>
      <p className="d-flex align-items-baseline mt-2">
        <strong className="me-2">{titleCase(sellStrategy)}:</strong>
        {' '}
        {sellStrategy === 'waterfall' ? (
          <span>In order from highest to lowest bid, sell to the first bidder that accepts.</span>
        ) : (
          <span>Sell to the highest bidder. Do not attempt to sell to other bids.</span>
        )}
      </p>
    </SummaryItemWrapper>
  );
};


export const BudgetSummary = React.memo(({
  budgets, caps, schedule, gotoTab,
}) => {
  if (!(budgets && Object.keys(budgets).length) && !(caps && Object.keys(caps).length)) return (null);

  return (
    <SummaryItemWrapper className="budget-summary" label="Budget and Caps" tabId="budget" icon="dollar-sign" gotoTab={gotoTab}>
      <Stack gap="0.5em" divider={<RowDivider />} className="caps-table">
        <div className="d-flex caps-row title">
          <div>&nbsp;</div>
          <div className="cap">Caps</div>
          <div className="budget">Budget</div>
        </div>
        {['total','monthly','weekly','daily'].map((interval, i) => (
          <div key={`sum-cap${i}`} className="d-flex caps-row">
            <div>{titleCase(interval)}</div>
            <div className="cap">{caps[interval] ?? <i>open</i>} </div>
            <div className="budget">{budgets[interval] ?
              <NumberFormat value={budgets[interval]} displayType="text" prefix="$" thousandSeparator decimalScale={0}  />
              : <i>open</i>}
            </div>
          </div>
        ))}
        {/* TBD: hourly feature is not live yet
        <div className="d-flex caps-row">
          <div>Hourly</div>
          <div className="cap"><i>{schedule && Object.keys(schedule).length > 0 ? 'scheduled' : 'open'}</i></div>
          <div className="budget">N/A</div>
        </div>*/}
      </Stack>
    </SummaryItemWrapper>
  );
});

// const BudgetSummaryItem = React.memo(({
//   name, budget = '', product, cap = '',
// }) => (
//   <li>
//     <strong>{name}</strong>
// &nbsp;&nbsp;
//     <span>
//       Spend:&nbsp;
//       {budget ? `$${numberFormat.format(budget)}` : <i>open</i>}
//     </span>
//     {' '}
// &nbsp;/&nbsp;
//     <span>
//       {' '}
//       {titleCase(product)}
//       :&nbsp;
//       {cap ? numberFormat.format(cap) : <i>open</i>}
//     </span>
//
//   </li>
// ));

export const TargetingSummary = React.memo(({
  attributes, targets, expression, matchIsRequired, isStandalone, gotoTab,
}) => {
  const [expressionMode, setExpressionMode] = useState(false);
  const hasTargeting = targets.length > 0 && targets[0].length > 0;

  // TODO: trim groups? might not be likely, but a lot of groups would blow up the summary
  // <Input type="textarea" rows={3} defaultValue={expression}/>
  return (
    <SummaryItemWrapper className="target-summary" label="Targeting" icon="bullseye" tabId="targeting" gotoTab={gotoTab}>
      {matchIsRequired &&
        <StandardAlert color="danger" icon="alert" className="validation mb-1">
          Match is required for bid
        </StandardAlert>
      }

      {isStandalone &&
        <StandardAlert color="warning" icon="alert" className="validation mb-1">
          Standalone Rule (does not affect sibling rules)
        </StandardAlert>
      }

      {hasTargeting === true ? (
        <>
          { expressionMode === false ? (
            <TargetGroupsDescription groups={targets} attributes={attributes} />
          ) : (<div className="dont-break-out">{expression}</div>)}

          { expression && (
            <div id="expression-toggler">
              <Button color="link" className="inline" size="xs" onClick={() => setExpressionMode(!expressionMode)}>
                [
                {expressionMode === true ? 'Description' : 'Expression'}
                ]
              </Button>
            </div>
          )}
        </>
      ) : (
        <span><i>No targets specified</i></span>
      )}

    </SummaryItemWrapper>
  );
});

// iterate over match conditions and write a description for each
// const TargetList = ({targets, attributes}) =>{
//   if(!Array.isArray(targets)) return (null);
//   return (<React.Fragment>
//   { targets.map( (group, i) => {
//     if(Array.isArray(group)){
//       return (
//         <div key={'group-'+ i}>
//           {i > 0 && <div className="target-summary-group-or">OR</div>}
//           <ul>
//             {group.map( (target, i)=>{
//               return(
//                 <li key={i}><TargetDescription attribute={attributes[target.attribute]} target={target}/></li>
//               )
//             })}
//           </ul>
//         </div>
//
//       );
//     } else return (null);
//   })
//   }
// </React.Fragment>)};

export const CallConfigSummary = React.memo(({ concurrency, billableDuration, gotoTab }) => {
  return (
    <SummaryItemWrapper className="call-summary flex-grow-1" label="Call Settings" icon="phone" tabId="calls" gotoTab={gotoTab}>
      <div className="data-row mt-2">
        <label>Billable Duration:</label>
        <div>{billableDuration ?  `${billableDuration} seconds` : <i>None configured (max 5 min.)</i>}</div>
      </div>
      <div className="data-row ">
        <label>Concurrency:</label>
        <div>{concurrency || <i>None configured (default 1)</i>}</div>
      </div>
    </SummaryItemWrapper>
  );
});


//Note: advertiser rules has enrichments on its own tab; routing rules mixes integrations on an 'integrations' tab
export const EnrichmentSummary = React.memo(({
                                                enrichments = [],
                                               attributes = [],
                                               tabName="enrichments",
                                               gotoTab,
}) => {
  if (enrichments.length === 0) return (null);


  return (
    <SummaryItemWrapper className="enrichment-summary flex-grow-1" label="Enrichments" icon="filter" tabId={tabName} gotoTab={gotoTab}>

      { enrichments.map((enrichment, i) => (
        <div key={i} className="mt-1">
          <h5>
            <IntegrationDisplay type="enrichment" alias={enrichment.integration.name} />
            {' '}
            <small>
              ( {titleCase(enrichment.timing)})
            </small>
          </h5>
          <div className="ms-3">
            <TargetGroupsDescription groups={enrichment.suppression_conditions} attributes={attributes} />
          </div>

        </div>
      ))}

    </SummaryItemWrapper>
  );
});

export const PrefillSummary = React.memo(({ prefill = {} = [], gotoTab }) => {
  if (!prefill.integration) return (null);

  return (
    <SummaryItemWrapper className="prefill-summary flex-grow-1" label="Prefill" icon="fill" tabId="destinations" gotoTab={gotoTab}>
      <div className="mt-1">
        <IntegrationDisplay type="prefill" alias={prefill.integration.name} />
      </div>
    </SummaryItemWrapper>
  );
});

//Note: advertiser rules has destinations on its own tab; routing rules mixes integrations on an 'integrations' tab
export const DestinationSummary = React.memo(({
  product,
  isRouting,
  destinationNumber,
  destinationSip,
  redirectUrl,
  prefill,
  postSale,
  callStarted,
  callEnded,
  tabName="destinations",
  gotoTab
}) => {

  const hasIntegration = (val) =>  Array.isArray(val) && val.length || val && typeof val === 'object' && Object.keys(val).length
  return (
    <SummaryItemWrapper className="destination-summary flex-grow-1" label="Destinations" icon="envelope" tabId={tabName} gotoTab={gotoTab}>
      {product === 'calls' && (
        <>
          <div className="data-row mt-2">
            <label>Destination Number:</label>
            <div>{destinationNumber || <i>none</i>}</div>
          </div>
          <div className="data-row ">
            <label>Destination SIP:</label>
            <div>{!Array.isArray(destinationSip) && destinationSip?.settings?.uri ? destinationSip.settings.uri : <i>none</i>}</div>
          </div>
        </>
      )}
      <div className="mt-1">

        {redirectUrl && (
          <div className="data-row">
            <label>Redirect URL</label>
            <div>{redirectUrl}</div>
          </div>
        )}

        {!isRouting && (
          <div className="data-row">
            <label>Prefill</label>
            <div>
              {hasIntegration(prefill) ? <IntegrationDisplay type="prefill" alias={prefill.integration.name} /> : <i>none</i>}
            </div>
          </div>
        )}


        {product === 'calls' && (
          <>
            <div className="data-row">
              <label>Call Started</label>
              <div>
                {hasIntegration(callStarted) ? (
                  <Stack gap="0.5em">
                    {callStarted.map((intg, i) => <IntegrationDisplay type="call_started" alias={intg.integration.name} key={i}/>)}
                  </Stack>
                ) : (
                  <i>none</i>
                )}
              </div>
            </div>

            <div className="data-row">
              <label>Call Ended</label>
              <div>
                {hasIntegration(callEnded) ? (
                  <Stack gap="0.5em" >
                    {callEnded.map((intg, i) => <IntegrationDisplay type="call_ended" alias={intg.integration.name} key={i} />)}
                  </Stack>
                ) : (
                  <i>none</i>
                )}
              </div>
            </div>
          </>
        )}

        <div className="data-row">
          <label>Post Sale</label>
          <div>
            {hasIntegration(postSale) ? (
              <Stack gap="0.5em" >
                {postSale.map((intg, i) => <IntegrationDisplay type="delivery" key={i} alias={intg.integration.name} />)}
              </Stack>
            ) : (
              <i>none</i>
            )}
          </div>
        </div>


      </div>
    </SummaryItemWrapper>
  );
});

export const SaleConfirmationSummary = React.memo(({ confirmation = {} = [], gotoTab }) => {
  if (!confirmation.integration) return (null);

  return (
    <SummaryItemWrapper className="sale-confirmation-summary flex-grow-1" label="Sale Confirmation" icon="fill" tabId="sale_confirmation" gotoTab={gotoTab}>
      <div className="mt-1">
        <IntegrationDisplay type="sale_confirmation" name={confirmation.integration.name} />
      </div>
    </SummaryItemWrapper>
  );
});

export const AdsSummary = React.memo(({ ads, accountAds, override, gotoTab }) => {
  if (ads.length === 0 ) return (null);

  const ruleAds = accountAds.filter((ad) => ads.includes(ad.id));

  return (
    <SummaryItemWrapper label="Ads" className="ads-summary" tabId="ads" icon="ad" gotoTab={gotoTab}>

      {ruleAds.length > 0 && override === true && (
        <div>
          <FaIcon icon="check" className="me-1" />
          <small>Override parent rule ads. Use only these ads for this rule.</small>
        </div>
      )}

      {ruleAds.map((ad,i) => <AdDisplay {...ad} key={i} />)}
    </SummaryItemWrapper>
  );
});

export const ChannelSummary = React.memo(({ modifiers, channels, gotoTab }) => {
  // if(modifiers.length === 0) return (null)



  return (
    <SummaryItemWrapper label="Channel Modifiers" className="channel-mod-summary mt-1" icon="sliders-h" tabId="channels" gotoTab={gotoTab}>
      {modifiers.length > 0 ? (
        <div className="scroll-list thin-scrollbar">
          {modifiers.map((mod, i) => (
            <div key={`sum-mod${i}`} className="data-row">
              <label>{getChannelName(channels, mod.channel_id)}</label>
              <div>
                <b>
                  {mod.bid_modifier}
                  %
                </b>
              </div>
            </div>
          ))}

        </div>
      ) : (
        <i>No channel modifiers</i>
      )}

    </SummaryItemWrapper>
  );
});

const getChannelName = (channels, channelId) => {
  let name = 'Channel';
  if (channels) {
    const matching = channels.find((ch) => ch.id === channelId);
    if (matching) name = matching.name;
  }
  return name;
};

/* For routing rules */
// export const ChannelRouteSummary = memo(({channelRoutes, channels, gotoTab})=>{
//
//   if(channelRoutes.length === 0) return (null)
//
//   let excess = 0;
//   if(channelRoutes.length > 6){
//     excess = channelRoutes.length - 6;
//     channelRoutes = channelRoutes.slice(0, 6);
//   }
//
//   return(
//     <SummaryItemWrapper label="Channels" tabId="channels" gotoTab={gotoTab}>
//       <ul>
//         {channelRoutes.map( (mod, i)=> <li key={'sum-mod'+i}> {getChannelName(channels, mod.channel_id)}: <b>{mod.distribution}%</b></li>)}
//         {excess > 0 &&  <li><i>...and <strong>{excess} others</strong></i></li>}
//       </ul>
//     </SummaryItemWrapper>
//   );
// });

export const SummaryItemWrapper = ({
  label, className = '', tabId, gotoTab, icon, children,
}) => {
  const allowEditOrder = usePermission('orders.edit');
  return (
    <div className={`form-section summary-item ${className}`}>
      <div className="summary-item-label d-flex align-items-center justify-content-between">
        <div>
          <FaIcon icon={icon} size="lg" className="me-2"/>
          <label>{label}</label>
        </div>
        {gotoTab && allowEditOrder && <Button color="link" className="inline" onClick={() => gotoTab(tabId)}>edit</Button>}
      </div>
      <div className="summary-item-data">{children}</div>
    </div>
  )
};
