import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Lazify from '../utils/Lazify';
import AdvertiserManager from '../App/AdvertiserManager';
import PublisherManager from '../App/PublisherManager';
import LoadingBar from './LoadingBar';
import ReportsLanding from '../App/Reports/Landing';
import Tools from '../App/Tools';
import './main_content.scss';
import ProtectedRoute from '../Components/ProtectedRoute';


const LazyDashboard = Lazify(() => import(/* webpackChunkName: "Dashboard" */ '../App/Dashboard'));
const LazyAdManager = Lazify(() => import(/* webpackChunkName: "AdManager" */ '../App/AdManager'));
const LazyUserManager = Lazify(() => import(/* webpackChunkName: "UserManager" */ '../App/UserManager/Users'));
const LazyMyProfileModal = Lazify(() => import(/* webpackChunkName: "UserModals" */ '../App/UserManager/User/MyProfileModal'));
const LazyMyPasswordModal = Lazify(() => import(/* webpackChunkName: "UserModals" */ '../App/UserManager/User/MyPasswordModal'));
const LazyChangeLog = Lazify(() => import(/* webpackChunkName: "ChangeLog" */ '../App/ChangeLog'));
const LazyAuctions = Lazify(() => import(/* webpackChunkName: "Auctions" */ '../App/Auctions'));
const LazyDeliveries = Lazify(() => import(/* webpackChunkName: "Deliveries" */ '../App/Deliveries'));
const LazyRouting = Lazify(() => import(/* webpackChunkName: "Routing" */ '../App/Routing'));
const LazyVerticals = Lazify(() => import(/* webpackChunkName: "Routing" */ '../App/Verticals'));

/* Component for main content area */
const MainContent = () => {

  return (

    <main id="main-content">

        <LoadingBar name="main" scrollToTop />

        <Routes>
          <Route index element={<Navigate to="/accounts" />} />

          <Route path="/accounts/*" element={<AdvertiserManager />}  />

          <Route path="/accounts/:accountId/ads/*" element={ <LazyAdManager/>} />

          <Route path="/accounts/:accountId/users" element={<ProtectedRoute permission="users.view" /> } >
            <Route index element={<LazyUserManager />} />
          </Route>

          <Route path="/publishing/*" element={<PublisherManager/>} />

          <Route path="/reports/*"  element={<ReportsLanding />} />

          <Route path="/routing/*" element={<LazyRouting />} />

          <Route path="/tools/*" element={<Tools />} />

          <Route path="/dashboard/*" element={<LazyDashboard />} />

          <Route path="/deliveries/*" element={<LazyDeliveries />} />

          <Route path="/changes" element={<ProtectedRoute permission="changes.view" /> } >
            <Route index element={<LazyChangeLog />} />
          </Route>

          <Route path="/auctions" element={<ProtectedRoute permission="auctions.view" /> } >
            <Route index element={<LazyAuctions />} />
          </Route>

          <Route path="/verticals" element={<ProtectedRoute permission="verticals.edit" /> } >
            <Route index element={<LazyVerticals />} />
          </Route>


          <Route element={() => <Navigate to="/accounts" />} />

        </Routes>

        <LazyMyProfileModal />
        <LazyMyPasswordModal />


      <div className="push-footer" />
    </main>

  );
};

export default MainContent;
