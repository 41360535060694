import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CopyWidget from '../../../Components/Rules/CopyWidget';
import MoveWidget from '../../../Components/Rules/MoveWidget';
import lazify from '../../../utils/Lazify';
import RulesContext from '../../../Providers/RulesContext';
import StandardAlert from '../../../Layout/StandardAlert';

const LazyBatchRuleModal = lazify(() => import(/* webpackChunkName: "RuleModals" */ '../../../Components/Rules/BatchRuleModal'));
// const LazySettingCopyModal = lazy( () => import(/* webpackChunkName: "RuleModals" */ "../../../Components/Rules/SettingCopyModal"));
// const LazySettingPasteModal = lazy( () => import(/* webpackChunkName: "RuleModals" */ "../../../Components/Rules/SettingPasteModal"));

const ActionHeader = ({ label, setActionMode }) => (
  <div className="header d-flex align-items-baseline">
    <Button onClick={() => setActionMode(null)} color="transparent"><FaIcon icon="arrow-left" size="1x" className="me-2" /></Button>
    <h5 className="flex-fill">{label}</h5>
  </div>
);

/*
 * ACTIONS PANEL
 * Slide-out panel for Rule Manager containing rule actions like copy, add, bulk update, etc
 * Some actions are done within the menu, and others will open a modal or do some other external action
 */
const ActionsPanel = (props) => {
  const rulesContext = useContext(RulesContext);
  const [actionMode, setActionMode] = useState(null);
  const [batchRuleModal, setBatchRuleModal] = useState(false);
  // const[settingCopyModal, setSettingCopyModal] = useState(false);
  // const[settingPasteModal, setSettingPasteModal] = useState(false);
  // const allowTransferSettings = usePermission('orders.create');

  const handleClose = () => {
    setActionMode(null);
    rulesContext.api.toggleActionsPane();
  };

  const handleDeleteRule = () => {
    // props.onDeleteRule();
    rulesContext.api.deleteRule(props.rule);
    handleClose();
  };

  const handleAddRule = (placement) => {
    rulesContext.api.addRule(props.rule, placement);
    handleClose();
  };

  const toggleDisabled = () => {
    if (props.rule.disabled) {
      rulesContext.api.disableRule(props.rule, false);
      handleClose();
    } else {
      // confirm
      setActionMode('disable');
    }
  };

  const handleDisable = () => {
    rulesContext.api.disableRule(props.rule, true);
    handleClose();
  };
  const handleSave = () => {
    props.onSaveRule();
    handleClose();
  };

  // close when modal opens
  useEffect(() => {
    if (batchRuleModal) {
      handleClose();
    }
  }, [batchRuleModal]);

  if (!props.rule) return (null);

  return (
    <>
      {/* Hide RuleActions header when in action mode? */}
      <div className={classnames(['actions-panel', { open: props.isOpen }])}>

        {/* Show menu if NOT in action mode */}
        {!actionMode && (
        <>
          <div className="header mb-3">
            <div className="d-flex justify-content-between align-items-baseline mb-1">
              <h5 className="m-0">Rule Actions</h5>
              <Button onClick={rulesContext.api.toggleActionsPane} color="transparent"><FaIcon icon="times" size="1x" /></Button>
            </div>
            <div>{props.rule.label} {props.isRootRule && <span>(root rule)</span>}</div>
          </div>

          <div className="rule-action-menu d-flex flex-column mt-1">

            <div className="rule-action-item">
              <UncontrolledDropdown size="sm">
                <DropdownToggle color="link" disabled={props.rule.isNew || !rulesContext.allowCreate} caret>
                  <FaIcon icon="plus" />
                  {' '}
                  Add
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem onClick={() => handleAddRule('below')} disabled={props.isRootRule}>
                    <FaIcon icon="bars" />
                    {' '}
                    One rule, below
                  </DropdownItem>
                  <DropdownItem onClick={() => handleAddRule('child')}>
                    <FaIcon icon="indent" />
                    {' '}
                    One rule, as child
                  </DropdownItem>
                  <DropdownItem onClick={() => setBatchRuleModal(true)}>
                    <FaIcon icon="indent" />
                    {' '}
                    Multiple rules, as children ...
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <div className="rule-action-item">
              <Button
                className=""
                color="link"
                onClick={handleSave}
                disabled={props.rule.isNew}
                size="sm"
              >
                <FaIcon icon="pencil-alt" />
                Save
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                className=""
                color="link"
                onClick={() => setActionMode('copy')}
                disabled={props.isRootRule || props.rule.isNew}
                size="sm"
              >
                <FaIcon icon="copy" />
                Copy
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                className=""
                color="link"
                onClick={() => setActionMode('move')}
                disabled={props.isRootRule || props.rule.isNew}
                size="sm"
              >
                <FaIcon icon="arrows-alt" />
                Move
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                className=""
                color="link"
                onClick={toggleDisabled}
                disabled={props.isRootRule || props.rule.isNew}
                size="sm"
              >

                {props.rule.disabled ? (
                  <>
                    <FaIcon icon="plug" />
                    Enable
                  </>
                ) : (
                  <>
                    <FaIcon icon="ban" />
                    Disable
                      </>
                ) }
              </Button>
            </div>

            <div className="rule-action-item">
              <Button
                className=""
                color="link"
                onClick={() => setActionMode('delete')}
                disabled={props.isRootRule || props.rule.isNew || !rulesContext.allowCreate}
                size="sm"
              >
                <FaIcon icon="trash" />
                Delete
              </Button>
            </div>

          </div>
        </>
        )}

        {/*
            * Action view (menu goes away, show action form
           */}

        {actionMode === 'copy' && (
        <div className="rule-action">
          <ActionHeader label="Copy Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <CopyWidget rule={props.rule} close={handleClose} cancel={() => setActionMode()} />
          </div>
        </div>
        )}

        {actionMode === 'move' && (
        <div className="rule-action">
          <ActionHeader label="Move Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <MoveWidget rule={props.rule} close={handleClose} />
          </div>
        </div>
        )}

        {actionMode === 'delete' && (
        <div className="rule-action">
          <ActionHeader label="Delete Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <StandardAlert color="light" className="p-0">Are you sure? Any rules that descend from this rule will also be deleted.</StandardAlert>
            <div className="d-flex mt-2 justify-content-end">
              <Button size="sm" color="link" onClick={() => setActionMode(null)} >Cancel</Button>
              <Button size="sm" className="ms-1" color="primary" onClick={handleDeleteRule}>Delete</Button>
            </div>
          </div>
        </div>
        )}

        {actionMode === 'disable' && (
        <div className="rule-action">
          <ActionHeader label="Disable Rule" setActionMode={setActionMode} />

          <div className="mt-3">
            <StandardAlert color="light" className="p-0">
              Disabling will block the application of this rule and all descendant rules.
              The rules can still be edited.
            </StandardAlert>
            <div className="d-flex mt-2 justify-content-end">
              <Button color="link" size="sm" onClick={() => setActionMode(null)} >Cancel</Button>
              <Button size="sm" className="ms-1" color="primary" onClick={handleDisable}>Disable</Button>

            </div>
          </div>
        </div>
        )}

      </div>

      <div
        className={classnames(['actions-overlay', { open: props.isOpen }])}
        onClick={handleClose}
      />

      <LazyBatchRuleModal
        isOpen={batchRuleModal}
        close={() => setBatchRuleModal(false)}
        rule={props.rule}
        attributes={rulesContext.attributes}
        attributeGroups={rulesContext.attributeGroups}
        refreshRules={rulesContext.api.refreshRules}
        ruleType="routing"
      />

    </>
  );
};

ActionsPanel.propTypes = {
  isOpen: PropTypes.bool,
  rule: PropTypes.object,
  isRootRule: PropTypes.bool,
  isDirty: PropTypes.bool,
  onChange: PropTypes.func,
  onPasteRule: PropTypes.func,
  onSaveRule: PropTypes.func,
  // onDeleteRule: PropTypes.func,
};

ActionsPanel.defaultProps = {
  isOpen: false,
};

export default ActionsPanel;
