import React, {useState, useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Input, Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import DocTitle from '../../../Layout/DocTitle';
import PageSection from '../../../Layout/PageSection';
import Auction from './index';
import './styles.scss';
import { AppContext } from '../../../Providers/AppProvider';

/*
 * Page view of Auction detail (as opposed to modal view)
 * Wrapped in page section. Gets auction id from route.
 * Has Change Auction functionality.
 */
const Page = () => {
  const app = useContext(AppContext);
  const userTimezone = app.user.timezone;
  const navigate = useNavigate();
  const { auction_id } = useParams();
  const auctionIdRef = useRef();


  const keyHandler = (event) => {
    if (event.key === 'Enter') {
      handleGo();
    }
  };

  // Focus input on mount (if no auction_id)
  useEffect(() => {
    if (!auction_id && auctionIdRef.current) {
      auctionIdRef.current.select();
    }
  }, []);

  const handleGo = () => {
    if (auctionIdRef.current.value) {
      navigate(`/reports/auction/${auctionIdRef.current.value}`);
    }
  };

  return (
    <div className="auction-page">
      <DocTitle pageTitle="Auction Detail" />
      <PageSection
        title="Auction"
        subtitle={<EditableAuctionId auctionId={auction_id} />}
      >

        {/* Show auction if auction id, else id input */}
        {auction_id ? (
          <Auction auction_id={auction_id} timezone={userTimezone} />
        ) : (

          <div className="mt-5 d-flex">
            <div className="flex-fill">
              <Input
                type="text"
                defaultValue=""
                placeholder="Auction ID"
                bsSize="sm"
                innerRef={auctionIdRef}
                onKeyDown={keyHandler}
              />
            </div>

            <Button className="ms-2" size="lg" color="primary" onClick={handleGo}>Go</Button>
          </div>

        )}

      </PageSection>
    </div>
  );
};

/*
 * Display of auction ID for page subtitle, toggles to input so you can change the auction id
 */
const EditableAuctionId = ({ auctionId }) => {
  const [entryMode, setEntryMode] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();

  const handleGo = () => {
    if (inputRef.current.value) {
      navigate(`/reports/auction/${inputRef.current.value}`);
      setEntryMode(false);
      inputRef.current.value = '';
    }
  };

  const handleCancel = () => setEntryMode(false);
  const handleEnterMode = () => setEntryMode(true);

  if (!auctionId) return (null);

  return entryMode ? (
         <div className="d-inline-flex">
           <Input type="text" bsSize="sm" defaultValue="" placeholder="Auction ID" innerRef={inputRef} style={{ width: '300px' }} />
           <Button
             size="sm"
             className="ms-1 teeny"
             onClick={handleGo}
             color="primary"
           >
             <FaIcon icon="check" size="sm" />
           </Button>
           <Button size="sm" className="ms-1 teeny" color="link" outline onClick={handleCancel} title="Change auction">
             <FaIcon icon="times" size="sm" />
           </Button>
         </div>
       ) : (
         <span className="modal-subheader">
           {auctionId}
           <Button color="link" size="sm" className="ms-2 inline" onClick={handleEnterMode}>
             <FaIcon icon="pencil-alt" size="sm" />
           </Button>
           <Button tag={Link} to={`/auctions?page=1&auction_id=${auctionId }`} color="link" size="sm" >
             <FaIcon icon="list" size="sm" />
           </Button>
         </span>
       );
};

EditableAuctionId.propTypes = {
  auctionId: PropTypes.string,
};

export default Page;
