import React from 'react';
import PropTypes from 'prop-types';
import PageSection from './PageSection';
import StandardAlert from './StandardAlert';

const NotAuthorized = ({ title }) => (
  <PageSection
    title={title}
    className="not-authorized"
  >
    <StandardAlert icon="alert" color="light">You are not authorized for this page</StandardAlert>
  </PageSection>
);

NotAuthorized.propTypes = {
  title: PropTypes.string,
};

export default NotAuthorized;
