import React, {ChangeEvent, useState} from "react";
import StackedBarChart from "../../Components/Table/StackedBarChart";
import { Nav, TabContent, TabPane} from "reactstrap";
import RejectionCategorySelect from "./List/RejectionCategorySelect";
import IntegrationRejectionList from ".//List/IntegrationRejectionList";
import NumberFormat from "react-number-format";
import {TabNav} from "@thedmsgroup/mastodon-ui-components/lib/components/Tabs/TabNav";

const StackedGraphOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked:true,
      title: false,
      clip:false,
      offset:true,
      type:'time',
      time: {
        unit: 'minute',
        // https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
        displayFormats: {
          minute:'h:mm a',
          hour:'h a',
          day: 'MMM-d',
          quarter: 'QQQ'
        }
      },
      grid: {
        display: true,
      },
      ticks: {
        autoSkip: true,
        maxRotation: 45,
        crossAlign:'center',
        major: {
          enabled: true,
        },
      },
    },
    y:{
      stacked:true
    }
  },
  plugins: {
    legend: {
      display: true,
      responsive: true,
      position: "bottom",
      labels: {
        boxWidth: 22,
        padding: 8,
        font: {
          size: 10
        },
        // usePointStyle:true
      },
      align: "start",
    },
    autocolors: {
      offset:2,
      data: "label"
    }
  },
} as any; // can't get ChartOptions type to validate type:'time'

/*
 * Tabbed widget that displays lists and graphs derived from aggregate
 * data about delivery rejections, from the read service.
 *
 * TODO: when aggregating by day, the label on the last day bar is missing
 *  ( or if just one day, there is no label)
 *  Doesn't happen for one hour bar
 */
type DeliveryRejectStatProps = {
  rejectCategory:string;
  onChangeRejectCategory:(category:string) => void;
  data: Record<string, any>,
  isLoading:boolean,
  className?:string
  aggInterval?:string
}

const DeliveryRejectStat = ({
  rejectCategory,
  onChangeRejectCategory,
  data = [],
  className = "",
  aggInterval

}: DeliveryRejectStatProps) => {

  const [activeTab, setActiveTab] = useState("categories");
  const [selectedRejectCategory, setSelectedRejectCategory] = useState<string>(rejectCategory);

  const handleChangeRejectCategory = (e:ChangeEvent<HTMLInputElement>) => {
    setSelectedRejectCategory(e.target.value);
    onChangeRejectCategory(e.target.value);
  }


  return (
    <div className={`list-and-chart ${className}`}>

      <Nav tabs className="mb-2">
        <TabNav
          isActive={activeTab === "categories"}
          setActive={setActiveTab}
          id="categories"
        >
          By Category
        </TabNav>
        <TabNav
          isActive={activeTab === "integrations"}
          setActive={setActiveTab}
          id="integrations"
        >
          By Integration
        </TabNav>

      </Nav>

      <TabContent activeTab={activeTab} style={{ height: '220px' }}>
        <TabPane tabId="categories">
          <div className="chart-grid">

            <div className="scrolling-list">
              <div className="form-section border">
                <div className="list thin-scrollbar">
                  {data.rejectionCategoryTotals && data.rejectionCategoryTotals.length > 0
                    ? (
                    data.rejectionCategoryTotals.map((bkt:any,i:number) => (
                    <div className="data-row" key={i}>
                      <label>{bkt.key}</label>
                      <div className="text-end me-1"><NumberFormat value={bkt.doc_count} displayType="text" thousandSeparator /></div>
                    </div>
                    ))) : (
                    <i>None found</i>
                  )}
                </div>

              </div>
            </div>


            {data.rejectionsOverTimeByCategory && (
              <StackedBarChart
                labels={data.rejectionsOverTimeByCategory.labels}
                datasets={data.rejectionsOverTimeByCategory.datasets}
                options={StackedGraphOptions}
                styles={{ height: '200px' }}
                aggInterval={aggInterval}
              />
            )}


          </div>
        </TabPane>

        <TabPane tabId="integrations">

          <div className="chart-grid integrations-rejected">
            <div>
              <div className="mb-1">
                <RejectionCategorySelect selected={selectedRejectCategory} onChange={handleChangeRejectCategory} />
              </div>
              <IntegrationRejectionList
                rejectionsByCategory={data.integrationRejectionTotals}
                category={selectedRejectCategory}
                className="scrolling-list"
              />
            </div>

            {data.rejectionsOverTimeByIntegration && (
              <StackedBarChart
                labels={data.rejectionsOverTimeByIntegration.labels}
                datasets={data.rejectionsOverTimeByIntegration.datasets}
                options={StackedGraphOptions}
                styles={{ height: '200px' }}
                aggInterval={aggInterval}
              />
            )}

          </div>

        </TabPane>

      </TabContent>

    </div>
  )
}

export default DeliveryRejectStat;

