import React, {FC, useRef, useEffect} from "react";
import {Bar } from "react-chartjs-2";
import type { ChartOptions} from 'chart.js';
import {AggIntervalToUnit} from "../../utils/chart";
import autocolors from 'chartjs-plugin-autocolors';
import { Chart as ChartJS, BarController, BarElement, TimeScale, LinearScale, CategoryScale, Legend, Tooltip} from 'chart.js';
ChartJS.register(BarController, BarElement,  TimeScale, LinearScale, CategoryScale, Legend, Tooltip, autocolors );
import 'chartjs-adapter-date-fns';

type BucketGraphStackedProps = {
  labels:string[];
  datasets:any[];
  options:ChartOptions;
  className?:string;
  plugins?:any[],
  styles?:Record<string, any>;
  title?:string;
  aggInterval?:string;
}

/*
 * Uses ChartJS to render a stacked bar graph with multiple data sets, inside of a styled div.
 *
 * We use the autocolors plugin for more color range. The default Colors module has a limited palette,
 * and begins to repeat when there are many datasets.
 */
const StackedBarChart:FC<BucketGraphStackedProps> = ({
                                            labels,
                                            datasets,
                                            options,
                                            styles={},
                                            plugins=[],
                                            className= "",
                                            title="",
                                            aggInterval=""
}) => {
  const chartRef = useRef();

  // Update x scale unit in options if aggregation interval changes
  useEffect(() => {
    if (aggInterval && chartRef.current) {
      const map = AggIntervalToUnit as Record<string, string>
      const unit:string|null = map[aggInterval];
      if (unit) {
        //@ts-ignore
        chartRef.current.options.scales.x.time.unit = unit;
        //@ts-ignore
        chartRef.current.update();
      }
    }
  }, [aggInterval, datasets, chartRef.current])

  return (
    <div style={styles} className={className}>
      {title && <h5>{title}</h5>}

        <Bar
          ref={chartRef}
          //@ts-ignore  getting chart option types to validate is (𖦹﹏𖦹;)
          options={options}
          data={{labels, datasets}}
          plugins={[autocolors, ...plugins]}
        />

    </div>
  )
}

export default StackedBarChart;
