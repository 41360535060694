import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';
import { FormTabFlat, FormTabMore } from '../../../../Components/Form/FormCommon';
import usePermission from "../../../../Hooks/usePermission";

/*
 * Navigation for RulesConfig form
 */

const NavTabs = ({
  activeTab, handleSelectTab, errorTabs, isSectionValid, product, allowAdvanced,
}) => {
  const allowEditOrder = usePermission('order.edit');
  const moreTabs = useMemo(() => {
    const tabs = product === 'calls' ? [
      { id: 'budget', label: 'Budget/Caps', valid: isSectionValid('datasheets') },
      { id: 'datasheets', label: 'Datasheets', valid: isSectionValid('budget') },
      { id: 'destinations', label: 'Destinations', valid: isSectionValid('destinations') },
      { id: 'tags', label: 'Tag', valid: isSectionValid('tags') },
      { id: 'calls', label: 'Call Settings', valid: isSectionValid('calls') },
      { id: 'activity', label: 'On/Off', valid: isSectionValid('activity') },
    ] : [
      { id: 'budget', label: 'Budget', valid: isSectionValid('budget') },
      { id: 'datasheets', label: 'Datasheets', valid: isSectionValid('budget') },
      { id: 'destinations', label: 'Destinations', valid: isSectionValid('destinations') },
      { id: 'tags', label: 'Tag', valid: isSectionValid('tags') },
      { id: 'activity', label: 'On/Off', valid: isSectionValid('activity') },
    ];

    if (product === 'clicks') {
      tabs.push({ id: 'tracking', label: 'Tracking', valid: isSectionValid('tracking') });
    }

    if (allowAdvanced) {
      tabs.push({ id: 'advanced', label: 'Advanced', valid: isSectionValid('advanced') });
    }

    return tabs;
  }, [product, allowAdvanced]);

  return (
    <Nav className="mastodon-tabs mb-4">
      <FormTabFlat
        id="summary"
        label="Summary"
        activeTab={activeTab}
        isValid
        setActive={handleSelectTab}
      />
      <FormTabFlat
        id="targeting"
        label="Targeting"
        activeTab={activeTab}
        isValid={isSectionValid('targeting')}
        setActive={handleSelectTab}
      />
      <FormTabFlat
        id="bidding"
        label="Bidding"
        activeTab={activeTab}
        isValid={isSectionValid('bidding')}
        setActive={handleSelectTab}
      />

      <FormTabFlat
        id="enrichments"
        label="Enrichments"
        activeTab={activeTab}
        isValid={isSectionValid('enrichments')}
        setActive={handleSelectTab}
      />
      <FormTabFlat
        id="sale_confirmation"
        label="Sale Confirmation"
        activeTab={activeTab}
        isValid={isSectionValid('sale_confirmation')}
        setActive={handleSelectTab}
      />

      {product === 'clicks' && (
        <FormTabFlat
          id="ads"
          label="Ads"
          activeTab={activeTab}
          setActive={handleSelectTab}
          isValid={isSectionValid('ads')}
        />
      )}
      <FormTabFlat
        id="channels"
        label="Channels"
        activeTab={activeTab}
        setActive={handleSelectTab}
        isValid={isSectionValid('channels')}
      />

      {moreTabs.length > 0 && (
      <FormTabMore
        items={moreTabs}
        activeTab={activeTab}
        setActive={handleSelectTab}
        errorTabs={errorTabs}
      />
      )}
    </Nav>
  );
};

NavTabs.propTypes = {
  activeTab: PropTypes.string,
  handleSelectTab: PropTypes.func,
  isSectionValid: PropTypes.func,
  errorTabs: PropTypes.array,
  product: PropTypes.string,
  allowAdvanced: PropTypes.bool,
};

NavTabs.defaultProps = {
  activeTab: '1',
  handleSelectTab: () => {},
  isSectionValid: true,
};

export default NavTabs;
