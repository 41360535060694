import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import FlexTable from '../../../Components/Table/FlexTable';
import StatusToggler from '../../../Components/Form/StatusToggler';
import StandardAlert from '../../../Layout/StandardAlert';
import { titleCaseWord } from '../../../utils/string';
import OrderLastSold from '../OrderLastSold';
import {OrdersSubtablePropertiesType} from "../advertiser";
import FaveHeart from "../../../Components/FaveHeart";

const OrdersSubtable: FC<OrdersSubtablePropertiesType> = ({
  view,
  account,
  orders,
  openOrderModal,
  onFavorite,
  favoriteOrderIds=[],
  allowViewLeads,
  allowViewCalls,
  allowEditOrder,
  allowEditOrderStatus,
  allowViewAuctions,
  onStatusChange,
  allowCreateOrder,
}) => {



  const filteredOrders = useMemo(() => {

    if (view === 'favorites' ) {
      return orders.filter((ord) => favoriteOrderIds.includes(ord.id));
    }
    return orders;
  }, [favoriteOrderIds])


  const columns = useMemo(
    () =>  [
        {
          accessor: "name",
          Header: "Order",
          disableSortBy: true,
          className: "order-name-cell",
          Cell: ({value, row}: any) => <OrderName
            account={row.original.account}
            name={value}
            id={row.original.id}
            isFavorite={favoriteOrderIds.includes(row.original.id)}
            onFavorite={onFavorite}
          />
        },
        {
          accessor: "vertical.display_name", Header: "Vertical", disableSortBy: true, maxWidth: 200,
        },
        {
          accessor: "product",
          Header: "Product",
          maxWidth: 110,
          disableSortBy: true,
          Cell: ({value}: any) => titleCaseWord(value),
        },
        {
          accessor: "status",
          Header: "Status",
          disableSortBy: true,
          disableGlobalFilter: true,
          className: "status-cell",
          maxWidth: 120,
          Cell: ({row}: any) => (
            <StatusCell
              status={row.original.status}
              orderId={row.original.id}
              product={row.original.product}
              allowEditOrder={allowEditOrder}
              allowEditOrderStatus={allowEditOrderStatus}
              updateStatusCallback={(id: number, status: string) => onStatusChange(row.original, status)}
            />
          ),
        },
        {
          accessor: "last_sold_at",
          Header: "Last Sold",
          disableSortBy: true,
          disableGlobalFilter: true,
          className: "last-sold-cell",
          Cell: ({row}: any) => (
            <OrderLastSold
              date={row.original.last_sold_at}
              isStale={row.original.last_sold_alert}
              status={row.original.status}
              orderId={row.original.id}
            />
          ),
        },
        {
          id: "actions",
          disableGlobalFilter: true,
          Cell: ({row}: any) => (
            <OrderActions
              account={account}
              order={row.original}
              onEditClick={openOrderModal}
              allowViewCalls={allowViewCalls}
              allowViewLeads={allowViewLeads}
              allowEditOrder={allowEditOrder}
              allowViewAuctions={allowViewAuctions}
            />
          ),
          width: 275,
        },

      ],
    [favoriteOrderIds],
  );

  const initialTableState = useMemo(() => ({ gridLayout: { columnWidths: ['300px', '1fr', '1fr', '1fr', '230px', '2fr'] } }), []);

  return (
    <>

      {orders.length > 0 ? (
        <>

          <FlexTable
            columns={columns}
            className="orders-subtable"
            initialState={initialTableState}
            /* data={FilterContext.filteredData} */
            data={filteredOrders}
            loading={false}
            noDataMessage="No orders were found"
            disableFilters
          />


          {allowCreateOrder && (
            <span style={{ marginLeft: '2rem' }}>
              <Button
                title={`New order for ${account.name}`}
                onClick={() => openOrderModal(null, account)}
                size="sm"
                color="link"

              >
                <FaIcon icon="plus" size="sm" />
                {' '}
                New Order
              </Button>
            </span>
          )}

        </>
      ) : (
        <>
          <StandardAlert color="light" className="mb-0">This account has no orders.</StandardAlert>
          {allowCreateOrder && (
            <span style={{ marginLeft: '2rem' }}>
              <Button
                color="link"
                size="sm"
                title={`New order for ${account.name}`}
                onClick={() => openOrderModal(null, account)}
              >
                <FaIcon icon="plus" size="sm" />
                {' '}
                New Order
              </Button>
            </span>
          )}
        </>
      )}
      {' '}
      {/* orders.length */}

    </>

  );
};

const OrderName = ({account, name, id, isFavorite, onFavorite}: any) => {
  return (
    <div>
      <FaveHeart isFavorite={isFavorite} id={id} size="xs" onChange={onFavorite} className="me-1" key={`fav-${id}`} />
      <Link
        to={`/accounts/${account.id}/order/${id}/rules`}
        state={{account}}
        title="Rules and targets for this order"
      >
      {name}
      </Link>
    </div>
  )
}

const OrderActions = ({
  account,
  order,
  onEditClick,
  allowViewLeads,
  allowViewCalls,
  allowEditOrder,
  allowViewAuctions,
}: any) => (
  <div className="list-actions">
      <Link
        to={`/accounts/${account.id}/order/${order.id}/rules`}
        state={{account}}
        title="Rules and targets for this order"
        className="action-item"
      >
        <FaIcon icon="clipboard-list" size="1x" />
      </Link>

    {allowEditOrder === true && (
      <Button
        color="link"
        onClick={() => { onEditClick(order, account); }}
        className="action-item inline"
        title="Order Settings"
      >
        <FaIcon icon="cog" size="1x" />
      </Button>
    )}

    {order.product === 'leads' && allowViewLeads && (
      <Link
        to={{
          pathname: '/reports/leads',
          search: `accounts=${account.id}&orders=${order.id}&sort_by=received_time&sort_dir=desc`,
        }}
        title="Lead prospects for this order"
        className="action-item"
      >
        <FaIcon icon="id-card" size="1x" />
      </Link>
    )}

    {order.product === 'calls' && allowViewCalls && (
      <Link
        to={{
          pathname: '/reports/calls',
          search: `accounts=${account.id}&orders=${order.id}&sort_by=received_time&sort_dir=desc`,
        }}
        title="Call prospects for this order"
        className="action-item"
      >
        <FaIcon icon="phone" size="1x" />
      </Link>
    )}

    {allowViewAuctions && (
      <Link
        to={{
          pathname: '/auctions',
          search: `page=1&order=${order.id}`,
        }}
        title="Auctions"
        className="action-item"
      >
        <FaIcon icon="comment-dollar" size="1x" />
      </Link>
    )}

  </div>
);

const StatusCell = ({
  status, orderId, product, allowEditOrder, allowEditOrderStatus, updateStatusCallback,
}: any) =>
  // For calls and c2c products, you need to have a special permission for changing order status
  (
    <StatusToggler
      status={status}
      target={orderId}
      onChangeAsync={updateStatusCallback}
      allowEdit={allowEditOrder || allowEditOrderStatus}
    />
  );
export default OrdersSubtable;
