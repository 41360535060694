import { useState } from 'react';
import copy from 'fast-copy';
import { RuleProps } from '../Components/Rules/constants';
import { isObject } from '../utils/types';
import {
  getDeep, setDeep, addDeep, removeDeep, getDisabledAncestor, getAncestorWithProps,
} from '../Components/Rules/TreeUtil';
import usePermission from './usePermission';

// Use reducer?
// const TreeState = (state, action) => {
//   //Name can be field name or a named action
//   const {name, value} = action;
//
//   switch (name) {
//
//     /*
//     Most are
//     setTree(...);
//     setPreviousSelectedRule(...)
//     setSelectedRule(...);
//      */
//
//     case 'set_tree':
//       return {...state};
//     case 'update_tree':
//       return {...state};
//     case 'add_temp_rule':
//       return {...state};
//     case 'swap_rule':
//       return {...state};
//     case 'select':
//       return {...state};
//     case 'reselect':
//       return {...state};
//
//     default:
//       return state;
//   }
// }

/*
 * Hook for managing a tree of rules
 */
const useRuleTree = (initialTree = null, initialRule = null, newRuleDefault = {}) => {
  const allowAdvanced = usePermission('rules_advanced.edit');
  const allowIntegration = usePermission('integrations.edit');

  // Tree is the root rule object, with an array of child rules
  // (which themselves may have child rules to nth level)
  const [tree, setTree] = useState(initialTree);
  const [selectedRule, setSelectedRule] = useState(initialRule);
  const [previousSelectedRule, setPreviousSelectedRule] = useState();
  const [refreshKey, setRefreshKey] = useState(1001);

  // getRule is only used in RulesConfig where we show parent
  const getRule = (targetId) => getDeep(tree, targetId);

  // Places rule with temporary ID as first step in adding new rule.
  // On save will be swapped out with the final saved rule (or delete if user cancels process)
  const addTempRule = (rule, placement, newRule) => {
    if (selectedRule && selectedRule.isNew) {
      // TODO: return value for this case?
      // notify('You are currently editing a new rule. Please save or cancel.', 'warning');
      return;
    }

    newRule = newRule || newRuleDefault;

    // Create a unique id because we use id as component key to ensure update
    newRule.id = new Date().getTime();

    if (placement === 'below') {
      newRule.parent_id = rule.parent_id;
      newRule.priority = rule.priority + 1;
    } else { // child
      newRule.parent_id = rule.id; // priority will be 0 (default set by api)
      newRule.priority = 0;
    }

    const ruleTree = addDeep(tree, newRule);
    setTree(ruleTree);
    setPreviousSelectedRule(selectedRule);
    setSelectedRule(newRule);
  };

  // Use for Copy
  const addNewRule = (newRule, selectNew = true) => {
    const ruleTree = addDeep(tree, newRule);
    setTree(ruleTree);
    if (selectNew) {
      setPreviousSelectedRule(selectedRule);
      setSelectedRule(newRule);
    }
  };

  // Used to replace temporary new rule with new saved rule
  const swapRule = (newRule, tempRule) => {
    let ruleTree = removeDeep(tree, tempRule);
    ruleTree = addDeep(tree, newRule);
    setPreviousSelectedRule(selectedRule);
    setTree(ruleTree);
    setSelectedRule(newRule);
    // setDirty(false);
  };

  const deleteRule = (rule, newSelection) => {
    // on delete, go to provided rule, previously selected rule, or root
    if (!newSelection) {
      newSelection = (previousSelectedRule && !previousSelectedRule.isNew) ? previousSelectedRule : tree;
    }
    const ruleTree = removeDeep(tree, rule);
    setTree(ruleTree);
    setPreviousSelectedRule(null);
    setSelectedRule(newSelection);
  };

  const selectRule = (rule = null, reset = false) => {
    // console.log('index.js:setSelected (rule, selected, tab):', rule, typeof rule, selectedRule, tab);

    // already selected
    if (rule && selectedRule && selectedRule.id === rule.id) {
      if (reset) {
        // Rule is being cancelled, or rule is re-selected post-update
        // Fetch current value from rule tree.
        setSelectedRule(copy(rule));
        setRefreshKey(refreshKey + 1);
      }

      return;
    } if (!rule) {
      // on cancel new rule, deselect the rule and revert to previous
      rule = previousSelectedRule;
    }

    // just changing rules, as one does
    setSelectedRule(copy(rule));
  };

  // Updates one rule in tree
  // Yeah, name should be 'updateRule' but that conflicted with other updateRule methods
  const updateRuleTree = (updatedRule) => {
    const ruleTree = setDeep({ ...tree }, enhanceTree(updatedRule));
    setTree(ruleTree);
  };

  // Add 'properties' array to rule which is used for icon display and filtering.
  // Basically, an array of strings indicating if rule has configuratons for targeting, ads, bids, etc
  const enhanceTree = (rule) => {
    rule.properties = [];

    RuleProps.map((prop) => {
      if (ruleHasProp(rule, prop)) {
        rule.properties.push(prop);
      }
    });

    if (rule.rules.length) {
      rule.rules = rule.rules.map((childRule) => enhanceTree(childRule));
    }

    return rule;
  };

  // enhance the tree rules with properties before setting
  const processTree = (tree) => setTree(enhanceTree(copy(tree)));

  const ruleHasProp = (rule, prop) => {

    switch (prop) {
      case 'ads':
        return rule.ads && Object.keys(rule.ads).length > 0;
      case 'bid_blocked':
        if (rule.bidding) {
          if (rule.bidding.type === 'block') {
            return true;
          } if (rule.bidding.type === 'absolute') {
            return (!rule.bidding.absolute_bid || isNaN(rule.bidding.absolute_bid));
          } if (rule.bidding.type === 'modifier') {
            return (!rule.bidding.bid_modifier || isNaN(rule.bidding.bid_modifier));
          }
        }
        return false;
      case 'disabled':
        return rule.disabled;
      case 'bid_schedule':
        return rule.schedule_modifiers && Object.keys(rule.schedule_modifiers).length;
      case 'billable_duration':
        return rule.billable_duration;
      case 'budget':
        return rule.budgets && (Object.values(rule.budgets).length || Object.values(rule.caps).length);
      case 'channel_modifiers':
        return Array.isArray(rule.channel_modifiers) && rule.channel_modifiers.length;
      case 'datasheets':
        return rule.datasheets && Object.keys(rule.datasheets).length;
      case 'destinations':
        return rule.redirect_url || (allowIntegration && rule.prefill_integration) || rule.destination_number || (allowIntegration && Array.isArray(rule.delivery_destinations) && rule.delivery_destinations.length);
      case 'destination_number':
        return rule.destination_number;
      case 'bid_exclusivity':
        return rule.exclusivity;

      case 'delivery_destinations':
      case 'post_sale':
        return allowIntegration && Array.isArray(rule.delivery_destinations) && rule.delivery_destinations.length;
      case 'prefill':
        return allowIntegration && rule?.prefill?.integration;
      case 'match_conditions':
        return Array.isArray(rule.match_conditions) && rule.match_conditions.length && rule.match_conditions[0].length;
      case 'match_is_required':
        return rule.match_is_required;
      case 'sale_conditions':
        return Array.isArray(rule.enrichment_addons) && rule.enrichment_addons.length;
      case 'schedule':
        return Array.isArray(rule.schedule) && rule.schedule.length;
      case 'is_standalone':
        return rule.is_standalone;
      case 'tags':
        return Array.isArray(rule.tags) && rule.tags.length;
      case 'advanced':
        return allowAdvanced && (rule.internal_bid_modifier || rule.track_qualifying_rate || rule.exclude_profit_reporting || rule.vendor_attributes);
      case 'tracking':
        return (Array.isArray(rule.impression_urls) && rule.impression_urls.length)
          || (Array.isArray(rule.click_urls) && rule.click_urls.length)
          || (Array.isArray(rule.click_tags) && rule.click_tags.length);

      // Routing Rules
      case 'sale_match_caps':
        return (rule.sale_caps && Object.values(rule.sale_caps).length)
          || (rule.match_caps && Object.values(rule.match_caps).length);

      case 'sell_strategy':
        return Boolean(rule.sell_strategy);

      case 'flow_control':
        return Boolean(rule.flow_control);

      case 'proxy_bidding':
        return allowIntegration && isObject(rule.proxy_bidding) && Object.keys(rule.proxy_bidding).length > 0;
    }
    return false;
  };

  const findPropAncestors = (startRule, funcMap) => getAncestorWithProps(tree, startRule, funcMap);

  return {
    tree,
    refreshKey,
    selectedRule,
    setTree: processTree,
    getRule,
    deleteRule,
    addTempRule,
    addNewRule,
    swapRule,
    selectRule,
    updateRuleTree,
    getDisabledAncestor: (rule) => getDisabledAncestor(tree, rule),
    findPropAncestors,
  };
};

export default useRuleTree;
