import React from 'react';
import {
  Row, Col, Button,
  FormGroup, Label, FormText, Input, FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Required } from '../../../Components/Form/FormCommon';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";

const Details = ({
  vendor, errors, onChange, isNew
}) => {

  const copyKeyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      notify('Copied!', 'success', { autoClose: 500 });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Row>
      <Col sm={6}>
        <FormGroup>
          <Label>
            Name
            {' '}
            <Required />
          </Label>
          <Input
            type="text"
            name="name"
            onChange={onChange}
            value={vendor.name || ''}
            invalid={!!errors.name}
          />
          <FormFeedback>{errors.name}</FormFeedback>
        </FormGroup>

      </Col>

      <Col sm={6}>

        <FormGroup>
          <Label>
            API Key
          </Label>
          { (vendor.token !== undefined) && 
          (<Button
            color="link"
            id="btn-export-copy"
            style={{ paddingTop: 0, paddingBottom: 0 }}
            onClick={() => copyKeyToClipboard(vendor.token)}
          >
            <FaIcon icon="copy" />
            {' '}
            Copy
          </Button>
          )}

          <Input
            type="text"
            name="token"
            id="key"
            value={vendor.token || ''}
            readOnly={true}
            placeholder={ isNew ? ("A token will be automatically created.") : undefined}
          />

        </FormGroup>

      </Col>
    </Row>
  );
};

Details.propTypes = {
  vendor: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  isNew: PropTypes.bool,
};

export default Details;
