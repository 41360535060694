import React, {ChangeEvent} from "react";
import {Input} from "reactstrap";


const RejectionCategorySelect = ({
                                   selected='delivery_timeout',
                                   onChange
}:{selected:string, onChange:(e:ChangeEvent<HTMLInputElement>)=>void}) => {

  return (
    <Input
      type="select"
      bsSize="sm"
      value={selected}
      onChange={onChange}
    >
      {/*<option value="" >All Categories</option>)*/}
      <option value="no match" >No Match</option>)
      <option value="duplicate" >Duplicate</option>)
      <option value="invalid" >Invalid</option>)
      <option value="delivery_failure" >Failure</option>)
      <option value="delivery_timeout" >Timeout</option>)
      <option value="general" >General</option>)
      <option value="todo" >Todo</option>)
      <option value="capped" >Capped</option>)
      <option value="dnc" >DNC</option>)
      <option value="compliance" >Compliance</option>)
      <option value="geo" >Geo</option>)
    </Input>
  )
}

export default RejectionCategorySelect;
