import React from 'react';
import {
  Button, ButtonGroup, Col, Label, Row,
} from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import Select from '../Form/SimpleSelect';
import EnhancedSelect from "../Form/EnhancedSelect";
import InputWithIcon from '../Form/InputWithIcon';
import {DefaultReactSelectProps} from "../Form/const";
// import {FontAwesomeIcon as FaIcon} from "@fortawesome/react-fontawesome";

/*
 * TextSearch:
 *     Input for text search entry
 *
 * Filter and StackedFilter
 *    Configurable Multi or single select dropdown filter components
 *
 * for use in a filter panel
 */

/* Single inline dropdown filter using react-select.
 * Wraps the EnhancedSelect component, but adds horizontal margin, and returns a parameter name as well as selected value.
 */
const Filter = ({
  onChange,
  options = [],
  param,
  value,
  label,
  disabled = false,
  className="",
  ...rest
}) => {
  const onSelectChange = (value) => {
    onChange && onChange(param, value);
  };

  const selectProps = { ...DefaultReactSelectProps, ...rest };
  return (
          <EnhancedSelect
            isClearable
            onChange={onSelectChange}
            label={label}
            disabled={disabled}
            options={options}
            value={value}
            tabSelectsValue
            backspaceRemovesValue
            isDisabled={disabled}
            className={`select-filter me-3 ${className}`}
            {...selectProps}
          />
  )

  // return (
  //   <div id={`filter-${param}`} className="select-filter me-3">
  //     <div>
  //       {label && <label className="form-label">{label}</label>}
  //       <Select
  //         classNamePrefix="select-filter"
  //         isClearable
  //         onChange={onSelectChange}
  //         options={options}
  //         value={value}
  //         tabSelectsValue
  //         backspaceRemovesValue
  //         {...selectProps}
  //         isDisabled={disabled}
  //       />
  //     </div>
  //   </div>
  // );
};

// Two state filter from a button group. Off=default/no-filter, On=filtered
const ButtonToggleFilter = React.memo(
  ({
    onChange, param, active, labelOn, labelOff,
  }) => {
    const handleClick = () => {
      onChange && onChange(param, !active);
    }

    return (
      <ButtonGroup className='btn-toggle'>
        <Button color="secondary" onClick={handleClick} outline={active}>
          {labelOff}
        </Button>
        <Button color="secondary" onClick={handleClick} outline={!active}>
          {labelOn}
        </Button>
      </ButtonGroup>
    );
  },
);

/* Filter that is stacked with other filters in a single dropdown */
const StackedFilter = ({
  onChange, onApply=null, options, param, value, label, ...rest
}) => {

  const onSelectChange = (value) => {
    onChange && onChange(param, value);
  };

  const selectProps = { ...DefaultReactSelectProps, ...rest };

  return (
    <Row id={`filter-${param}`} className="select-filter align-items-center">
      {label && (
        <Col sm={3}>
          <Label>{label}</Label>
        </Col>
      )}
      <Col sm={label ? 9 : 12}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Select
              classNamePrefix="select-filter"
              isClearable
              onChange={onSelectChange}
              options={options}
              value={value}
              tabSelectsValue
              backspaceRemovesValue
              {...selectProps}
            />
          </div>

          {/* optional apply link to right of select */}
          {typeof onApply === 'function' && (
          <div>
            <Button className="ms-1" size="sm" color="link" onClick={onApply}>apply</Button>
          </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

const TextSearch = React.memo(
  ({
    value,
    placeholder,
    onUpdate,
    onClear,
    autofocus = true,
    disabled = false,
    delay = 500,
    style={}
  }) => {
    const handleChangeDebounced = useDebouncedCallback((val) => {
      onUpdate(val.trim());
    }, delay);

    // This was done for auction list, an odd case where search is handled differently
    const handleEnter = (e) => {
      if (e.key === 'Enter') {
        onUpdate(e.target.value.trim());
      }
    };

    // Why the key?
    // We need to use an uncontrolled input (defaultValue) for debounce to work.
    // https://github.com/xnimorz/use-debounce/issues/13
    // This can be a problem in cases where the value changes from some external event,
    // such as when filtering is wired to the query string and the URL changes.
    // The uncontrolled component won't react to this, but the changed key will
    // force a re-render

    return (
      <div className="search-filter-wrapper" key={`search-filter-key-${value}`} style={style}>
        <InputWithIcon
          icon="search"
          iconSize="lg"
          placeholder={placeholder}
          name="search_term"
          containerClass="expands"
          defaultValue={value}
          autofocus={autofocus}
          onChange={(e) => handleChangeDebounced(e.target.value)}
          onKeyDown={handleEnter}
          onClear={onClear}
          disabled={disabled}
        />
      </div>
    );
  },
);

export {
  Filter, StackedFilter, ButtonToggleFilter, TextSearch,
};
