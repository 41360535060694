import React, {
  useState, useEffect, useRef, useContext, useReducer,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Row, Col, Form, FormGroup, FormFeedback, Input, Button, Spinner,
} from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import PasswordWithToggle from '../Components/Form/PasswordWithToggle';
import StandardAlert from '../Layout/StandardAlert';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import DocTitle from '../Layout/DocTitle';
import { AppContext } from '../Providers/AppProvider';

const loginStateReducer = (state, action) => {
  switch (action.type) {
    case 'activated':
      // for showing activation message
      return { ...state, activated: true };
    case 'invalidForm':
      return {
        ...state, isPosting: false, isValid: false, formError: action.error, activated: false,
      };
    case 'success':
      return { ...state, isPosting: false, isValid: true };
    case 'fail':
      return {
        ...state, isPosting: false, isValid: false, formError: action.error,
      };
    case 'posting':
      return {
        ...state, isPosting: true, isValid: true, formError: '', activated: false,
      };
    default:
      return state;
  }
};

const Login = () => {
  const app = useContext(AppContext);
  const location = useLocation();
  const [loginState, dispatch] = useReducer(loginStateReducer, { isPosting: false, isValid: true, formError: '' });
  const [redirect, setRedirect] = useState();
  const inputRef = useRef();

  // Do on mount
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // e.g., session timeout redirected here
    // if(authContext.authError ){
    //   notify( authContext.authError , 'warning', {autoClose:false,delay:500})
    // }
    if (app.authError) {
      notify(app.authError, 'warning', { autoClose: false, delay: 500 });
    }

    if (location.state && location.state.activated) {
      dispatch({ type: 'activated' });
    }

    // unmount
    return () => {
      toast.dismiss();
    };
  }, []);

  // Redirect if authorized (there could be delay as we query other tabs)
  useEffect(() => {
    if (app.isAuthorized) {
      setRedirect(app.getLoginRedirect(location.state?.from) || '/accounts');
    }
  }, [app.isAuthorized]);

  const handleChange = () => {
    toast.dismiss();
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (loginState.isPosting) return;

    if (!evt.target.email.value || !evt.target.password.value) {
      // not validating individual fields
      dispatch({ type: 'invalidForm', error: 'Email and password are required' });
    } else {
      execLogin({
        email: evt.target.email.value,
        password: evt.target.password.value,
      });
    }
  };

  const execLogin = async (credentials) => {
    dispatch({ type: 'posting' });
    const result = await app.api.login(credentials);
    if (!result) {
      if (app.api.error.message.includes("Invalid")) {
        dispatch({ type: 'fail', error: app.api.error.message });
      } else {
        dispatch({ type: 'fail', error: 'Invalid email or password' });
      }
      /* example 400 error object
            {
                form:{ password: "This value should not be blank."}
                message: ""
                name: "Invalid Request"
                status: 400
            } */
    } else {
      dispatch({ type: 'success' });
      app.onLogin(result);
      // const searchParams = new URLSearchParams(window.location.search);
      // const redir = searchParams.get('rd') || '/accounts';

      let redir = '/accounts';
      if (location.state && location.state.referrer) {
        const { search, pathname } = location.state.referrer;
        // console.log('Login.js:building redirect', search, pathname);
        if (pathname) {
          redir = pathname;
          if (!redir || redir === '/' || redir === '/login') {
            redir = '/accounts';
          } else if (search) {
            redir += search;
          }
        }
      }

      setRedirect(redir);
    }
  };

  return (
    <>

      {redirect && <Navigate to={redirect} />}

      <DocTitle pageTitle="Sign In" />
      <Row>
        <Col sm={12} md={{ size:4, offset:4 }} className="entry-form-panel">
          <Form onSubmit={handleSubmit}>
            { loginState.activated && <StandardAlert color="success" icon="check">Your account has been activated. Please sign in.</StandardAlert>}

            { !loginState.isValid && (<StandardAlert color="warning">{loginState.formError}</StandardAlert>)}

            <FormGroup>
              <Input
                type="text"
                name="email"
                placeholder="Email Address"
                innerRef={inputRef}
                onChange={handleChange}
              />
              <FormFeedback>Email address is required</FormFeedback>
            </FormGroup>

            <FormGroup>
              <PasswordWithToggle
                name="password"
                placeholder="Password"
                onChange={handleChange}
                error="Password is required"
              />
              <FormFeedback>Password is required</FormFeedback>
            </FormGroup>

            <Button type="submit" color="primary" block disabled={loginState.isPosting}>
              { loginState.isPosting === true ? (
                <Spinner color="light" size="sm" />
              ) : <span>Sign In</span>}
            </Button>

            <Button size="sm" color="link" tag={Link} to="/forgot-password" block className="mt-2 no-case">Forgot password?</Button>

          </Form>
        </Col>
      </Row>

    </>

  );
};

Login.propTypes = {
  location: PropTypes.object,
};

export default Login;
