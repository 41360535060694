import React, { useContext,  useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, FormText, FormGroup, Label, Button, Input, FormFeedback
} from "reactstrap";
import StandardAlert from '../../../../Layout/StandardAlert';
import RulesContext from '../../../../Providers/RulesContext';
import DeliveryDestinations from '../../../../Components/Rules/DeliveryDestinations';
import Prefill from './Prefill';
import SipConfig from './SipConfig';
import {TokenTextarea} from "@thedmsgroup/mastodon-ui-components";
import useApi from "../../../../Hooks/useApi";
import {HelpPopper} from "@thedmsgroup/mastodon-ui-components";


const Destination = (props) => {
  const rulesContext = useContext(RulesContext);
  const definitionsRef = useRef(rulesContext.api.getIntegrationDefinitionsByType('delivery'));
  const debugApi = useApi('debug', 'template');
  const[redirectUrl, setRedirectUrl] = useState(props.redirectUrl);
  const[redirectUrlTemplate, setRedirectUrlTemplate] = useState(props.redirectUrlTemplate);

  const handleDeliveryChange = (destinations) => {
    props.onChange('delivery_destinations', destinations);
  };

  const handleCallStartedWebhookChange = (destinations) => {
    props.onChange('call_started_webhooks', destinations);
  };

  const handleCallEndedWebhookChange = (destinations) => {
    props.onChange('call_ended_webhooks', destinations);
  };

  const handlePrefillChange = (prefill) => {
    props.onChange('prefill', prefill);
  };

  const handleSipChange = (sip) => {
    props.onChange('destination_sip', sip);
  };


  const handleChangeRedirectUrl = useCallback((val) => {
    setRedirectUrl(val);
    props.onChange('redirect_url', val);
  }, []);


  const handleChangeRedirectUrlTemplate = (val) => {
    setRedirectUrlTemplate(val);
    props.onChange('redirect_url_template', val);
  };

  const handleClickDestinationNumberAncestor = () => {
    const targetRule = rulesContext.api.getRule(props.propAncestors.destination_number.id);
    targetRule && rulesContext.api.setSelected(targetRule);
  };

  const handleUrlPreview = async(url) => {
    const result =  await debugApi.fetch( {
      template:url
    });
    return result ? result.result : "";
  }

  const { errors } = props;

  return (
    <div>
      {(errors.destination_number || errors.delivery_destinations || errors.destination_sip)
          && (
          <StandardAlert color="info" className="validation">
            Please correct the errors below before continuing
          </StandardAlert>
      )}
      { props.product === 'clicks' && (
        <>
          <div className="form-section">
            <div className="form-section-header">Redirect URL</div>
            <Row className="mb-3">
              <Col lg={12} xl={9}>
                <FormGroup>

                  <TokenTextarea
                    tokens={props.tokens}
                    text={redirectUrl || ""}
                    onChange={handleChangeRedirectUrl}
                    rows={3}
                    label="Redirect URL"
                    onPreview={handleUrlPreview}
                  />


                  <FormText className="mb-2">
                    The visitor will be redirected to this URL upon clicking an ad.
                    Insert lead data by providing placeholder names surrounded by braces:
                    <i>&amp;insured=&#123;currently_insured&#125;</i>
                    .&nbsp;
                    This destination may be overriden by prefill or proxy integration, if set.
                  </FormText>

                </FormGroup>
              </Col>

            </Row>

            <Row className="mb-3">
              <Col lg={12} xl={9}>
                <FormGroup>
                  <TokenTextarea
                    tokens={props.tokens}
                    text={redirectUrlTemplate || ""}
                    onChange={handleChangeRedirectUrlTemplate}
                    rows={3}
                    label="Redirect URL Template"
                    onPreview={handleUrlPreview}
                  />

                <FormText>
                  If receiving a redirect URL from a third party integration, use this template to add additional parameters.
                  <br />
                  You can use
                  {' '}
                  <i>&#123;redirect_url&#125;</i>
                  {' '}
                  as a placeholder for the provided URL. Or, if only query string parameters are specified,
                  they will be appended to the given redirect URL (for example,d
                  {' '}
                  <i>insured=&#123;currently_insured&#125;&amp;sub1=&#123;sub_1&#125;</i>
                  )
                </FormText>

                </FormGroup>
              </Col>
            </Row>
          </div>

          <FormGroup>
            <Prefill
              onChange={handlePrefillChange}
              integration={props.prefill.integration}
                  /* template={props.prefill.template} */
              errors={errors.prefill}
            />
          </FormGroup>

        </>
      )}

      { props.product === 'calls' && (
      <div className="form-section">
        <div className="form-section-header">Call Destination</div>
        <p>Enter a phone number or configure SIP transfer.</p>

        {/* would like to  use a link to ancestor
                Need to call rulesContext.setSelected(rule, tab)
                */}

        {props.propAncestors.destination_number && (
        <Row>
          <Col sm={12} md={8}>
            <p>
              The ancestor rule{' '}
              <Button color="link" size="sm" className="inline" onClick={handleClickDestinationNumberAncestor}>{props.propAncestors.destination_number.label}</Button>
              {' '}  has the value {props.propAncestors.destination_number.value}. The ancestor value will be used if no value is entered here.
            </p>

          </Col>
        </Row>
        )}

        <Row>
          <Col sm={12} md={6}>
            <FormGroup>
              <Label >Phone Number
                <HelpPopper
                name="phone-number-help"
                iconClass="ms-1"
              >
                <p>The phone number should be a 10 digit number prefixed with &quot;+1&quot;, with no dashes or spaces.</p>
                  <p>A Twilio template can be appended to the number.</p>
              </HelpPopper>
              </Label>

                <Input
                  bsSize="sm"
                  name="destination_number"
                  placeholder="10 digit number"
                  value={props.destinationNumber || ''}
                  onChange={props.onChange}
                  error={errors.destination_number}
                  invalid={!!errors.destination_number}
                />

              <FormFeedback>{errors.destination_number}</FormFeedback>

            </FormGroup>
          </Col>

        </Row>

        <Row className="mt-2">
          <Col lg={12} xl={9}>
            <FormGroup>
              <div><Label>SIP Transfer</Label></div>
              <FormText>Configuring SIP will override the destination phone number</FormText>

              <SipConfig sip={props.destinationSip} onChange={handleSipChange} error={errors.destination_sip} />

            </FormGroup>

          </Col>
        </Row>

      </div>
      )}

      { props.product === 'calls' && (
      <div className="mt-2">
        <DeliveryDestinations
          definitions={definitionsRef.current}
          label="Call Started Integrations"
          destinations={props.callStartedWebhooks}
          onChange={handleCallStartedWebhookChange}
          errors={errors}
        />
        <DeliveryDestinations
          definitions={definitionsRef.current}
          label="Call Ended Integrations"
          destinations={props.callEndedWebhooks}
          onChange={handleCallEndedWebhookChange}
          errors={errors}
        />
      </div>
      )}

      <div className="mt-2">
        <DeliveryDestinations
          definitions={definitionsRef.current}
          label="Post-Sale Integrations"
          description="Configure post-sale deliveries or web hooks."
          destinations={props.deliveryDestinations}
          onChange={handleDeliveryChange}
          errors={errors}
        />
      </div>

    </div>
  );
};

Destination.propTypes = {
  redirectUrl: PropTypes.string,
  redirectUrlTemplate: PropTypes.string,
  prefill: PropTypes.object,
  // deliverySettings: PropTypes.string,
  deliveryDestinations: PropTypes.array,
  callStartedWebhooks: PropTypes.array,
  callEndedWebhooks: PropTypes.array,
  destinationNumber: PropTypes.string,
  twilioTemplate: PropTypes.string,
  destinationSip: PropTypes.object,
  tokens: PropTypes.array,
  product: PropTypes.string,
  verticalId: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.object,
};

Destination.defaultProps = {
  deliveryDestinations: [],
  callStartedWebhooks: [],
  callEndedWebhooks: [],
}

export default React.memo(Destination);
