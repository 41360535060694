/*
 * Components and helpers for use with react-table and table data formatting
 * @see https://react-table.tanstack.com/
 */
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import {
  parseJSON, format as formatDate, isDate, isValid as isValidDate,
} from 'date-fns';
import { format as formatDateTz, utcToZonedTime } from 'date-fns-tz';
import { Spinner } from 'reactstrap';
import NumberFormat from 'react-number-format';
import StandardAlert from '../../Layout/StandardAlert';

// For translating values in vertical column
export const VerticalMap = {
  'auto-insurance': 'Auto Insurance',
  autoins: 'Auto Insurance',
  'auto-warranty': 'Auto Warranty',
  autowarranty: 'Auto Warranty',
  debt: 'Debt',
  'disability-benefits': 'Disability Benefits',
  education: 'Education',
  'final-expense': 'Final Expense',
  'health-insurance': 'Health Insurance',
  healthins: 'Health Insurance',
  'home-insurance': 'Home Insurance',
  homeins: 'Home Insurance',
  jobs: 'Jobs',
  legal: 'Legal',
  'life-insurance': 'Life Insurance',
  lifeins: 'Life Insurance',
  'medical-alert-device': 'Medical Alert Device',
  medicare: 'Medicare',
  mortgage: 'Mortgage',
  'non-profit': 'Non-Profit',
  pathways: 'Pathways',
  'personal-loan': 'Personal Loan',
  rewards: 'Rewards',
  roofing: 'Roofing',
  solar: 'Solar',
  ssdi: 'SSDI',
  'tax-debt': 'Tax Debt',
  windows: 'Windows',
};

// For translating values in change log type column
export const ChangeLogTypeMap = {
  'order.updated': 'Order Updated',
  'order.created': 'Order Created',
  'order.status': 'Order Status',
  'targetrule.bid': 'Bid',
  'targetrule.deleted': 'Rule Deleted',
  'targetrule.created': 'Rule Created',
};

// Labels for match and sale rejections
export const AuctionRejectionMap = {
  'proxy_rejected:no match': 'Proxy Rejected: No Match',
  'proxy_rejected:duplicate': 'Proxy Rejected: Duplicate',
  'proxy_rejected:invalid': 'Proxy Rejected: Invalid',
  'proxy_rejected:capped': 'Proxy Rejected: Capped',
  'proxy_rejected:geo': 'Proxy Rejected: Geo',
  'proxy_rejected:todo': 'Proxy Rejected: TODO',
  'proxy_rejected:delivery_failure': 'Proxy Rejected: Delivery Failure',
  'proxy_rejected:delivery_timeout': 'Proxy Rejected: Delivery Timeout',
  'bid_floor': 'Bid Floor',
  'bid_falloff': 'Bid Falloff',
  'dedupe_scope': 'Dedupe (scope)',
  'bid': 'Bid',
  'cap': 'Cap',
  'missing_ad': 'Missing Ad',
  'suppression':'Suppression',
  'dnc': 'DNC',
  'proxy_rejected:': 'Proxy Rejected: Unknown',
  'delivery_timeout': 'Delivery Timeout',
  'timeout': 'Timeout',
  'compliance': 'Compliance',
}
export const CapTimeframeChoices = {
  second: 'Per second',
  minute: 'Per minute',
  hourly: 'Hourly',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  annual: 'Annually',
  total: 'Total',
};

// Header that uses font awesome sort icons instead of the default box-shadow effect
// Usage in table props: ThComponent={IconSortHeader}
// This depends on sort props injected by getTheadThPropsWithSort
// Turn off the box-shadow at .ReactTable .rt-thead .rt-th.-sort-asc  (and .-sort-desc)
export const IconSortHeader = (props) =>
  // console.log('Leads.js:CustomHeader props', props);
  // props.sorted is object {desc: true|false, id: "first_name"},  or it is undefined if never sorted
  (
    <div className={`rt-th ${props.className}`} style={props.style} onClick={props.toggleSort}>
      {props.children}
      {props.sortable
    && (
    <div className={classnames(['sort-icon', { 'sort-active': props.sorted }])}>
      {props.sorted && props.sorted.desc === false && <FaIcon icon="sort-up" size="sm" />}
      {props.sorted && props.sorted.desc === true && <FaIcon icon="sort-down" size="sm" />}
      {!props.sorted && <FaIcon icon="sort" size="sm" />}
    </div>
    )}
    </div>
  )
;

export const AnyCell = () => <span className='text-black-50'>- any -</span>;

/*
 * Formatters
 * With ReactTable you can assign these in the column definition like:
 * Cell:MoneyFormatter
 */
export const MoneyFormatter = ({ value }) => (value
  ? (<NumberFormat value={value} displayType="text" thousandSeparator prefix="$" decimalScale={2} fixedDecimalScale />)
  : <>0</>);

// Assumes E.164 format +15552220000
export const PhoneFormatter = ({ value }) => (value
  ? (<NumberFormat value={value} displayType="text" format="+###########" />)
  : '');

export const PercentFormatter = ({ value, decimals = 0 }) => (value
  ? (<NumberFormat value={value} displayType="text" thousandSeparator suffix="%" decimalScale={decimals} />)
  : <span>0</span>);

export const DateFormatter = ({ value, format = 'yyyy-MM-dd', timezone=null }) => {
  if (!value) return <span>&mdash;</span>;
   //console.log('Table.js:DateFormatter, date value', value, isDate(value));
   if (Number.isInteger(value)) {
     value = new Date(value);
   }
  const dt = isDate(value) ? value : value.indexOf('T') > 0 ? parseJSON(value) : new Date(value);

  if (!isValidDate(dt)) return <span>&mdash;</span>;

  if (timezone) {
    // console.log('Table.js:DateFormatter format to timezone :dt, timezone', dt, timezone);
    const tzDate = utcToZonedTime(dt, timezone);
    return <span>{formatDateTz(tzDate, format, { timeZone: timezone })}</span>;
  }

  return <span>{formatDate(dt, format)}</span>;
};

// Seconds as hh:mm:ss
export const DurationFormatter = ({seconds}) => {
  if (!seconds) return (null);

  const sec = Math.ceil(seconds) % 60;
  const minutes = Math.floor((seconds / 60) % 60);
  const hours = Math.floor(seconds / 3600);

  // hh:mm:ss ( no hours if zero)
  return <span>{(hours ? `${hours}:` : '') + (`${minutes}`).padStart(hours ? 2 : 1, '0') + ':' + (`${sec}`).padStart(2, '0')}</span>;
};

export const NoDataComponent = (props) => (
  <div className={classnames('rt-noData', { loading: props.loading })}>
    <StandardAlert color="light">{props.children}</StandardAlert>
  </div>
);

export const LoadingComponent = (props) => (
  <div className={classnames('-loading', { 'active -active': props.loading })}>
    <div><Spinner color="secondary" /></div>
  </div>
);
