import React, {
  useEffect, useState, useReducer, useContext,
} from 'react';
import {
  Form, FormGroup, Button, Spinner,
} from 'reactstrap';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import Payment from 'payment';
import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome';
import { isBefore, startOfDay } from 'date-fns';
import classnames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import PropTypes from 'prop-types';
import {BlockUI} from 'ns-react-block-ui';
import InputWithIcon from '../../../../Components/Form/InputWithIcon';
import EditCard from './EditCard';
import StandardAlert from '../../../../Layout/StandardAlert';
import CardIcon from '../../../../Components/Icons/Card/CardIcon';
import CardService from './CardService';
import { MoneyFormatter, DateFormatter } from '../../../../Components/Table/Table';
import {notify} from "@thedmsgroup/mastodon-ui-components/lib/common/Notify";
import usePermission from '../../../../Hooks/usePermission';
import { AppContext } from '../../../../Providers/AppProvider';

// Mask dd.dd
const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: false,
  decimalLimit: 0,
  integerLimit: 5,
  // fixedDecimalScale:true
});

const CardMessage = ({ type, message, onDismiss }) => {
  if (!type) return (null);
  const icon = 'info';
  let color = 'info';
  if (type === 'storeCardSuccess') {
    message = message || 'Your credit card information has been submitted. Please allow a few minutes for processing before it is available to use.';
    color = 'success';
  } else if (type === 'storeCardCancel') {
    message = message || 'New card verification was cancelled.';
  } else if (type === 'chargeSuccess') {
    message = message || 'The charge for added funds has been submitted';
    color = 'success';
  } else if (type === 'chargeFail') {
    message = message || 'We were unable to process this charge.';
    color = 'warning';
  }
  return (<StandardAlert icon={icon} color={color} allowDismiss onDismiss={onDismiss}>{message}</StandardAlert>);
};

const CreditCardMaskedDisplay = ({ last4 }) => (
  <span className="cc-masked">
    <span className="mask">&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;</span>
    {last4}
  </span>
);

const CardStateReducer = (state, action) => {
  // Name can be field name or a named action
  const { name, value } = action;

  // clear message for any action except setting a message
  if (name !== 'message') {
    state = { ...state, message: null };
  }

  switch (name) {
    case 'select_card':
      return { ...state, selectedCard: value };
    case 'payment_amount':
      return { ...state, paymentAmount: value };
    case 'cancel':
      return {
        ...state,
        selectedCard: null,
        paymentAmount: '',
      };
    case 'message':
      return { ...state, message: value };

    default:
      return state;
  }
};

const initialState = {
  selectedCard: null,
  paymentAmount: '',
  message: '',
  error: null,
  isValid: true,
};

/*
 * Component for managing credit cards, adding funds to your account, and managing
 * payment plans
 *
 */
const Cards = ({ accountId, cards, refreshAccount }) => {
  const app = useContext(AppContext);
  const Accounting = React.useMemo(() => app.getApi('accounting'), []);
  const [state, updateState] = useReducer(CardStateReducer, initialState);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [cardsOnFile, setCardsOnFile] = useState(cards);
  const [editCard, setEditCard] = useState(null);
  const [chargeCardConfirm, setChargeCardConfirm] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const allowEdit = usePermission('accounting.edit');
  const allowView = usePermission('accounting.view');

  if (!allowView) return (<span>Not authorized</span>);

  const routerLocation = useLocation();
  const navigate = useNavigate();

  // On mount, read query string for MeS response
  useEffect(() => {
    // Check for return result so we can message the user about MeS cancel or success.
    // (Read query string on timeout because the parent Settings component's
    // tab navigaton also reads and changes query string)
    window.setTimeout(() => {
      const search = qs.parse(routerLocation.search);

      if ('cardresult' in search) {
        updateState({ name: 'message', value: { type: search.cardresult } });
        delete (search.cardresult);
        delete (search.last4);
        navigate({replace:true, search: qs.stringify({ ...search }) });
      }
    }, 500);
  }, []);

  // Process card list  when we first get it
  useEffect(() => {
    if (cards) {
      cards = cards.map((card) => {
        const exp = startOfDay(new Date(card.expiration_date));
        card.is_expired = isBefore(exp, new Date());
        return card;
      });
      // if(cards.length === 1) updateState({name:'select_card', value:cards[0]});

      setCardsOnFile(cards);
      setCardsLoaded(true);
    }
  }, [cards]);

  // Focus on amount input when card is selected
  useEffect(() => {
    if (state.selectedCard) {
      const el = document.getElementById(`inp-amount-${state.selectedCard.id}`);
      if (el) el.select();
    }
  }, [state.selectedCard]);

  const updateForm = React.useCallback(({ target: { value, name, type } }) => {
    if (name === 'new_card_number') {
      value = Payment.fns.formatCardNumber(value);
    }
    updateState({ name, value });
  }, []);

  const removeCard = async () => {
    if (editCard) {
      setIsUpdating(true);
      const result = await Accounting.removeCard(accountId, editCard.id);
      // TODO: check the result
      // if(result){
      setEditCard(null);
      notify('The card has been removed', 'success');
      refreshAccount();
      // } else {
      //   notify('Unable to get remove card: ' + Accounting.error.name, 'error')
      // }

      setIsUpdating(false);
    }
  };

  // This is only used by EditCard
  const updateCard = async (useForAutoPay) => {
    if (editCard) {
      setIsUpdating(true);
      const result = await Accounting.updateCard(accountId, editCard.id, editCard.priority, useForAutoPay);

      if (result) {
        setEditCard(null);
        refreshAccount();
        notify('The credit card was updated', 'success');
      } else {
        notify(`Unable to update card: ${Accounting.error.name}`, 'error');
      }

      setIsUpdating(false);
    }
  };

  const updatePriority = async (card, direction) => {
    // Note: priority is descending (higher number first)
    if (card && !isUpdating) {
      setIsUpdating(true);
      const newPriority = direction === 'up' ? ++card.priority : Math.max(--card.priority, 0);
      const result = await Accounting.updateCard(card.id, newPriority, card.auto_billing_eligible);

      if (result) {
        setEditCard(null);
        await refreshAccount();
        // notify('The credit card priority was updated', 'success')
      } else {
        notify(`Unable to update card priority: ${Accounting.error.name}`, 'error');
      }

      setIsUpdating(false);
    }
  };

  // Open/shut edit card pane
  const toggleEditCard = (card) => {
    if (card) {
      setEditCard(editCard && editCard.id === card.id ? null : card);
      // TODO: need to cancel add new card
    } else {
      setEditCard(card);
    }
  };

  const gotoCheckout = async () => {
    setIsRedirecting(true);
    const search = (routerLocation.search || '?tab=funds');
    const { origin, pathname } = window.location;
    const baseReturn = `${origin + pathname}#${routerLocation.pathname}${search}`;

    const response = await Accounting.getCheckoutPage(
      accountId,
      `${baseReturn}&cardresult=storeCardSuccess`,
      `${baseReturn}&cardresult=storeCardCancel`,
    );

    if (response) {
      // CardService.storePendingCard(cardNumber, state.newCardIssuer, state.newCardExp);
      location.href = response.url;
    } else {
      notify('Unable to redirect to checkout', 'error');
      setIsRedirecting(false);
    }
  };

  const doManualCharge = async () => {
    // TODO validation
    if (state.selectedCard) {
      setIsUpdating(true);
      const payment = parseInt(state.paymentAmount.replace(/[^0-9]/g, ''));
      const result = await Accounting.chargeToCard(accountId, state.selectedCard.id, payment);

      /* result is an array of charges, false on error
      0:
        amount: 33
        card_id: 14
        created_at: "2020-04-14T22:53:51.960739605Z"
        invoice_number: "ue5e963eff191ba"
        status: "accepted"
        transaction_id: "b4bd490dab5a3254bf57a5ccc370dbd3"
       */
      if (result) {
        const cardName = CardService.getCardNameFromType(state.selectedCard.type);
        updateState({ name: 'cancel' });
        updateState({
          name: 'message',
          type: 'chargeSuccess',
          value: {
            type: 'chargeSuccess',
            message: (<span>
              A charge of
              <MoneyFormatter value={state.paymentAmount} />
              {' '}
              on
              {cardName}
              {' '}
              {state.selectedCard.last_four}
              {' '}
              has been submitted.
                      </span>),
          },
        });

        notify((<span>
          A charge of
          <MoneyFormatter value={state.paymentAmount} />
          {' '}
          on
          {cardName}
          {' '}
          {state.selectedCard.last_four}
          {' '}
          has been submitted.
                </span>), 'success');

        refreshAccount();
      } else {
        // We don't get a useful error structure from the Accounting api
        updateState({ name: 'message', value: { type: 'chargeFail', message: (<span>An error occurred while submitting this charge </span>) } });
        /* error is
        form: {}
        message: ""
        name: ""
        status: 400
         */
      }
      setChargeCardConfirm(false);
      setIsUpdating(false);
    }
  };

  const cancelCharge = () => updateState({ name: 'cancel' });

  const handleClickSelectCard = (card) => {
    if (card && state.selectedCard && card.id === state.selectedCard.id) {
      // close if already selected
      updateState({ name: 'select_card', value: null });
    } else {
      updateState({ name: 'select_card', value: card });
      toggleEditCard(null);
    }
  };

  const handleDismissMessage = () => updateState({ name: 'message', value: null });

  if (!cardsLoaded) {
    return (
      <div>
        <Spinner size="lg" className="ms-4 mt-3 d-block" color="secondary" />
        {' '}
      </div>
    );
  }

  return (

    <div id="manage-cards-section" className="form-section">
      <h5>Credit Cards</h5>
      <div>

        {cardsOnFile.length > 0
          ? (
            <p>
              Select a credit card to make a one-time addition of funds to your account.
              If automatic billing is configured, cards will be used in the order shown.
            </p>
          )
          : (
            <p>
              Enter a credit card to add funds to your account.
              Funds can be maintained automatically by selecting a payment plan, or manually with one-time payments.
            </p>
          )}
      </div>

      {state.message && <CardMessage {...state.message} onDismiss={handleDismissMessage} />}

      <div id="cards-on-file">
        <BlockUI
          tag="div"
          blocking={isUpdating}
          mode="stretch"
          loader={<Spinner color="success" />}
        >

          {/* List existing cards */}
          {cardsOnFile && cardsOnFile.length > 0 && (
          <>

            {cardsOnFile.map((card, i) => {
              const isSelected = !!(state.selectedCard && state.selectedCard.id === card.id);
              return (
                <div className={classnames('card-on-file-row', { selected: isSelected })} key={i}>

                  <div className="d-flex justify-content-between align-items-center">
                    {allowEdit === true && (
                    <div className="cc-priority-col">
                      {/*  Don't show if only 1 card. */}
                      {isUpdating === false && cardsOnFile.length > 1 ? (
                        <>
                          {i > 0 && (
                          <FaIcon
                            icon="long-arrow-alt-up"
                            className="me-1"
                            size="lg"
                            title="Increase priority"
                            onClick={() => updatePriority(card, 'up')}
                          />
                          )}
                          {i < cardsOnFile.length - 1 && (
                          <FaIcon
                            icon="long-arrow-alt-down"
                            size="lg"
                            title="Lower priority"
                            onClick={() => updatePriority(card, 'down')}
                          />
                          )}
                        </>
                      ) : (null)}

                    </div>
                    )}

                    <div className="cc-image-col" onClick={() => handleClickSelectCard(card)}>
                      <CardIcon type={card.type} width={54} />
                    </div>
                    <div className="cc-number-col">
                      <CreditCardMaskedDisplay last4={card.last_four} />
                      {card.is_default === true && (
                      <span className="default ms-1 me-2">
                        <FaIcon icon="check" size="1x" color="green" className="ms-2" />
                        <small>default</small>
                      </span>
                      ) }
                    </div>
                    <div className="cc-exp-col">
                      <DateFormatter value={card.expiration_date} format="MM/yy" />
                      {card.is_expired && (
                      <small className="expired ms-1">
                        <FaIcon icon="exclamation-triangle" size="sm" color="darkred" />
                        {' '}
                        Expired
                      </small>
                      ) }
                    </div>

                    {isSelected === false && (
                    <div className="cc-auto-pay-col">
                      {card.auto_billing_eligible === true && (
                      <span>
                        <FaIcon icon="check" size="sm" className="me-1" color="green" />
                        {' '}
                        <small>use for auto-payment plan</small>
                        {' '}
                      </span>
                      )}
                    </div>
                    )}

                    {isSelected === true && (
                    <div className="cc-checkout flex-grow-1 text-right">

                      <Form inline>

                        {chargeCardConfirm === true ? (
                          <>
                            {isUpdating ? (
                              <Spinner size="sm" />
                            ) : (
                              <div className="inline-confirm">
                                <span className="me-2">
                                  <b>
                                    Charge
                                          <MoneyFormatter value={state.paymentAmount} />
                                    {' '}
                                    to card?
                                        </b>
                                </span>
                                <Button onClick={doManualCharge} size="sm" color="primary" className="me-2">Yes</Button>
                                <Button onClick={() => setChargeCardConfirm(false)} size="sm" color="secondary" outline>No</Button>
                              </div>
                            )}

                          </>

                        ) : (
                          <>
                            <FormGroup className="me-2">
                              <MaskedInput
                                mask={currencyMask}
                                value={state.paymentAmount || ''}
                                onChange={updateForm}
                                id={`inp-amount-${card.id}`}
                                placeholder="Amount"
                                name="payment_amount"
                                render={(ref, props) => <InputWithIcon className="teeny" size="sm" innerRef={ref} icon="dollar-sign" {...props} />}
                              />
                            </FormGroup>

                            <Button size="sm" color="link" onClick={cancelCharge}>Cancel</Button>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => setChargeCardConfirm(true)}
                              className="me-2"
                              disabled={state.paymentAmount <= 0}
                            >
                              Add Funds
                            </Button>

                          </>
                        )}
                      </Form>
                    </div>
                    )}

                    {isSelected === false && allowEdit && (
                    <div className="cc-action flex-fill text-right">

                      <Button
                        color="link"
                        size="sm"
                        onClick={() => handleClickSelectCard(card)}
                        className="inline me-2"
                      >
                        Add Funds
                      </Button>

                      <Button
                        color="link"
                        size="sm"
                        onClick={() => toggleEditCard(card)}
                        className="inline"
                      >
                        Edit
                        {' '}
                        <FaIcon className="ms-1" icon={editCard && card.id === editCard.id ? 'chevron-down' : 'chevron-right'} />
                      </Button>
                    </div>

                    )}
                  </div>

                  {/* Edit card section */}
                  {isSelected === false && editCard && card.id === editCard.id && (
                  <EditCard
                    card={card}
                    cancelEdit={toggleEditCard}
                    updateCard={updateCard}
                    removeCard={removeCard}
                    isUpdating={isUpdating}
                  />
                  )}

                </div>
              );
            })}
            {' '}
            {/* end map cardsOnFile */}

          </>
          )}
          {' '}
          {/* if cardsOnFile length */}

          {allowEdit && (
          <div className="card-on-file-row new-card d-flex justify-content-end">
            <div>
              <small>You will be directed to a secure card verification page.</small>

              <Button
                color="link"
                size="sm"
                className="btn-add-card ms-3"
                onClick={gotoCheckout}
                disabled={isRedirecting || editCard}
              >
                {isRedirecting === true ?
                  <Spinner size="sm" color="light" className="me-1" />
                  : <FaIcon icon="plus" size="sm" className="me-1" />}
                Add Card
              </Button>

            </div>
          </div>
          )}

       </BlockUI>

      </div>
    </div>

  );
};

Cards.propTypes = {
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cards: PropTypes.array,
  refreshAccount: PropTypes.func,
};

export default Cards;
